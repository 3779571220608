import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { blogPosts } from "./posts"; // Adjust the import path as necessary
import { Layout } from "../Layout";
import { createUrlFriendlyString } from "./BlogÜbersicht";
import analyticsEventLog from "../../helperFunctions/AnalyticsEvent";

function BlogPost() {
  let { postId, postTitle } = useParams();
  const post = blogPosts.find(
    (post) => createUrlFriendlyString(post.title) === postTitle
  );

  useEffect(() => {
    const originalTitle = document.title;
    if (post) {
      document.title = `${post.title} - Futedu`;
    }
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, [post]);

  // Fallback if no post is found
  if (!post) {
    return (
      <Layout>
        <div className="p-5 max-w-lg">
          <div className="text-red-500 p-3 rounded-md">
            Post nicht gefunden!
          </div>
          <Link to="/Blog" className="text-indigo-500">
            Zurück zur Übersicht
          </Link>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <head>
        <title>{post.title} - Futedu</title>
        <meta name="description" content={post.metaDescription} />
        <meta name="keywords" content={post.metaKeywords} />
        <link
          rel="canonical"
          href={`https://www.futedu.de/Blog/${createUrlFriendlyString(
            post.title
          )}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: post.title,
            articleBody: post.content,
            author: {
              "@type": "Person",
              name: post.author,
            },
            datePublished: post.date,
            image: post.image,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://www.futedu.de/Blog/${createUrlFriendlyString(
                post.title
              )}`,
            },
          })}
        </script>
      </head>
      <div className="text-primary px-10 p-6 max-w-4xl mx-auto bg-white rounded-lg ">
        <div className="p-5 max-w-3xl mx-auto">
          <h1 className="font-bold text-2xl text-primary py-5 text-center">
            {post.title}
          </h1>
          <div className="text-center py-5">
            {post.image && (
              <img
                src={post.image}
                alt={post.title}
                className="max-w-full h-auto rounded-md mx-auto"
              />
            )}
          </div>
          <div
            className="text-primary"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <div className="text-center pt-7">
            <div className="btn btn-accent text-primary rounded-3xl shadow-lg font-bold px-16 mx-auto">
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                  analyticsEventLog(`toHomeFromPostId${post.id}`);
                }}
              >
                Zurück zur Startseite
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogPost;
