import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase/firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Layout } from "./Layout";
import { useNavigate } from "react-router-dom";
// import "./Slider.css";

const Slider = ({ label, id, sliderValues, setSliderValues, index }) => {
  const [value, setValue] = useState(2);

  const handleChange = (event) => {
    console.log(index);
    const newValue = parseInt(event.target.value);
    const newSliderValues = [...sliderValues];
    setValue(parseInt(event.target.value));
    if (id === "inverse") {
      newSliderValues[index] = 4 - newValue;
    } else {
      newSliderValues[index] = newValue;
    }
    setSliderValues(newSliderValues);
    console.log(newSliderValues);
  };

  return (
    <div className="flex flex-col justify-between bg-primary my-10 gap-5 py-10 rounded-xl max-w-4xl mx-auto ml-2 mr-2">
      <p className="pb-14 px-3 text-3xl ">{label}</p>
      <div className="flex flex-col px-5 md:px-10">
        <div className="flex items-center justify-between pb-2 md:pb-3 font-semibold">
          <div className="text-sm">trifft nicht zu</div>
          <div className="text-sm">trifft sehr zu</div>
        </div>
        <input
          type="range"
          min="0"
          max="4"
          value={value}
          className="range range-md range-accent"
          id={id}
          onChange={handleChange}
        />
        <div className="w-full flex justify-between text-xs mt-3">
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </div>
      </div>
    </div>
  );
};

const Button = ({ id, onClick, label }) => {
  return (
    <div className="pb-10">
      <p>
        <button
          id={id}
          className="btn btn-accent rounded-3xl shadow-lg font-bold px-16"
          type="button"
          onClick={onClick}
        >
          {label}
        </button>
      </p>
    </div>
  );
};

const Blocks = ({ id, children, style }) => {
  return (
    <div id={id} align="center" style={style} className="pt-10">
      {children}
    </div>
  );
};

export function Personality() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Persönlichkeitstest - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [visibleBlock, setVisibleBlock] = useState(1);
  const [sliderValues1, setSliderValues1] = useState(Array(20).fill(0));
  const [sliderValues2, setSliderValues2] = useState(Array(20).fill(0)); // Initialize with 20 sliders, you may adjust this based on your actual number of sliders
  const [sliderValues3, setSliderValues3] = useState(Array(20).fill(0));
  const [sliderValues4, setSliderValues4] = useState(Array(20).fill(0));
  const [sliderValues5, setSliderValues5] = useState(Array(20).fill(0));

  const navigate = useNavigate();
  let uid;
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      console.log("signed in");
      uid = user.uid;
    } else {
      //not signed in
      console.log("not signed in");
      return navigate("/registrieren");
    }
  });

  function handleSubmit(id) {
    if (visibleBlock < 5) {
      document.documentElement.scrollTop = 0;
      // For older browsers
      document.body.scrollTop = 0;
      setVisibleBlock(visibleBlock + 1);
    } else {
      // Link back to Overview
    }

    let sum;
    let key;
    console.log(id);
    switch (id) {
      case "id1":
        key = "Neurotizismus";
        sum = sliderValues1.reduce((acc, curr) => acc + curr, 0);
        break;
      case "id2":
        key = "Verträglichkeit";
        sum = sliderValues2.reduce((acc, curr) => acc + curr, 0);
        break;
      case "id3":
        key = "Gewissenhaftigkeit";
        sum = sliderValues3.reduce((acc, curr) => acc + curr, 0);
        break;
      case "id4":
        key = "Extrovertiert";
        sum = sliderValues4.reduce((acc, curr) => acc + curr, 0);
        break;
      case "id5":
        key = "Offenheit";
        sum = sliderValues5.reduce((acc, curr) => acc + curr, 0);
        break;
      default:
        sum = 0; // Default value if id does not match any case
    }
    //auth.currentUser.uid; // Assuming you have authenticated user

    setDoc(
      doc(db, "users", uid),
      {
        [key]: sum / 80, /// (sliderValues1.length * 4), // Assuming each slider has a range from 0 to 4
      },
      { merge: true }
    )
      .then(() => {
        console.log("User added to Firestore");

        if (visibleBlock == 5) {
          return navigate("../Overview");
        }
      })
      .catch((error) => {
        console.log("Error adding user to Firestore:", error);
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt
  return (
    <>
      <Layout pageTitle={"Persönlichkeit"}>
        <Blocks
          style={{ display: visibleBlock === 1 ? "block" : "none" }}
          id={`block${1}`}
        >
          <Slider
            label="Ich werde leicht wütend."
            id="slider1"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={0}
          />
          <Slider
            label="Ich rege mich leicht auf."
            id="slider2"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={1}
          />
          <Slider
            label="Ich ändere oft meine Stimmung."
            id="slider3"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={2}
          />
          <Slider
            label="Ich bin eine Person, deren Stimmung leicht auf und ab geht."
            id="slider4"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={3}
          />
          <Slider
            label="Ich werde schnell unruhig."
            id="slider5"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={4}
          />
          <Slider
            label="Ich kann leicht provoziert werden."
            id="slider6"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={5}
          />
          <Slider
            label="Ich bin selten gereizt."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={6}
          />
          <Slider
            label="Ich habe meine Gefühle unter Kontrolle."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={7}
          />
          <Slider
            label="Ich verliere nur selten die Beherrschung."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={8}
          />
          <Slider
            label="Ich bin nicht leicht zu verärgern."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={9}
          />
          <Slider
            label="Ich bin voller Zweifel über viele Dinge."
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={10}
          />
          <Slider
            label="Ich fühle mich leicht bedroht."
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={11}
          />
          <Slider
            label="Ich mache mir Sorgen über Dinge."
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={12}
          />
          <Slider
            label="Ich bin leicht zu entmutigen."
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={13}
          />
          <Slider
            label="Ich werde schnell von Ereignissen überwältigt."
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={14}
          />
          <Slider
            label="Ich habe vor vielen Dingen Angst."
            id="slider7"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={15}
          />
          <Slider
            label="Ich fühle mich selten traurig."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={16}
          />
          <Slider
            label="Ich fühle mich wohl alleine."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={17}
          />
          <Slider
            label="Ich fühle mich selten deprimiert."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={18}
          />
          <Slider
            label="Ich schäme mich nicht leicht."
            id="inverse"
            sliderValues={sliderValues1}
            setSliderValues={setSliderValues1}
            index={19}
          />
          <Button onClick={() => handleSubmit("id1")} label="Weiter" />
        </Blocks>
        <Blocks
          style={{ display: visibleBlock === 2 ? "block" : "none" }}
          id={`block${2}`}
        >
          <Slider
            label="Ich bin mitfühlend."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={0}
          />
          <Slider
            label="Ich erkundige mich nach dem Wohlbefinden anderer."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={1}
          />
          <Slider
            label="Ich kann die Gefühle anderer nachempfinden."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={2}
          />
          <Slider
            label="Ich interessiere mich für das Leben anderer Menschen."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={3}
          />
          <Slider
            label="Ich tue gerne etwas für andere."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={4}
          />
          <Slider
            label="Ich interessiere mich nicht für die Probleme anderer Menschen."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={5}
          />
          <Slider
            label="Ich kann mich nicht um die Bedürfnisse anderer kümmern."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={6}
          />
          <Slider
            label="Ich bin gleichgültig gegenüber den Gefühlen anderer."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={7}
          />
          <Slider
            label="Ich nehme mir keine Zeit für andere."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={8}
          />
          <Slider
            label="Ich habe keine weiche Seite."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={9}
          />
          <Slider
            label="Ich respektiere Autorität."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={10}
          />
          <Slider
            label="Ich hasse es, aufdringlich zu sein."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={11}
          />
          <Slider
            label="Ich vermeide es, anderen meinen Willen aufzuzwingen."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={12}
          />
          <Slider
            label="Ich setze Menschen selten unter Druck."
            id="normal"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={13}
          />
          <Slider
            label="Ich beleidige andere Personen manchmal."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={14}
          />
          <Slider
            label="Ich glaube, dass ich besser bin als andere."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={15}
          />
          <Slider
            label="Ich nutze andere aus."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={16}
          />
          <Slider
            label="Ich suche den Konflikt."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={17}
          />
          <Slider
            label="Ich habe Spaß am streiten."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={18}
          />
          <Slider
            label="Ich bin auf meinen eigenen persönlichen Vorteil aus."
            id="inverse"
            sliderValues={sliderValues2}
            setSliderValues={setSliderValues2}
            index={19}
          />
          <Button onClick={() => handleSubmit("id2")} label="Weiter" />
        </Blocks>
        <Blocks
          style={{ display: visibleBlock === 3 ? "block" : "none" }}
          id={`block${3}`}
        >
          <Slider
            label="Mir ist es wichtig, dass meine Pläne umgesetzt werden."
            id="inverse1"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={0}
          />
          <Slider
            label="Ich beende, was ich anfange."
            id="inverse2"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={1}
          />
          <Slider
            label="Ich erledige Dinge schnell."
            id="inverse3"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={2}
          />
          <Slider
            label="Ich weiß immer, was ich tue."
            id="inverse4"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={3}
          />
          <Slider
            label="Ich verschwende meine Zeit."
            id="inverse5"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={4}
          />
          <Slider
            label="Ich finde es schwierig, mich an die Arbeit zu machen."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={5}
          />
          <Slider
            label="Ich bringe Dinge durcheinander."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={6}
          />
          <Slider
            label="Ich konzentriere mich nicht auf die anstehende Aufgabe."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={7}
          />
          <Slider
            label="Ich schiebe Entscheidungen auf."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={8}
          />
          <Slider
            label="Ich lasse mich leicht ablenken."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={9}
          />
          <Slider
            label="Ich mag Ordnung."
            id="inverse11"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={10}
          />
          <Slider
            label="Ich halte die Dinge ordentlich."
            id="inverse12"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={11}
          />
          <Slider
            label="Ich befolge einen Zeitplan."
            id="inverse13"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={12}
          />
          <Slider
            label="Ich möchte, dass alles perfekt ist."
            id="inverse14"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={13}
          />
          <Slider
            label="Ich möchte, dass Regeln eingehalten werden."
            id="inverse15"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={14}
          />
          <Slider
            label="Ich will, dass jedes Detail beachtet wird."
            id="inverse16"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={15}
          />
          <Slider
            label="Ich lasse mein Hab und Gut herumliegen."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={16}
          />
          <Slider
            label="Ich störe mich nicht an unordentlichen Menschen."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={17}
          />
          <Slider
            label="Ich störe mich nicht an Unordnung."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={18}
          />
          <Slider
            label="Ich mag keine Routine."
            id="inverse"
            sliderValues={sliderValues3}
            setSliderValues={setSliderValues3}
            index={19}
          />
          <Button onClick={() => handleSubmit("id3")} label="Weiter" />
        </Blocks>
        <Blocks
          style={{ display: visibleBlock === 4 ? "block" : "none" }}
          id={`block${4}`}
        >
          <Slider
            label="Ich finde leicht Freunde."
            id="slider1"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={0}
          />
          <Slider
            label="Ich werde schnell mit Anderen warm."
            id="slider2"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={1}
          />
          <Slider
            label="Ich zeige meine Gefühle, wenn ich glücklich bin."
            id="slider3"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={2}
          />
          <Slider
            label="Ich habe viel Spaß."
            id="slider4"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={3}
          />
          <Slider
            label="Ich lache viel."
            id="slider5"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={4}
          />
          <Slider
            label="Es ist schwer, mich kennenzulernen."
            id="slider6"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={5}
          />
          <Slider
            label="Ich halte andere auf Distanz."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={6}
          />
          <Slider
            label="Ich gebe wenig von mir preis."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={7}
          />
          <Slider
            label="Ich lasse mich selten von Aufregung mitreißen."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={8}
          />
          <Slider
            label="Ich bin kein sehr enthusiastischer Mensch."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={9}
          />
          <Slider
            label="Ich übernehme die Verantwortung."
            id="slider7"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={10}
          />
          <Slider
            label="Ich habe eine starke Persönlichkeit."
            id="slider8"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={11}
          />
          <Slider
            label="Ich weiß, wie ich Menschen fesseln kann."
            id="slider9"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={12}
          />
          <Slider
            label="Ich sehe mich als gute Führungskraft."
            id="slider10"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={13}
          />
          <Slider
            label="Ich kann andere überreden, etwas zu tun."
            id="slider11"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={14}
          />
          <Slider
            label="Ich bin der Erste, der handelt."
            id="slider12"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={15}
          />
          <Slider
            label="Ich habe keine durchsetzungsfähige Persönlichkeit."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={16}
          />
          <Slider
            label="Mir fehlt das Talent, Menschen zu beeinflussen."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={17}
          />
          <Slider
            label="Ich warte darauf, dass andere den Weg vorgeben."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={18}
          />
          <Slider
            label="Ich halte mich mit meiner Meinung zurück."
            id="inverse"
            sliderValues={sliderValues4}
            setSliderValues={setSliderValues4}
            index={19}
          />
          <Button onClick={() => handleSubmit("id4")} label="Weiter" />
        </Blocks>
        <Blocks
          style={{ display: visibleBlock === 5 ? "block" : "none" }}
          id={`block${5}`}
        >
          <Slider
            label="Ich verstehe die Dinge schnell."
            id="inverse1"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={0}
          />
          <Slider
            label="Ich kann viele Informationen verarbeiten."
            id="inverse2"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={1}
          />
          <Slider
            label="Ich löse gerne komplexe Probleme."
            id="inverse3"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={2}
          />
          <Slider
            label="Ich habe einen großen Wortschatz."
            id="inverse4"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={3}
          />
          <Slider
            label="Ich denke schnell."
            id="inverse5"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={4}
          />
          <Slider
            label="Ich kann meine Ideen klar formulieren."
            id="inverse6"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={5}
          />
          <Slider
            label="Ich habe Schwierigkeiten, abstrakte Ideen zu verstehen."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={6}
          />
          <Slider
            label="Ich vermeide philosophische Diskussionen."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={7}
          />
          <Slider
            label="Ich vermeide schwieriges Lesematerial."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={8}
          />
          <Slider
            label="Ich lerne Dinge eher langsam."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={9}
          />
          <Slider
            label="Ich genieße die Schönheit der Natur."
            id="inverse11"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={10}
          />
          <Slider
            label="Ich glaube an die Bedeutung der Kunst."
            id="inverse12"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={11}
          />
          <Slider
            label="Ich liebe es, über Dinge nachzudenken."
            id="inverse13"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={12}
          />
          <Slider
            label="Ich tauche tief in die Musik ein."
            id="inverse14"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={13}
          />
          <Slider
            label="Ich sehe Schönheit in Dingen, die andere vielleicht nicht bemerken."
            id="inverse15"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={14}
          />
          <Slider
            label="Ich brauche ein kreatives Ventil."
            id="inverse16"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={15}
          />
          <Slider
            label="Ich mag keine Poesie."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={16}
          />
          <Slider
            label="Ich verliere mich selten in Gedanken."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={17}
          />
          <Slider
            label="Ich träume selten."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={18}
          />
          <Slider
            label="Ich nehme selten die emotionalen Aspekte von Gemälden und Bildern wahr."
            id="inverse"
            sliderValues={sliderValues5}
            setSliderValues={setSliderValues5}
            index={19}
          />
          <Button onClick={() => handleSubmit("id5")} label="Fertig" />
        </Blocks>
      </Layout>
    </>
  );
}
