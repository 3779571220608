import JsPDF from "jspdf";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import logo from "../../assets/Futedu_logo.png";
import "../../fonts/RedHatDisplay-VariableFont_wght-normal.js";
import "../../fonts/Gabarito-VariableFont_wght-bold.js";

// Function to fetch the logo image

export const addHeader = async (pdf, title) => {
  const pageWidth = pdf.internal.pageSize.getWidth();
  const headerHeight = 20;
  const backgroundColor = "#181826";
  const textColor = "#FFFFFF";
  // Set the background color for the header

  pdf.setFillColor(backgroundColor);
  pdf.rect(0, 0, pageWidth, headerHeight, "F");

  pdf.addImage(logo, "PNG", 5, 5, 10, 10);
  // Additional text elements in the header
  pdf.setFontSize(10);
  pdf.setTextColor(textColor);
  pdf.setFont("helvetica", "normal");
  pdf.text("Futedu", 16, 11);
  pdf.setFontSize(10);

  const currentDate = new Date().toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const dateWidth = pdf.getTextWidth(currentDate);
  pdf.text(currentDate, pageWidth - dateWidth - 10, 11);
  pdf.setFontSize(20);
  pdf.setTextColor("#000000");
  pdf.setFont("helvetica", "bold");
  const titleWidth = pdf.getTextWidth(title);
  pdf.text(title, (pageWidth - titleWidth) / 2, 35);
  pdf.setFontSize(10);
  pdf.setTextColor("#000000");
  pdf.setFont("helvetica", "normal");
};

//gets the user data from firestore and returns it
export async function getData(userID) {
  const userDocRef = doc(db, `users/${userID}`);

  try {
    // Attempt to fetch the document
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      // If no document exists, return null
      console.log(`No document found for user ID: ${userID}`);
      return null;
    }
  } catch (error) {
    console.error("Error fetching user document:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

// Function to add footer to each page
const addFooter = (pdf) => {
  const pageCount = pdf.internal.getNumberOfPages(); // Get the total number of pages

  // Set up the footer style
  pdf.setTextColor("#858585");
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(10);
  // A more subdued color for the footer

  for (let i = 1; i <= pageCount; i++) {
    pdf.setPage(i); // Ensure the footer is added to each page
    const footerText = "Copyright © 2024 Futedu"; // Customize with your desired text
    const pageNumber = `Seite ${i} von ${pageCount}`;

    const pageWidth = pdf.internal.pageSize.getWidth();
    const footerYPosition = pdf.internal.pageSize.getHeight() - 10; // Adjust Y position as needed

    // Center the copyright text and page number on the bottom of each page
    const textWidth = pdf.getTextWidth(footerText);
    const numberWidth = pdf.getTextWidth(pageNumber);

    // Position the copyright text and page number on either side
    pdf.text(footerText, (pageWidth - textWidth) / 2, footerYPosition);
    pdf.text(pageNumber, pageWidth - numberWidth - 10, footerYPosition);
  }
};

export const exportAllToPdf = async (chartRefs, response, images) => {
  const pdf = new JsPDF();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 5; // Adjusted margin for better spacing
  const chartWidth = 140; //(pageWidth / 2) - (margin * 2); // Use the entire width for two charts per page
  const chartHeight = 140; //(pageHeight / 2) - margin * 2; // Adjust chart height accordingly
  const headerSize = 35;

  /*response["Prioritäten"] = 
  `
  Durschnittliche Werte:

  Einkommen:        0.4
  Freiheit:         0.5
  Freizeit:         0.6
  Good Cause:  0.7
  Kundenkontakt:    0.3
  Verantwortung:    0.5
  `*/

  // Adjust positions for two charts per page
  const positions = [
    { x: 35, y: margin + headerSize },
    //{ x: (pageWidth) / 2 - (chartWidth / 3), y: margin + headerSize },
    { x: 30, y: pageHeight / 2 + margin / 2 },
  ];

  const titles = [
    "Persönlichkeit",
    "Fähigkeiten",
    "Interessen",
    "Auswertung",
    "Berufe"
  ];
  console.log(images);
  const categories = [
    "PersonalityTraits",
    "SkillLevels",
    "Interessen",
  ];
  categories.forEach((category, idx) => {
    const categoryImage = images[category]; // Access the single image for each category

    if (!categoryImage) return; // Skip if no image available

    if (idx > 0) pdf.addPage(); // Adds a new page after the first image
    pdf.addImage(
      categoryImage.image,
      "PNG",
      35,
      margin + headerSize,
      chartWidth,
      chartHeight
    ); // Add image
    addText(pdf, response, titles[idx]); // Add category text
  });

  // Add header and text block to the first page
  pdf.addPage();
  //Last page
  pdf.setTextColor("#000000");
  addHeader(pdf, "Empfehlungen");
  const blockText = response["Auswertung"];
  let textPositionY = 50; // Start from the top of the new page
  const lineHeight = 7; // Adjust line height as needed
  const textWidth = 170; // Maximum width of text block
  const textColor = "#000000";
  // Split text into lines considering the maxWidth
  console.log(typeof blockText);
  const cleanedBlockText = blockText.replace(/[#\*-]/g, "")// Remove all '#' characters

  const lines = cleanedBlockText
    .split("\n")
    .flatMap((part) => pdf.splitTextToSize(part, textWidth));

  // Add lines with checking for page overflow
  lines.forEach((line) => {
    if (textPositionY > pdf.internal.pageSize.height - 30) {
      // Check if we need a new page
      pdf.addPage();
      addHeader(pdf, "");
      textPositionY = 40; // Reset Y position to the top of the new page
    }
    pdf.text(line.trimStart() , 20, textPositionY, { color: textColor });
    textPositionY += lineHeight;
  });

  // Add additional text content as a new page
  //addFooter(pdf);
  pdf.addPage(pdf)
  pdf.setTextColor("#000000");
  textPositionY = 50
  addHeader(pdf, "Berufe"); // Assuming this function is implemented elsewhere
  
  const blockText2 = response["Berufe"]; // Assume this comes from some previous response
  const cleanedBlockText2 = blockText2.replace(/[#\*-]/g, "");
  
  const lines2 = cleanedBlockText2
    .split("\n")
    .flatMap(part => pdf.splitTextToSize(part, textWidth));
  
  lines2.forEach(line => {
    if (textPositionY > pdf.internal.pageSize.height - 30) {
      pdf.addPage();
      addHeader(pdf, ""); // Re-add header on new page
      textPositionY = 40;  // Reset Y position
    }
    pdf.text(line.trimStart(), 20, textPositionY);
    textPositionY += lineHeight;
  });
  
  addFooter(pdf); // Assuming this function is implemented elsewhere
  pdf.save("Auswertung.pdf");
};

function addText(pdf, response, title) {
  //pdf.addPage();
  addHeader(pdf, title);
  pdf.setFontSize(10);
  const blockText = response[title];
  let textPositionY = 190; // Start from the top of the new page
  const lineHeight = 7; // Adjust line height as needed
  const textWidth = 180; // Maximum width of text block
  const textColor = "#000000";
  // Split text into lines considering the maxWidth
  const lines = blockText.replace(/[#\*-]/g, "")
    .split("\n")
    .flatMap((part) => pdf.splitTextToSize(part, textWidth));

  // Add lines with checking for page overflow
  lines.forEach((line) => {
    if (textPositionY > pdf.internal.pageSize.height - 20) {
      // Check if we need a new page
      pdf.addPage();
      addHeader(pdf, "");
      textPositionY = 50; // Reset Y position to the top of the new page
    }
    pdf.text(line .trimStart(), 20, textPositionY, { color: textColor });
    textPositionY += lineHeight;
  });
}
