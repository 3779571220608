import { Layout } from "./Layout";

export function CaseStudy() {
  return (
    <Layout>
      <div className="text-primary p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-lg mt-8">
        <h1 className="text-3xl font-bold text-primary mb-4">
          Profil von Anna
        </h1>
        <p className="text-gray-700 mb-6">
          Anna ist eine Person, die gut mit anderen zusammenarbeitet und
          zuverlässig ihre Aufgaben erfüllt. Anna ist eher introvertiert und
          zeigt wenig Anfälligkeit für Stress oder negative Emotionen. Sie ist
          offen für neue Erfahrungen und Ideen, jedoch nicht extrem
          experimentierfreudig.
        </p>

        <p className="text-gray-700 mb-6">
          In Bezug auf ihre Fähigkeiten zeigt Anna eine durchschnittliche
          Kreativität und moderate empathische Fähigkeiten. Ihre numerische
          Intelligenz ist stark ausgeprägt, was darauf hindeutet, dass sie sehr
          gut mit Zahlen umgehen kann. Ihre Schreibkompetenzen sind jedoch nicht
          ganz so stark entwickelt.
        </p>

        <p className="text-gray-700 mb-6">
          Annas Vorlieben liegen in der Teamarbeit und Unterstützung anderer.
          Ihr starkes Interesse am Kreieren zeigt ihre kreative Seite. Zudem
          besitzt sie gute analytische und verhandlungstechnische Fähigkeiten
          besitzt.
        </p>

        <p className="text-gray-700 mb-6">
          Besonders ausgeprägte Neigungen hat Anna im Bereich der
          Naturwissenschaften. Deutlich weniger hat sie für Technik übrig. Auch
          gesellschaftliche Themen und Künste entsprechen eher weniger ihren
          Neigungen.
        </p>

        <p className="text-gray-700 mb-6">
          Insgesamt ist Anna eine kooperative und gewissenhafte Person mit einer
          starken mathematischen Begabung und einer ausgeprägten Neigung zur
          Wissenschaft. Sie hat die Fähigkeit, effektiv zu verhandeln und neue
          Ideen zu entwickeln. Anna bevorzugt eine unterstützende und
          koordinierende Rolle in sozialen und kreativen Kontexten, während
          technische und künstlerische Felder weniger in ihrem Fokus stehen.
        </p>

        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-semibold text-primary mb-3">
              1. Psychologie
            </h2>
            <p className="text-gray-700">
              <strong>Begründung:</strong> Für Anna könnte das Studium der
              Psychologie besonders reizvoll sein, da es ihre hohe
              Verträglichkeit ideal nutzt. Ihre Neigung, anderen zu helfen,
              macht sie zu einer geeigneten Kandidatin für Berufe, in denen
              menschliches Wohlbefinden und zwischenmenschliche Beziehungen im
              Vordergrund stehen. Außerdem bietet das Feld genügend Raum für
              analytische Ansätze und hat zusätzlich Überschneidungen mit
              naturwissenschaftlichen Themen.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-primary mb-3">
              2. Wirtschaftswissenschaften
            </h2>
            <p className="text-gray-700">
              <strong>Begründung:</strong> Annas stark ausgeprägte numerische
              Intelligenz und ihr großes Interesse am Verhandeln machen die
              Wirtschaftswissenschaften zu einer hervorragenden Wahl. Diese
              Disziplin verbindet analytisches Denken mit sozialer Interaktion,
              was Annas Fähigkeit, effektiv zu verhandeln und komplexe Probleme
              zu lösen, ideal einsetzt. Zudem könnten ihre
              Koordinationsfähigkeiten und ihre Gewissenhaftigkeit in Bereichen
              wie Projektmanagement oder Unternehmensführung besonders gut zur
              Geltung kommen.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-primary mb-3">3. Medizin</h2>
            <p className="text-gray-700">
              <strong>Begründung:</strong> Annas tiefes Interesse an
              Naturwissenschaften und ihre starke altruistische Motivation
              machen das Medizinstudium zu einer passenden Option. Ihre
              Gewissenhaftigkeit und ihre Fähigkeit, in stressigen Situationen
              ruhig zu bleiben, sind wesentliche Eigenschaften für eine
              erfolgreiche medizinische Laufbahn. Auch wenn ihre technischen
              Fähigkeiten weniger ausgeprägt sind, bietet die Medizin viele
              Bereiche, in denen ihre Stärken im zwischenmenschlichen Bereich
              und ihre naturwissenschaftliche Neugier von großem Vorteil sind.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
