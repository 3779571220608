import { Layout } from "./Layout";
import React, { useEffect } from "react";

export function Datenschutzvereinbarung() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Datenschutzvereinbarung - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  return (
    <Layout pageTitle={"Datenschutzvereinbarung"}>
      <div className="text-primary p-8">
        <h1>Datenschutzerkl&auml;rung</h1>

        <h2>1) Einleitung und Kontaktdaten des Verantwortlichen</h2>

        <p>
          <b>1.1</b>&nbsp;Wir freuen uns, dass Sie unsere Website besuchen und
          bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie
          &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung
          unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        </p>

        <p>
          <b>1.2</b>&nbsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf
          dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist
          Futedu GbR, Stieglitzstr. 8, 04229 Leipzig, Deutschland, Tel.:
          01729647870, E-Mail: info@futedu.de. Der f&uuml;r die Verarbeitung von
          personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche
          oder juristische Person, die allein oder gemeinsam mit anderen
          &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen
          Daten entscheidet.
        </p>

        <h2>2) Datenerfassung beim Besuch unserer Website</h2>

        <p>
          <b>2.1</b>&nbsp;Bei der blo&szlig; informatorischen Nutzung unserer
          Website, also wenn Sie sich nicht registrieren oder uns anderweitig
          Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr
          Browser an den Seitenserver &uuml;bermittelt (sog.
          &bdquo;Server-Logfiles&#8220;). Wenn Sie unsere Website aufrufen,
          erheben wir die folgenden Daten, die f&uuml;r uns technisch
          erforderlich sind, um Ihnen die Website anzuzeigen:
        </p>

        <ul>
          <li>Unsere besuchte Website</li>
          <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
          <li>Menge der gesendeten Daten in Byte</li>
          <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
          <li>Verwendeter Browser</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
        </ul>

        <p>
          Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
          auf Basis unseres berechtigten Interesses an der Verbesserung der
          Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine
          Weitergabe oder anderweitige Verwendung der Daten findet nicht statt.
          Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich
          zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine
          rechtswidrige Nutzung hinweisen.
        </p>

        <p>
          <b>2.2</b>&nbsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und
          zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer
          vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den
          Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie
          k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge
          &bdquo;https://&#8220; und dem Schloss-Symbol in Ihrer Browserzeile
          erkennen.
        </p>

        <h2>3) Hosting &amp; Content-Delivery-Network</h2>

        <p>
          F&uuml;r das Hosting unserer Website und die Darstellung der
          Seiteninhalte nutzen wir einen Anbieter, der seine Leistungen selbst
          oder durch ausgew&auml;hlte Sub-Unternehmer ausschlie&szlig;lich auf
          Servern innerhalb der Europ&auml;ischen Union erbringt.
        </p>

        <p>
          S&auml;mtliche auf unserer Website erhobenen Daten werden auf diesen
          Servern verarbeitet.
        </p>

        <p>
          Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
          geschlossen, der den Schutz der Daten unserer Seitenbesucher
          sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
        </p>

        <h2>4) Cookies</h2>

        <p>
          Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
          bestimmter Funktionen zu erm&ouml;glichen, verwenden wir Cookies, also
          kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden.
          Teilweise werden diese Cookies nach Schlie&szlig;en des Browsers
          automatisch wieder gel&ouml;scht (sog. &bdquo;Session-Cookies&#8220;),
          teilweise verbleiben diese Cookies l&auml;nger auf Ihrem Endger&auml;t
          und erm&ouml;glichen das Speichern von Seiteneinstellungen (sog.
          &bdquo;persistente Cookies&#8220;). Im letzteren Fall k&ouml;nnen Sie
          die Speicherdauer der &Uuml;bersicht zu den Cookie-Einstellungen Ihres
          Webbrowsers entnehmen.
        </p>

        <p>
          Sofern durch einzelne von uns eingesetzte Cookies auch
          personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
          gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur
          Durchf&uuml;hrung des Vertrages, gem&auml;&szlig; Art. 6 Abs. 1 lit. a
          DSGVO im Falle einer erteilten Einwilligung oder gem&auml;&szlig; Art.
          6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an
          der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer
          kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
        </p>

        <p>
          Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
          Setzen von Cookies informiert werden und einzeln &uuml;ber deren
          Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte
          F&auml;lle oder generell ausschlie&szlig;en k&ouml;nnen.
        </p>

        <p>
          Bitte beachten Sie, dass bei Nichtannahme von Cookies die
          Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.
        </p>

        <h2>5) Kontaktaufnahme</h2>

        <p>
          Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
          E-Mail) werden &ndash; ausschlie&szlig;lich zum Zweck der Bearbeitung
          und Beantwortung Ihres Anliegens und nur im daf&uuml;r erforderlichen
          Umfang &ndash; personenbezogene Daten verarbeitet.
        </p>

        <p>
          Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser
          berechtigtes Interesse an der Beantwortung Ihres Anliegens
          gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung
          auf einen Vertrag ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r
          die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden
          gel&ouml;scht, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst,
          dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist
          und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
        </p>

        <h2>6) Datenverarbeitung bei Er&ouml;ffnung eines Kundenkontos</h2>

        <p>
          Gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene
          Daten im jeweils erforderlichen Umfang weiterhin erhoben und
          verarbeitet, wenn Sie uns diese bei der Er&ouml;ffnung eines
          Kundenkontos mitteilen. Welche Daten f&uuml;r die Kontoer&ouml;ffnung
          erforderlich sind, entnehmen Sie der Eingabemaske des entsprechenden
          Formulars auf unserer Website.
        </p>

        <p>
          Eine L&ouml;schung Ihres Kundenkontos ist jederzeit m&ouml;glich und
          kann durch eine Nachricht an die o.g. Adresse des Verantwortlichen
          erfolgen. Nach L&ouml;schung Ihres Kundenkontos werden Ihre Daten
          gel&ouml;scht, sofern alle dar&uuml;ber geschlossenen Vertr&auml;ge
          vollst&auml;ndig abgewickelt sind, keine gesetzlichen
          Aufbewahrungsfristen entgegenstehen und unsererseits kein berechtigtes
          Interesse an der Weiterspeicherung fortbesteht.
        </p>

        <h2>7) Datenverarbeitung zur Vertragsabwicklung</h2>

        <p>
          <b>7.1</b>&nbsp;Zur Abwicklung des Vertrags arbeiten wir mit dem / den
          nachstehenden Dienstleister(n) zusammen, die uns ganz oder teilweise
          bei der Durchf&uuml;hrung geschlossener Vertr&auml;ge
          unterst&uuml;tzen. An diese Dienstleister werden nach Ma&szlig;gabe
          der folgenden Informationen gewisse personenbezogene Daten
          &uuml;bermittelt.
        </p>

        <p>
          <b>7.2</b>&nbsp;Verwendung von Paymentdienstleistern
          (Zahlungsdiensten)
        </p>

        <p>- Apple Pay</p>

        <p>
          Wenn Sie sich f&uuml;r die Zahlungsart &bdquo;Apple Pay&#8220; der
          Apple Distribution International (Apple), Hollyhill Industrial Estate,
          Hollyhill, Cork, Irland, entscheiden, erfolgt die Zahlungsabwicklung
          &uuml;ber die &bdquo;Apple Pay&#8220;-Funktion Ihres mit iOS, watchOS
          oder macOS betriebenen Endger&auml;tes durch die Belastung einer bei
          &bdquo;Apple Pay&#8220; hinterlegten Zahlungskarte. Apple Pay
          verwendet hierbei Sicherheitsfunktionen, die in die Hardware und
          Software Ihres Ger&auml;ts integriert sind, um Ihre Transaktionen zu
          sch&uuml;tzen. F&uuml;r die Freigabe einer Zahlung ist somit die
          Eingabe eines zuvor durch Sie festgelegten Codes sowie die
          Verifizierung mittels der &bdquo;Face ID&#8220;- oder &bdquo;Touch
          ID&#8220; &ndash; Funktion ihres Endger&auml;tes erforderlich.
        </p>

        <p>
          Zum Zwecke der Zahlungsabwicklung werden Ihre im Rahmen des
          Bestellvorgangs mitgeteilten Informationen nebst den Informationen
          &uuml;ber Ihre Bestellung in verschl&uuml;sselter Form an Apple
          weitergegeben. Apple verschl&uuml;sselt diese Daten sodann erneut mit
          einem entwicklerspezifischen Schl&uuml;ssel, bevor die Daten zur
          Durchf&uuml;hrung der Zahlung an den Zahlungsdienstleister der in
          Apple Pay hinterlegten Zahlungskarte &uuml;bermittelt werden. Die
          Verschl&uuml;sselung sorgt daf&uuml;r, dass nur die Website, &uuml;ber
          die der Einkauf get&auml;tigt wurde, auf die Zahlungsdaten zugreifen
          kann. Nachdem die Zahlung get&auml;tigt wurde, sendet Apple zur
          Best&auml;tigung des Zahlungserfolges Ihre Ger&auml;teaccountnummer
          sowie einen transaktionsspezifischen, dynamischen Sicherheitscode an
          die Ausgangswebsite.
        </p>

        <p>
          Sofern bei den beschriebenen &Uuml;bermittlungen personenbezogene
          Daten verarbeitet werden, erfolgt die Verarbeitung
          ausschlie&szlig;lich zum Zwecke der Zahlungsabwicklung
          gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO.
        </p>

        <p>
          Apple bewahrt anonymisierte Transaktionsdaten auf, darunter der
          ungef&auml;hre Kaufbetrag, das ungef&auml;hre Datum und die
          ungef&auml;hre Uhrzeit sowie die Angabe, ob die Transaktion
          erfolgreich abgeschlossen wurde. Durch die Anonymisierung wird ein
          Personenbezug vollst&auml;ndig ausgeschlossen. Apple nutzt die
          anonymisierten Daten zur Verbesserung von &bdquo;Apple Pay&#8220; und
          anderen Apple-Produkten und Diensten.
        </p>

        <p>
          Wenn Sie Apple Pay auf dem iPhone oder der Apple Watch zum Abschluss
          eines Kaufs verwenden, den Sie &uuml;ber Safari auf dem Mac
          get&auml;tigt haben, kommunizieren der Mac und das
          Autorisierungsger&auml;t &uuml;ber einen verschl&uuml;sselten Kanal
          auf den Apple-Servern. Apple verarbeitet oder speichert keine dieser
          Informationen in einem Format, mit dem Ihre Person identifiziert
          werden kann. Sie k&ouml;nnen die M&ouml;glichkeit zur Verwendung von
          Apple Pay auf Ihrem Mac in den Einstellungen Ihres iPhone
          deaktivieren. Gehen Sie zu &quot;Wallet &amp; Apple Pay&quot;, und
          deaktivieren Sie &quot;Zahlungen auf Mac erlauben&quot;.
        </p>

        <p>
          Weitere Hinweise zum Datenschutz bei Apple Pay finden Sie unter der
          nachstehenden Internetadresse:{" "}
          <a href="https://support.apple.com/de-de/HT203027" target="_blank">
            https://support.apple.com
            <wbr />
            /de-de
            <wbr />
            /HT203027
          </a>
          <br />- Google Pay
        </p>

        <p>
          Wenn Sie sich f&uuml;r die Zahlungsart &bdquo;Google Pay&#8220; der
          Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5,
          Irland (&bdquo;Google&#8220;) entscheiden, erfolgt die
          Zahlungsabwicklung &uuml;ber die &bdquo;Google Pay&#8220;-Applikation
          Ihres mit mindestens Android 4.4 (&bdquo;KitKat&#8220;) betriebenen
          und &uuml;ber eine NFC-Funktion verf&uuml;genden mobilen
          Endger&auml;ts durch die Belastung einer bei Google Pay hinterlegten
          Zahlungskarte oder einem dort verifizierten Bezahlsystem (z.B.
          PayPal). F&uuml;r die Freigabe einer Zahlung &uuml;ber Google Pay in
          H&ouml;he von mehr als 25,- &euro; ist das vorherige Entsperren Ihres
          mobilen Endger&auml;tes durch die jeweils eingerichtete
          Verifikationsma&szlig;nahme (etwa Gesichtserkennung, Passwort,
          Fingerabdruck oder Muster) erforderlich.
        </p>

        <p>
          Zum Zwecke der Zahlungsabwicklung werden Ihre im Rahmen des
          Bestellvorgangs mitgeteilten Informationen nebst den Informationen
          &uuml;ber Ihre Bestellung an Google weitergegeben. Google
          &uuml;bermittelt sodann Ihre in Google Pay hinterlegten
          Zahlungsinformationen in Form einer einmalig vergebenen
          Transaktionsnummer an die Ausgangswebsite, mit der eine erfolgte
          Zahlung verifiziert wird. Diese Transaktionsnummer enth&auml;lt
          keinerlei Informationen zu den realen Zahldaten Ihrer bei Google Pay
          hinterlegten Zahlungsmittel, sondern wird als einmalig g&uuml;ltiger
          numerischer Token erstellt und &uuml;bermittelt. Bei s&auml;mtlichen
          Transaktionen &uuml;ber Google Pay tritt Google lediglich als
          Vermittler zur Abwicklung des Bezahlvorgangs auf. Die
          Durchf&uuml;hrung der Transaktion erfolgt ausschlie&szlig;lich im
          Verh&auml;ltnis zwischen dem Nutzer und der Ausgangswebsite durch
          Belastung des bei Google Pay hinterlegten Zahlungsmittels.
        </p>

        <p>
          Sofern bei den beschriebenen &Uuml;bermittlungen personenbezogene
          Daten verarbeitet werden, erfolgt die Verarbeitung
          ausschlie&szlig;lich zum Zwecke der Zahlungsabwicklung
          gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO.
        </p>

        <p>
          Google beh&auml;lt sich vor, bei jeder &uuml;ber Google Pay
          get&auml;tigten Transaktion bestimmte vorgangsspezifische
          Informationen zu erheben, zu speichern und auszuwerten. Hierzu
          geh&ouml;ren Datum, Uhrzeit und Betrag der Transaktion,
          H&auml;ndlerstandort und -beschreibung, eine vom H&auml;ndler
          bereitgestellte Beschreibung der gekauften Waren oder Dienste, Fotos,
          die Sie der Transaktion beigef&uuml;gt haben, der Name und die
          E-Mail-Adresse des Verk&auml;ufers und K&auml;ufers bzw. des Absenders
          und Empf&auml;ngers, die verwendete Zahlungsmethode, Ihre Beschreibung
          f&uuml;r den Grund der Transaktion sowie gegebenenfalls das mit der
          Transaktion verbundene Angebot.
        </p>

        <p>
          Laut Google erfolgt diese Verarbeitung ausschlie&szlig;lich
          gem&auml;&szlig; Art. 6 Abs.1 lit. f DSGVO auf Basis des berechtigten
          Interesses an der ordnungsgem&auml;&szlig;en Rechnungslegung, der
          Verifizierung von Vorgangsdaten und der Optimierung und
          Funktionserhaltung des Google Pay-Dienstes.
        </p>

        <p>
          Google beh&auml;lt sich au&szlig;erdem vor, die verarbeiteten
          Vorgangsdaten mit weiteren Informationen zusammenzuf&uuml;hren, die
          bei der Nutzung weiterer Google-Dienste durch Google erhoben und
          gespeichert werden.
        </p>

        <p>Die Nutzungsbedingungen von Google Pay finden sich hier:</p>

        <p>
          <a
            href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&amp;ldt=googlepaytos&amp;ldl=de"
            target="_blank"
          >
            https://payments.google.com
            <wbr />
            /payments
            <wbr />
            /apis-secure
            <wbr />
            /u
            <wbr />
            /0
            <wbr />
            /get_legal_document
            <wbr />
            ?ldo=0
            <wbr />
            &amp;ldt=googlepaytos
            <wbr />
            &amp;ldl=de
          </a>
          <br />
          Weitere Hinweise zum Datenschutz bei Google Pay finden Sie unter der
          nachstehenden Internetadresse:
          <br />
          <a
            href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=de"
            target="_blank"
          >
            https://payments.google.com
            <wbr />
            /payments
            <wbr />
            /apis-secure
            <wbr />
            /get_legal_document
            <wbr />
            ?ldo=0
            <wbr />
            &amp;ldt=privacynotice
            <wbr />
            &amp;ldl=de
          </a>
          <br />- Paypal
        </p>

        <p>
          Auf dieser Website stehen eine oder mehrere Online-Zahlungsarten des
          folgenden Anbieters zur Verf&uuml;gung: PayPal (Europe) S.a.r.l. et
          Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxemburg
        </p>

        <p>
          Bei Auswahl einer Zahlungsart des Anbieters, bei der Sie in
          Vorleistung gehen, werden an diesen Ihre im Rahmen des Bestellvorgangs
          mitgeteilten Zahlungsdaten (darunter Name, Anschrift, Bank- und
          Zahlkarteninformationen, W&auml;hrung und Transaktionsnummer) sowie
          Informationen &uuml;ber den Inhalt Ihrer Bestellung gem&auml;&szlig;
          Art. 6 Abs. 1 lit. b DSGVO weitergegeben. Die Weitergabe Ihrer Daten
          erfolgt in diesem Falle ausschlie&szlig;lich zum Zweck der
          Zahlungsabwicklung mit dem Anbieter und nur insoweit, als sie
          hierf&uuml;r erforderlich ist.
        </p>

        <p>
          Bei Auswahl einer Zahlungsart, bei der wir in Vorleistung gehen,
          werden Sie im Bestellablauf auch aufgefordert, bestimmte
          pers&ouml;nliche Daten (Vor- und Nachname, Stra&szlig;e, Hausnummer,
          Postleitzahl, Ort, Geburtsdatum, E-Mail-Adresse, Telefonnummer, ggf.
          Daten zu einem alternativen Zahlungsmittel) anzugeben.
        </p>

        <p>
          Um in solchen F&auml;llen unser berechtigtes Interesse an der
          Feststellung Ihrer Zahlungsf&auml;higkeit zu wahren, werden diese
          Daten von uns gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zum Zwecke
          einer Bonit&auml;tspr&uuml;fung an den Anbieter weitergeleitet. Der
          Anbieter pr&uuml;ft auf Basis der von Ihnen angegebenen
          pers&ouml;nlichen Daten sowie weiterer Daten (wie etwa Warenkorb,
          Rechnungsbetrag, Bestellhistorie, Zahlungserfahrungen), ob die von
          Ihnen ausgew&auml;hlte Zahlungsm&ouml;glichkeit im Hinblick auf
          Zahlungs- und/oder Forderungsausfallrisiken gew&auml;hrt werden kann.
        </p>

        <p>
          Die Bonit&auml;tsauskunft kann Wahrscheinlichkeitswerte enthalten
          (sog. Score-Werte). Soweit Score-Werte in das Ergebnis der
          Bonit&auml;tsauskunft einflie&szlig;en, haben sie ihre Grundlage in
          einem wissenschaftlich anerkannten mathematisch-statistischen
          Verfahren. In die Berechnung der Score-Werte flie&szlig;en unter
          anderem, aber nicht ausschlie&szlig;lich, Anschriftendaten ein.
        </p>

        <p>
          Sie k&ouml;nnen dieser Verarbeitung Ihrer Daten jederzeit durch eine
          Nachricht an uns oder gegen&uuml;ber dem Anbieter widersprechen.
          Jedoch bleibt der Anbieter ggf. weiterhin berechtigt, Ihre
          personenbezogenen Daten zu verarbeiten, sofern dies zur
          vertragsgem&auml;&szlig;en Zahlungsabwicklung erforderlich ist.
          <br />- Paypal Checkout
        </p>

        <p>
          Diese Website nutzt PayPal Checkout, ein Online-Zahlungssystem von
          PayPal, das sich aus PayPal-eigenen Zahlungsarten und lokalen
          Zahlmethoden von Drittanbietern zusammensetzt.
        </p>

        <p>
          Bei Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal
          oder &ndash; falls angeboten &ndash; &bdquo;Sp&auml;ter
          Bezahlen&#8220; via PayPal geben wir Ihre Zahlungsdaten im Rahmen der
          Zahlungsabwicklung an die PayPal (Europe) S.a.r.l. et Cie, S.C.A.,
          22-24 Boulevard Royal, L-2449 Luxemburg (nachfolgend
          &quot;PayPal&quot;), weiter. Die Weitergabe erfolgt gem&auml;&szlig;
          Art. 6 Abs. 1 lit. b DSGVO und nur insoweit, als dies f&uuml;r die
          Zahlungsabwicklung erforderlich ist.
        </p>

        <p>
          PayPal beh&auml;lt sich f&uuml;r die Zahlungsmethoden Kreditkarte via
          PayPal, Lastschrift via PayPal oder &ndash; falls angeboten -
          &bdquo;Sp&auml;ter bezahlen&#8220; via PayPal &ndash; die
          Durchf&uuml;hrung einer Bonit&auml;tsauskunft vor. Hierf&uuml;r werden
          Ihre Zahlungsdaten gegebenenfalls gem&auml;&szlig; Art. 6 Abs. 1 lit.
          f DSGVO auf Basis des berechtigten Interesses von PayPal an der
          Feststellung Ihrer Zahlungsf&auml;higkeit an Auskunfteien
          weitergegeben. Das Ergebnis der Bonit&auml;tspr&uuml;fung in Bezug auf
          die statistische Zahlungsausfallwahrscheinlichkeit verwendet PayPal
          zum Zweck der Entscheidung &uuml;ber die Bereitstellung der jeweiligen
          Zahlungsmethode. Die Bonit&auml;tsauskunft kann
          Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit
          Score-Werte in das Ergebnis der Bonit&auml;tsauskunft
          einflie&szlig;en, haben sie ihre Grundlage in einem wissenschaftlich
          anerkannten mathematisch-statistischen Verfahren. In die Berechnung
          der Score-Werte flie&szlig;en unter anderem, aber nicht
          ausschlie&szlig;lich, Anschriftendaten ein. Sie k&ouml;nnen dieser
          Verarbeitung Ihrer Daten jederzeit durch eine Nachricht an PayPal
          widersprechen. Jedoch bleibt PayPal ggf. weiterhin berechtigt, Ihre
          personenbezogenen Daten zu verarbeiten, sofern dies zur
          vertragsgem&auml;&szlig;en Zahlungsabwicklung erforderlich ist.
        </p>

        <p>
          Bei Verf&uuml;gbarkeit und Auswahl der PayPal-Zahlungsart
          &bdquo;Rechnungskauf&#8220; werden Ihre Zahlungsdaten zur Vorbereitung
          der Zahlung zun&auml;chst an PayPal &uuml;bermittelt, woraufhin PayPal
          diese zur Durchf&uuml;hrung der Zahlung an die Ratepay GmbH,
          Franklinstra&szlig;e 28-29, 10587 Berlin (&quot;Ratepay&quot;)
          weiterleitet. Rechtsgrundlage ist jeweils Art. 6 Abs. 1 lit. b DSGVO.
          In diesem Fall f&uuml;hrt RatePay im eigenen Namen eine
          Identit&auml;ts- und Bonit&auml;tspr&uuml;fung zur Feststellung der
          Zahlungsf&auml;higkeit entsprechend dem oben bereits genannten Prinzip
          durch und gibt Ihre Zahlungsdaten aufgrund des berechtigten Interesses
          an der Feststellung der Zahlungsf&auml;higkeit gem&auml;&szlig; Art. 6
          Abs. 1 lit. f DSGVO an Auskunfteien weiter. Eine Liste der
          Auskunfteien, auf die Ratepay zur&uuml;ckgreifen kann, findet sich
          hier:{" "}
          <a
            href="https://www.ratepay.com/legal-payment-creditagencies/"
            target="_blank"
          >
            https://www.ratepay.com
            <wbr />
            /legal-payment-creditagencies
            <wbr />/
          </a>
        </p>

        <p>
          Bei Nutzung der Zahlungsart eines lokalen Drittanbieters werden Ihre
          Zahlungsdaten zur Vorbereitung der Zahlung zun&auml;chst
          gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO an PayPal weitergegeben.
          Abh&auml;ngig von Ihrer Auswahl einer verf&uuml;gbaren lokalen
          Zahlungsart &uuml;bermittelt PayPal Ihre Zahlungsdaten zur
          Durchf&uuml;hrung der Zahlung sodann gem&auml;&szlig; Art. 6 Abs. 1
          lit. b DSGVO an den entsprechenden Anbieter:
        </p>

        <p>
          - Apple Pay (Apple Distribution International (Apple), Hollyhill
          Industrial Estate, Hollyhill, Cork, Irland)
          <br />
          - Google Pay (Google Ireland Limited, Gordon House, 4 Barrow St,
          Dublin, D04 E5W5, Irland)
          <br />
          - Sofort (SOFORT GmbH, Theresienh&ouml;he 12, 80339 M&uuml;nchen,
          Deutschland)
          <br />
          - iDeal (Currence Holding BV, Beethovenstraat 300 Amsterdam,
          Niederlande)
          <br />
          - giropay (Paydirekt GmbH, Stephanstr. 14-16, 60313 Frankfurt am Main
          <br />
          - bancontact (Bancontact Payconiq Company, Rue d'Arlon 82, 1040
          Br&uuml;ssel, Belgien)
          <br />
          - blik (Polski Standard P&lstrok;atno&sacute;ci sp. z o.o., ul.
          Czerniakowska 87A, 00-718 Warschau, Polen)
          <br />
          - eps (PSA Payment Services Austria GmbH, Handelskai 92, Gate 2<br />
          1200 Wien, &Ouml;sterreich)
          <br />
          - MyBank (PRETA S.A.S, 40 Rue de Courcelles, F-75008 Paris,
          Frankreich)
          <br />- Przelewy24 (PayPro SA, Kanclerska 15A, 60-326 Pozna&nacute;,
          Polen)
        </p>

        <p>
          Weitere datenschutzrechtliche Informationen entnehmen Sie bitte der
          Datenschutzerkl&auml;rung von PayPal:{" "}
          <a
            href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
            target="_blank"
          >
            https://www.paypal.com
            <wbr />
            /de
            <wbr />
            /webapps
            <wbr />
            /mpp
            <wbr />
            /ua
            <wbr />
            /privacy-full
          </a>
          <br />- Stripe
        </p>

        <p>
          Auf dieser Website stehen eine oder mehrere Online-Zahlungsarten des
          folgenden Anbieters zur Verf&uuml;gung: Stripe Payments Europe Ltd., 1
          Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland
        </p>

        <p>
          Bei Auswahl einer Zahlungsart des Anbieters, bei der Sie in
          Vorleistung gehen (etwa Kreditkartenzahlung), werden an diesen Ihre im
          Rahmen des Bestellvorgangs mitgeteilten Zahlungsdaten (darunter Name,
          Anschrift, Bank- und Zahlkarteninformationen, W&auml;hrung und
          Transaktionsnummer) sowie Informationen &uuml;ber den Inhalt Ihrer
          Bestellung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO weitergegeben.
          Die Weitergabe Ihrer Daten erfolgt in diesem Falle
          ausschlie&szlig;lich zum Zweck der Zahlungsabwicklung mit dem Anbieter
          und nur insoweit, als sie hierf&uuml;r erforderlich ist.
        </p>

        <p>
          Bei Auswahl einer Zahlungsart, bei der der Anbieter in Vorleistung
          geht (etwa Rechnungs- oder Ratenkauf bzw. Lastschrift), werden Sie im
          Bestellablauf auch aufgefordert, bestimmte pers&ouml;nliche Daten
          (Vor- und Nachname, Stra&szlig;e, Hausnummer, Postleitzahl, Ort,
          Geburtsdatum, E-Mail-Adresse, Telefonnummer, ggf. Daten zu einem
          alternativen Zahlungsmittel) anzugeben.
        </p>

        <p>
          Um unser berechtigtes Interesse an der Feststellung der
          Zahlungsf&auml;higkeit unserer Kunden zu wahren, werden diese Daten
          von uns gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zum Zweck einer
          Bonit&auml;tspr&uuml;fung an den Anbieter weitergeleitet. Der Anbieter
          pr&uuml;ft auf Basis der von Ihnen angegebenen pers&ouml;nlichen Daten
          sowie weiterer Daten (wie etwa Warenkorb, Rechnungsbetrag,
          Bestellhistorie, Zahlungserfahrungen), ob die von Ihnen
          ausgew&auml;hlte Zahlungsm&ouml;glichkeit im Hinblick auf Zahlungs-
          und/oder Forderungsausfallrisiken gew&auml;hrt werden kann.
        </p>

        <p>
          Die Bonit&auml;tsauskunft kann Wahrscheinlichkeitswerte enthalten
          (sog. Score-Werte). Soweit Score-Werte in das Ergebnis der
          Bonit&auml;tsauskunft einflie&szlig;en, haben sie ihre Grundlage in
          einem wissenschaftlich anerkannten mathematisch-statistischen
          Verfahren. In die Berechnung der Score-Werte flie&szlig;en unter
          anderem, aber nicht ausschlie&szlig;lich, Anschriftendaten ein.
        </p>

        <p>
          Sie k&ouml;nnen dieser Verarbeitung Ihrer Daten jederzeit durch eine
          Nachricht an uns oder gegen&uuml;ber dem Anbieter widersprechen.
          Jedoch bleibt der Anbieter ggf. weiterhin berechtigt, Ihre
          personenbezogenen Daten zu verarbeiten, sofern dies zur
          vertragsgem&auml;&szlig;en Zahlungsabwicklung erforderlich ist.
        </p>

        <h2>8) Webanalysedienste</h2>

        <p>
          <b>8.1</b>&nbsp;Google Analytics 4
        </p>

        <p>
          Diese Website benutzt Google Analytics 4, einen Webanalysedienst der
          Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5,
          Irland (&quot;Google&quot;), der eine Analyse Ihrer Benutzung unserer
          Website erm&ouml;glicht.
        </p>

        <p>
          Standardm&auml;&szlig;ig werden beim Besuch der Website durch Google
          Analytics 4 Cookies gesetzt, die als kleine Textbausteine auf Ihrem
          Endger&auml;t abgelegt werden und bestimmte Informationen erheben. Zum
          Umfang dieser Informationen geh&ouml;rt auch Ihre IP-Adresse, die
          allerdings von Google um die letzten Ziffern gek&uuml;rzt wird, um
          eine direkte Personenbeziehbarkeit auszuschlie&szlig;en.
        </p>

        <p>
          Die Informationen werden an Server von Google &uuml;bertragen und dort
          weiterverarbeitet. Dabei sind auch &Uuml;bermittlungen an Google LLC
          mit Sitz in den USA m&ouml;glich.
        </p>

        <p>
          Google nutzt die erhobenen Informationen in unserem Auftrag, um Ihre
          Nutzung der Website auszuwerten, Reports &uuml;ber die
          Websiteaktivit&auml;ten f&uuml;r uns zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen zu erbringen. Die im Rahmen von Google Analytics von
          Ihrem Browser &uuml;bermittelte und gek&uuml;rzte IP-Adresse wird
          nicht mit anderen Daten von Google zusammengef&uuml;hrt. Die im Rahmen
          der Nutzung von Google Analytics 4 erhobenen Daten werden f&uuml;r die
          Dauer von zwei Monaten gespeichert und anschlie&szlig;end
          gel&ouml;scht.
        </p>

        <p>
          Alle vorstehend beschriebenen Verarbeitungen, insbesondere das Setzen
          von Cookies auf dem verwendeten Endger&auml;t, erfolgen nur, wenn Sie
          uns hierf&uuml;r Ihre ausdr&uuml;ckliche Einwilligung gem. Art. 6 Abs.
          1 lit. a DSGVO erteilt haben.
          <br />
          Ohne Ihre Einwilligung unterbleibt der Einsatz von Google Analytics 4
          w&auml;hrend Ihres Seitenbesuchs. Sie k&ouml;nnen Ihre erteilte
          Einwilligung mit Wirkung f&uuml;r die Zukunft jederzeit widerrufen. Um
          Ihr Widerrufsrecht auszu&uuml;ben, deaktivieren Sie bitte diesen
          Dienst &uuml;ber das auf der Website bereitgestellte
          &bdquo;Cookie-Consent-Tool&#8220;.
        </p>

        <p>
          Wir haben mit Google einen Auftragsverarbeitungsvertrag geschlossen,
          der den Schutz der Daten unserer Seitenbesucher sicherstellt und eine
          unberechtigte Weitergabe an Dritte untersagt.
        </p>

        <p>
          Weitere rechtliche Hinweise zu Google Analytics 4 finden Sie unter{" "}
          <a
            href="https://policies.google.com/privacy?hl=de&amp;gl=de"
            target="_blank"
          >
            https://policies.google.com/privacy?hl=de&amp;gl=de
          </a>{" "}
          und unter{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
          >
            https://policies.google.com/technologies/partner-sites
          </a>
        </p>
        <br />
        <br />
        <p>
          Demografische Merkmale
          <br />
          Google Analytics 4 nutzt die spezielle Funktion &bdquo;demografische
          Merkmale&#8220; und kann dar&uuml;ber Statistiken erstellen, die
          Aussagen &uuml;ber das Alter, Geschlecht und Interessen von
          Seitenbesuchern treffen. Dies geschieht durch die Analyse von Werbung
          und Informationen von Drittanbietern. Dadurch k&ouml;nnen Zielgruppen
          f&uuml;r Marketingaktivit&auml;ten identifiziert werden. Die
          gesammelten Daten k&ouml;nnen jedoch keiner bestimmten Person
          zugeordnet werden und werden nach einer Speicherung f&uuml;r die Dauer
          von zwei Monaten gel&ouml;scht.
        </p>

        <p>
          Google Signals
          <br />
          Als Erweiterung zu Google Analytics 4 kann auf dieser Website Google
          Signals verwendet werden, um ger&auml;te&uuml;bergreifende Berichte
          erstellen zu lassen. Wenn Sie personalisierte Anzeigen aktiviert haben
          und Ihre Ger&auml;te mit Ihrem Google-Konto verkn&uuml;pft haben, kann
          Google vorbehaltlich Ihrer Einwilligung zur Nutzung von Google
          Analytics gem. Art. 6 Abs. 1 lit. a DSGVO Ihr Nutzungsverhalten
          ger&auml;te&uuml;bergreifend analysieren und Datenbankmodelle, unter
          anderem zu ger&auml;te&uuml;bergreifenden Conversions, erstellen. Wir
          erhalten keine personenbezogenen Daten von Google, sondern nur
          Statistiken. Wenn Sie die ger&auml;te&uuml;bergreifende Analyse
          stoppen m&ouml;chten, k&ouml;nnen Sie die Funktion
          &quot;Personalisierte Werbung&quot; in den Einstellungen Ihres
          Google-Kontos deaktivieren. Folgen Sie dazu den Anweisungen auf dieser
          Seite:{" "}
          <a
            href="https://support.google.com/ads/answer/2662922?hl=de"
            target="_blank"
          >
            https://support.google.com
            <wbr />
            /ads
            <wbr />
            /answer
            <wbr />
            /2662922
            <wbr />
            ?hl=de
          </a>
        </p>

        <p>
          UserIDs
          <br />
          Als Erweiterung zu Google Analytics 4 kann auf dieser Website die
          Funktion &quot;UserIDs&quot; verwendet werden. Wenn Sie in die Nutzung
          von Google Analytics 4 gem. Art. 6 Abs. 1 lit. a DSGVO eingewilligt,
          ein Konto auf dieser Website eingerichtet haben und sich auf
          verschiedenen Ger&auml;ten mit diesem Konto anmelden, k&ouml;nnen Ihre
          Aktivit&auml;ten, darunter auch Conversions,
          ger&auml;te&uuml;bergreifend analysiert werden.
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen
          Datenschutzniveaus&nbsp;sicherstellt.
        </p>

        <p>
          <b>8.2</b>&nbsp;Google Tag Manager
        </p>

        <p>
          Diese Website nutzt den &bdquo;Google Tag Manager&#8220;, einen Dienst
          des folgenden Anbieters: Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland (nachfolgend: &bdquo;Google&#8220;).
        </p>

        <p>
          Der Google Tag Manager bietet eine technische Grundlage daf&uuml;r,
          diverse Webanwendungen, darunter auch Tracking- und Analysedienste, zu
          b&uuml;ndeln und &uuml;ber eine einheitliche Benutzeroberfl&auml;che
          kalibrieren, steuern und an Bedingungen kn&uuml;pfen zu k&ouml;nnen.
          Der Google Tag Manager selbst speichert keine Informationen auf
          Nutzerendger&auml;ten oder liest diese aus. Auch nimmt der Dienst
          keine eigenst&auml;ndigen Datenanalysen vor. Allerdings wird durch den
          Google Tag Manager bei Seitenaufruf Ihre IP-Adresse an Google
          &uuml;bertragen und dort gegebenenfalls gespeichert. Auch eine
          &Uuml;bermittlung an Server von Google LLC. In den USA ist
          m&ouml;glich.
        </p>

        <p>
          Diese Verarbeitung wird nur dann vollzogen, wenn Sie uns
          gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
          ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
          Einwilligungserteilung unterbleibt der Einsatz von Google Tag Manager
          w&auml;hrend Ihres Seitenbesuchs. Sie k&ouml;nnen Ihre erteilte
          Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um
          Ihren Widerruf auszu&uuml;ben, deaktivieren Sie diesen Dienst bitte in
          dem auf der Webseite bereitgestellten
          &bdquo;Cookie-Consent-Tool&#8220;.
        </p>

        <p>
          Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
          geschlossen, der den Schutz der Daten unserer Seitenbesucher
          sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen
          Datenschutzniveaus&nbsp;sicherstellt.
        </p>

        <p>
          <b>8.3</b>&nbsp;Hotjar
        </p>

        <p>
          Diese Website nutzt den Webanalysedienst des folgenden Anbieters:
          Hotjar Ltd, Level 2, St Julians Business Centre, 3, Elia Zammit
          Street, St Julians STJ 1000, Malta
        </p>

        <p>
          Mithilfe von Cookies und/oder vergleichbaren Technologien
          (Tracking-Pixel, Web-Beacons, Algorithmen zum Auslesen von
          Endger&auml;te- und Browserinformationen) erhebt und speichert der
          Dienst pseudonymisierte Besucherdaten, darunter Informationen des
          verwendeten Endger&auml;ts wie die IP-Adresse und
          Browserinformationen, um sie f&uuml;r statistische Analysen des
          Nutzungsverhaltens auf unserer Website auszuwerten und
          pseudonymisierte Nutzungsprofile zu erstellen. Unter anderem ist so
          die Auswertung von Bewegungsmustern (sog. Heatmaps) m&ouml;glich,
          welche die Dauer von Seitenbesuchen sowie Interaktionen mit
          Seiteninhalten (z. B. Texteingaben, Scrollen, Klicks und Mouse-Overs)
          aufzeigen. Die Pseudonymisierung schlie&szlig;t eine direkte
          Personenbeziehbarkeit grunds&auml;tzlich aus. Eine
          Zusammenf&uuml;hrung mit auf andere Weise erhobenen Klardaten zu Ihrer
          Person findet nicht statt.
        </p>

        <p>
          Alle oben beschriebenen Verarbeitungen, insbesondere das Auslesen oder
          Speichern von Informationen auf dem verwendeten Endger&auml;t, werden
          nur dann vollzogen, wenn Sie uns gem&auml;&szlig; Art. 6 Abs. 1 lit. a
          DSGVO dazu Ihre ausdr&uuml;ckliche Einwilligung erteilt haben. Sie
          k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r
          die Zukunft widerrufen, indem Sie diesen Dienst in dem auf der
          Webseite bereitgestellten &bdquo;Cookie-Consent-Tool&#8220;
          deaktivieren.
        </p>

        <p>
          Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
          geschlossen, der den Schutz der Daten unserer Seitenbesucher
          sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
        </p>

        <h2>9) Retargeting/ Remarketing und Conversion-Tracking</h2>

        <p>
          <b>9.1</b>&nbsp;Google Ads Remarketing
        </p>

        <p>
          Diese Webseite nutzt Retargeting-Technologie des folgenden Anbieters:
          Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5,
          Irland
        </p>

        <p>
          Zu diesem Zweck setzt Google ein Cookie im Browser Ihres
          Endger&auml;ts, welches automatisch mittels einer pseudonymen
          Cookie-ID und auf Grundlage der von Ihnen besuchten Seiten eine
          interessensbasierte Werbung erm&ouml;glicht. Eine
          dar&uuml;berhinausgehende Datenverarbeitung findet nur statt, sofern
          Sie gegen&uuml;ber Google zugestimmt haben, dass Ihr Internet-- und
          App-Browserverlauf von Google mit ihrem Google-Konto verkn&uuml;pft
          wird und Informationen aus ihrem Google-Konto zum Personalisieren von
          Anzeigen verwendet werden, die sie im Web betrachten. Sind sie in
          diesem Fall w&auml;hrend des Seitenbesuchs unserer Webseite bei Google
          eingeloggt, verwendet Google Ihre Daten zusammen mit Google
          Analytics-Daten, um Zielgruppenlisten f&uuml;r
          ger&auml;te&uuml;bergreifendes Remarketing zu erstellen und zu
          definieren. Dazu werden Ihre personenbezogenen Daten von Google
          vor&uuml;bergehend mit Google Analytics-Daten verkn&uuml;pft, um
          Zielgruppen zu bilden. Im Rahmen der Nutzung von Google Ads
          Remarketing kann es auch zu einer &Uuml;bermittlung von
          personenbezogenen Daten an die Server der Google LLC. in den USA
          kommen.
        </p>

        <p>
          Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
          Cookies f&uuml;r das Auslesen von Informationen auf dem verwendeten
          Endger&auml;t, werden nur dann vollzogen, wenn Sie uns
          gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
          ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
          Einwilligungserteilung unterbleibt der Einsatz von
          Retargeting-Technologie w&auml;hrend Ihres Seitenbesuchs.
        </p>

        <p>
          Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
          f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
          deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
          bereitgestellten &bdquo;Cookie-Consent-Tool&#8220;.
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen Datenschutzniveaus
          sicherstellt.
        </p>

        <p>
          <b>9.2</b>&nbsp;Google Ads Conversion-Tracking
        </p>

        <p>
          Diese Website nutzt das Online-Werbeprogramm &quot;Google Ads&quot;
          und im Rahmen von Google Ads das Conversion-Tracking der Google
          Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
          (&bdquo;Google&#8220;). Wir nutzen das Angebot von Google Ads, um mit
          Hilfe von Werbemitteln (sogenannten Google Adwords) auf externen
          Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Wir
          k&ouml;nnen in Bezug zu den Daten der Werbekampagnen ermitteln, wie
          erfolgreich die einzelnen Werbema&szlig;nahmen sind. Wir verfolgen
          damit das Anliegen, Ihnen Werbung anzuzeigen, die f&uuml;r Sie von
          Interesse ist, unsere Website f&uuml;r Sie interessanter zu gestalten
          und eine faire Berechnung der anfallenden Werbekosten zu erreichen.
        </p>

        <p>
          Das Cookie f&uuml;r Conversion-Tracking wird gesetzt, wenn ein Nutzer
          auf eine von Google geschaltete Ads-Anzeige klickt. Bei Cookies
          handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t
          abgelegt werden. Diese Cookies verlieren in der Regel nach 30 Tagen
          ihre G&uuml;ltigkeit und dienen nicht der pers&ouml;nlichen
          Identifizierung. Besucht der Nutzer bestimmte Seiten dieser Website
          und ist das Cookie noch nicht abgelaufen, k&ouml;nnen Google und wir
          erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser
          Seite weitergeleitet wurde. Jeder Google Ads-Kunde erh&auml;lt ein
          anderes Cookie. Cookies k&ouml;nnen somit nicht &uuml;ber die Websites
          von Google Ads-Kunden nachverfolgt werden. Die mithilfe des
          Conversion-Cookies eingeholten Informationen dienen dazu,
          Conversion-Statistiken f&uuml;r Google Ads-Kunden zu erstellen, die
          sich f&uuml;r Conversion-Tracking entschieden haben. Die Kunden
          erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt
          haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer pers&ouml;nlich identifizieren lassen. Im Rahmen der
          Nutzung von Google Ads kann es auch zu einer &Uuml;bermittlung von
          personenbezogenen Daten an die Server der Google LLC. in den USA
          kommen.
        </p>

        <p>
          Details zu den durch Google Ads Conversion Tracking angesto&szlig;enen
          Verarbeitungen und zum Umgang Googles mit Daten von Websites finden
          Sie hier:{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
          >
            https://policies.google.com/technologies/partner-sites
          </a>
        </p>

        <p>
          Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
          Cookies f&uuml;r das Auslesen von Informationen auf dem verwendeten
          Endger&auml;t, werden nur dann vollzogen, wenn Sie uns
          gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
          ausdr&uuml;ckliche Einwilligung erteilt haben. Sie k&ouml;nnen Ihre
          erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft
          widerrufen, indem Sie diesen Dienst in dem auf der Webseite
          bereitgestellten &bdquo;Cookie-Consent-Tool&#8220; deaktivieren.
        </p>

        <p>
          Sie können dem Setzen von Cookies durch Google Ads Conversion-Tracking
          darüber hinaus dauerhaft widersprechen, indem Sie das unter folgendem
          Link verfügbare Browser-Plug-in von Google herunterladen und
          installieren:
          <br />
          <a
            href="https://www.google.com/settings/ads/plugin?hl=de"
            target="_blank"
          >
            https://www.google.com/settings/ads/plugin?hl=de
          </a>
        </p>

        <p>
          Bitte beachten Sie, dass bestimmte Funktionen dieser Website
          möglicherweise nicht oder nur eingeschränkt genutzt werden können,
          wenn Sie die Verwendung von Cookies deaktiviert haben.
          <br />
          Googles Datenschutzbestimmungen sind hier einsehbar:{" "}
          <a href="https://www.google.de/policies/privacy/" target="_blank">
            https://www.google.de/policies/privacy/
          </a>
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen
          Datenschutzniveaus&nbsp;sicherstellt.
        </p>

        <h2>10) Seitenfunktionalit&auml;ten</h2>

        <p>
          <b>10.1</b>&nbsp;Google Sign-In
        </p>

        <p>
          Auf unserer Website stellen wir eine Single-Sign-On-Funktion des
          folgenden Anbieters bereit: Google Ireland Limited, Gordon House, 4
          Barrow St, Dublin, D04 E5W5, Irland
        </p>

        <p>
          Neben einer &Uuml;bermittlung von Daten an den o.g. Anbieterstandort
          k&ouml;nnen Daten auch &uuml;bermittelt werden an: Google LLC, USA
        </p>

        <p>
          Wenn Sie &uuml;ber einen Account beim Anbieter verf&uuml;gen,
          k&ouml;nnen Sie sich mit diesen Accountdaten zur Erstellung eines
          Benutzerkontos bzw. zur Registrierung auf unserer Website anmelden.
        </p>

        <p>
          Beim Besuch dieser Seite kann &uuml;ber diese Anmeldefunktion eine
          direkte Verbindung zwischen Ihrem Browser und den Servern des
          Anbieters hergestellt werden, auch wenn Sie keinen Account beim
          Anbieter besitzen oder nicht in einen solchen eingeloggt sind. Der
          Anbieter erh&auml;lt dadurch die Information, dass Sie unsere Seite
          besucht haben. Die insofern erhobenen Informationen (ggf.
          einschlie&szlig;lich Ihrer IP-Adresse) werden von Ihrem Browser direkt
          an einen Server des Anbieters &uuml;bermittelt und dort gespeichert.
          Die Informationen werden jedoch nicht dazu verwendet, Sie
          pers&ouml;nlich zu identifizieren, und werden nicht an Dritte
          weitergegeben.
        </p>

        <p>
          Diese Datenverarbeitungsvorg&auml;nge erfolgen gem&auml;&szlig; Art. 6
          Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer
          nutzerfreundlichen und interaktiven Ausgestaltung unseres
          Online-Auftritts.
        </p>

        <p>
          Bet&auml;tigen Sie den Anmeldebutton, um sich mit den Daten Ihres
          Accounts beim Anbieter auf unserer Website zu registrieren,
          &uuml;bermittelt der Anbieter ausschlie&szlig;lich auf Basis Ihrer
          ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a
          DSGVO die in Ihrem Account hinterlegten allgemeinen und
          &ouml;ffentlichen zug&auml;nglichen Informationen (Nutzer-ID, Name,
          Anschrift, Mailadresse, Alter und Geschlecht) an uns.
        </p>

        <p>
          Wir speichern und verwenden die vom Anbieter &uuml;bermittelten Daten
          zur Einrichtung eines Benutzerkontos mit den notwendigen Daten
          (Anrede, Vorname, Nachname, Adressdaten, Land, E-Mail-Adresse,
          Geburtsdatum), sofern Sie diese gegen&uuml;ber dem Anbieter
          freigegeben haben. Umgekehrt k&ouml;nnen auf Basis Ihrer Einwilligung
          Daten (z.B. Informationen &uuml;ber Ihr Surf- oder Kaufverhalten) von
          uns auf Ihren Account beim Anbieter &uuml;bertragen werden.
        </p>

        <p>
          Die erteilte Einwilligung kann jederzeit mit Wirkung f&uuml;r die
          Zukunft uns gegen&uuml;ber widerrufen werden.
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen
          Datenschutzniveaus&nbsp;sicherstellt.
        </p>

        <p>
          <b>10.2</b>&nbsp;Google Web Fonts
        </p>

        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
          sogenannte Web Fonts des folgenden Anbieters: Google Ireland Limited,
          Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
        </p>

        <p>
          Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web
          Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt
          anzuzeigen und stellt eine direkte Verbindung zu den Servern des
          Anbieters her. Hierbei werden bestimmte Browserinformationen,
          einschlie&szlig;lich Ihrer IP-Adresse, an den Anbieter
          &uuml;bermittelt.
        </p>

        <p>
          Daten k&ouml;nnen zudem &uuml;bermittelt werden an: Google LLC, USA
        </p>

        <p>
          Die Verarbeitung von personenbezogenen Daten im Zuge der
          Verbindungsaufnahme mit dem Anbieter der Schriftarten wird nur dann
          vollzogen, wenn Sie uns gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO
          dazu Ihre ausdr&uuml;ckliche Einwilligung erteilt haben. Sie
          k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r
          die Zukunft widerrufen, indem Sie diesen Dienst &uuml;ber das auf der
          Webseite bereitgestellte &bdquo;Cookie-Consent-Tool&#8220;
          deaktivieren. Falls Ihr Browser keine Web Fonts unterst&uuml;tzt, wird
          eine Standardschrift von Ihrem Computer genutzt.
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen
          Datenschutzniveaus&nbsp;sicherstellt.
        </p>

        <p>
          <b>10.3</b>&nbsp;Google reCAPTCHA
        </p>

        <p>
          Auf dieser Website verwenden wir den CAPTCHA-Dienst des folgenden
          Anbieters: Google Ireland Limited, Gordon House, 4 Barrow St, Dublin,
          D04 E5W5, Irland
        </p>

        <p>
          Daten k&ouml;nnen zudem &uuml;bermittelt werden an: Google LLC, USA.
          F&uuml;r die optische Gestaltung des Captcha-Fensters werden vom
          Anbieter &bdquo;Google Fonts&quot;, also von Google aus dem Internet
          geladene Schriftarten, verwendet. Zu einer Verarbeitung weiterer
          Informationen als den oben genannten, die bereits &uuml;ber die
          Funktionalit&auml;t von ReCaptcha an Google &uuml;bertragen werden,
          kommt es hierbei nicht.
        </p>

        <p>
          Der Dienst pr&uuml;ft, ob eine Eingabe durch eine nat&uuml;rliche
          Person oder missbr&auml;uchlich durch maschinelle und automatisierte
          Verarbeitung erfolgt, und blockiert Spam, DDoS-Attacken sowie
          &auml;hnliche automatisierte Schadzugriffe. Um sicherzustellen, dass
          eine Handlung von einem Menschen und nicht von einem automatisierten
          Bot vorgenommen wird, erhebt der Anbieter die IP-Adresse des
          verwendeten Endger&auml;ts, Erkennungsdaten des verwendeten Browser-
          und Betriebssystem-Typ sowie Datum und Dauer des Besuchs und
          &uuml;bermittelt diese zur Auswertung an Server des Anbieters.
        </p>

        <p>
          Rechtsgrundlage ist unser berechtigtes Interesse an der Feststellung
          der individuellen Eigenverantwortung im Internet und der Vermeidung
          von Missbrauch und Spam gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO.
        </p>

        <p>
          Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
          abgeschlossen, der den Schutz der Daten unserer Seitenbesucher
          sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
        </p>

        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem
          EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen,
          das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen
          Kommission die Einhaltung des europ&auml;ischen
          Datenschutzniveaus&nbsp;sicherstellt.
        </p>

        <h2>11) Tools und Sonstiges</h2>

        <p>Cookie-Consent-Tool</p>

        <p>
          Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen
          f&uuml;r einwilligungspflichtige Cookies und cookie-basierte
          Anwendungen ein sog. &bdquo;Cookie-Consent-Tool&#8220;. Das
          &bdquo;Cookie-Consent-Tool&#8220; wird Nutzern bei Seitenaufruf in
          Form einer interaktiven Benutzeroberfl&auml;che angezeigt, auf welcher
          sich per H&auml;kchensetzung Einwilligungen f&uuml;r bestimmte Cookies
          und/oder cookie-basierte Anwendungen erteilen lassen. Hierbei werden
          durch den Einsatz des Tools alle einwilligungspflichtigen
          Cookies/Dienste nur dann geladen, wenn der jeweilige Nutzer
          entsprechende Einwilligungen per H&auml;kchensetzung erteilt. So wird
          sichergestellt, dass nur im Falle einer erteilten Einwilligung
          derartige Cookies auf dem jeweiligen Endger&auml;t des Nutzers gesetzt
          werden.
        </p>

        <p>
          Das Tool setzt technisch notwendige Cookies, um Ihre
          Cookie-Pr&auml;ferenzen zu speichern. Personenbezogene Nutzerdaten
          werden hierbei grunds&auml;tzlich nicht verarbeitet.
        </p>

        <p>
          Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung oder
          Protokollierung von Cookie-Einstellungen doch zur Verarbeitung
          personenbezogener Daten (wie etwa der IP-Adresse), erfolgt diese
          gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
          berechtigten Interesses an einem rechtskonformen, nutzerspezifischen
          und nutzerfreundlichen Einwilligungsmanagement f&uuml;r Cookies und
          mithin an einer rechtskonformen Ausgestaltung unseres
          Internetauftritts.
        </p>

        <p>
          Weitere Rechtsgrundlage f&uuml;r die Verarbeitung ist ferner Art. 6
          Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der
          rechtlichen Verpflichtung, den Einsatz technisch nicht notwendiger
          Cookies von der jeweiligen Nutzereinwilligung abh&auml;ngig zu machen.
        </p>

        <p>
          Soweit erforderlich, haben wir mit dem Anbieter einen
          Auftragsverarbeitungsvertrag geschlossen, der den Schutz der Daten
          unserer Seitenbesucher sicherstellt und eine unberechtigte Weitergabe
          an Dritte untersagt.
        </p>

        <p>
          Weitere Informationen zum Betreiber und den
          Einstellungsm&ouml;glichkeiten des Cookie-Consent-Tools finden Sie
          direkt in der entsprechenden Benutzeroberfl&auml;che auf unserer
          Website.
        </p>

        <h2>12) Rechte des Betroffenen</h2>

        <p>
          <b>12.1</b>&nbsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen
          gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung
          Ihrer personenbezogenen Daten die nachstehenden Betroffenenrechte
          (Auskunfts- und Interventionsrechte), wobei f&uuml;r die jeweiligen
          Aus&uuml;bungsvoraussetzungen auf die angef&uuml;hrte Rechtsgrundlage
          verwiesen wird:
        </p>

        <ul>
          <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO;</li>
          <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO;</li>
          <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO;</li>
          <li>
            Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art.
            18 DSGVO;
          </li>
          <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO;</li>
          <li>
            Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO;
          </li>
          <li>
            Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7
            Abs. 3 DSGVO;
          </li>
          <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO.</li>
        </ul>

        <p>
          <b>12.2</b>&nbsp;WIDERSPRUCHSRECHT
        </p>

        <p>
          WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE
          PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN
          BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT,
          AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
          GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT
          EINZULEGEN.
        </p>

        <p>
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
          VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
          ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
          F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN,
          GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE
          VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
          RECHTSANSPR&Uuml;CHEN DIENT.
        </p>

        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
          GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
          ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH
          WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.
        </p>

        <p>
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
          VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
        </p>

        <h2>13) Dauer der Speicherung personenbezogener Daten</h2>

        <p>
          Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
          anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und
          &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der
          jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und
          steuerrechtliche Aufbewahrungsfristen).
        </p>

        <p>
          Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
          ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a
          DSGVO werden die betroffenen Daten so lange gespeichert, bis Sie Ihre
          Einwilligung widerrufen.
        </p>

        <p>
          Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im
          Rahmen rechtsgesch&auml;ftlicher bzw.
          rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage
          von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten
          nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig
          gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
          Vertragsanbahnung erforderlich sind und/oder unsererseits kein
          berechtigtes Interesse an der Weiterspeicherung fortbesteht.
        </p>

        <p>
          Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
          bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;ben,
          es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde
          f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
          Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen.
        </p>

        <p>
          Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
          Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
          diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach
          Art. 21 Abs. 2 DSGVO aus&uuml;ben.
        </p>

        <p>
          Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung
          &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt,
          werden gespeicherte personenbezogene Daten im &Uuml;brigen dann
          gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben
          oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
        </p>
      </div>
    </Layout>
  );
}
