import { Layout } from "./Layout";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export function AboutUs() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Über uns - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  return (
    <Layout pageTitle={"Über uns - Futedu"}>
      <head>
        <meta
          name="description"
          content="Erfahre mehr über Futedu, unsere Mission, und wie wir dir helfen können, den besten Studiengang zu finden."
        />
        <meta
          name="keywords"
          content="Über uns, Futedu, Studienberatung, Studienwahl, KI-gestützte Tests, Karriereplanung"
        />
      </head>
      <div className="bg-primary flex flex-row justify-center">
        <h1 className="text-4xl p-10">Über uns</h1>
      </div>
      <div className="text-primary px-10 p-6 max-w-4xl mx-auto bg-white rounded-lg text-justify">
        <h2 className="text-3xl font-bold pt-5">Wie entstand die Idee?</h2>
        <br />
        <div className="text-lg">
          <p>
            Wie viele von uns, wussten wir nach dem Abitur nicht genau, welchen
            Studiengang wir wählen sollten. Einige von uns haben deshalb
            professionelle Beratung in Anspruch genommen. Diese Beratung kostete
            jedoch eine vierstellige Summe und war somit für viele
            unerschwinglich. Aus dieser Erfahrung entstand die Idee, künstliche
            Intelligenz zu nutzen, um eine ähnlich hochwertige, aber deutlich
            kostengünstigere und flexiblere Lösung zu entwickeln.{" "}
          </p>
          <br />
          <p>
            Das professionelle Beratungsangebot bestand hauptsächlich aus der
            Auswertung von Testergebnissen und der Empfehlung passender
            Studiengänge. Glücklicherweise sind dies Aufgaben, die eine
            künstliche Intelligenz hervorragend bewältigen kann. Zusätzlich
            berücksichtigt unsere Lösung eine Vielzahl von Studiengängen, nicht
            nur die, die dem Berater gerade einfallen.
          </p>
          <br />
          <p>
            Mit Futedu möchten wir euch bei der vielleicht schwierigsten Frage
            eurer Schulzeit unterstützen: "Was soll ich nach dem Abitur machen?"
            Unsere KI-gestützte Beratung bietet eine umfassende und
            erschwingliche Alternative, die jedem Zugang zu einer fundierten
            Entscheidung ermöglicht.{" "}
          </p>
        </div>
        <h2 className="text-3xl font-bold pt-5">Warum Futedu?</h2>
        <br></br>
        <div className="text-lg">
          <p>
            Die moderne Bildungslandschaft ist geprägt von einer beeindruckenden
            Vielfalt an Studienmöglichkeiten. Von etablierten Fachbereichen bis
            hin zu aufstrebenden Nischen – die Auswahl ist schier grenzenlos.
            Diese Fülle mag inspirierend sein, birgt aber auch die Gefahr der
            Überforderung und Unsicherheit in sich.
          </p>
          <br />
          <p>
            Zusätzlich unterliegt die Berufswelt einem stetigen Wandel. Neue
            Technologien, veränderte Anforderungen und sich entwickelnde
            Branchen machen es schwierig, den Überblick zu behalten und
            sicherzustellen, dass die gewählte Studienrichtung zukunftsfähig
            ist.
          </p>
          <br />
          <p>
            Die wachsende Zahl der Studienabbrecher spiegelt die Komplexität
            dieser Entscheidung wider. Viele Studierende treffen ihre Wahl mit
            guten Absichten, nur um später festzustellen, dass die gewählte
            Richtung nicht ihren Erwartungen oder Fähigkeiten entspricht. Dies
            führt zu Frustration und Verunsicherung – etwas, das vermieden
            werden kann.
          </p>
          <br />
          <p>
            Unser Ziel ist es, dich in diesem Prozess zu unterstützen. Es ist
            normal, sich überfordert zu fühlen, und du bist damit nicht allein.
            Eine fundierte Entscheidung erfordert Zeit, Selbstreflexion und
            zuverlässige Informationen. Genau hier kommt professionelle
            Studienberatung ins Spiel. Mit unseren Werkzeugen helfen wir dir,
            deine Interessen, Stärken und Ziele zu erkunden, um den besten Weg
            für deine individuelle Zukunft zu finden.
          </p>
          <br />
          <p>
            Deine zukünftige Karriere verdient eine gründliche Planung. Die
            Unterstützung bei der Studienwahl ist der Schlüssel dazu. Unser Ziel
            ist es, dass du voller Zuversicht in deine akademische Reise
            startest und die Gewissheit hast, eine informierte Entscheidung zu
            treffen, die optimal zu deinen Stärken und Ambitionen passt.
          </p>

          <p>
            Du möchtest genauer wissen, was dich erwartet?{" "}
            <a className="text-indigo-600" href="http://futedu.de/beispiel">
              Schau dir unsere Beispielauswertung an!
            </a>
          </p>
        </div>
        <br></br>
        <h2 className="text-3xl font-bold">Wie funktioniert Futedu?</h2>
        <br></br>
        <div className="text-lg">
          <p>
            Die Wahl des richtigen Studiengangs kann eine echte Herausforderung
            sein. Doch mit den Fortschritten in der Künstlichen Intelligenz (KI)
            stehen dir jetzt leistungsstarke Werkzeuge zur Verfügung, um diese
            Entscheidung fundierter und zielgerichteter zu treffen. Erfahre
            hier, wie KI-basierte Persönlichkeitstests dir dabei helfen können,
            den optimalen Studiengang zu finden, und entdecke die zahlreichen
            Vorteile dieser innovativen Methode.
          </p>
          <br />
          <b>1. Präzise Analyse deiner Persönlichkeit:</b>
          <p>
            KI-gestützte Persönlichkeitstests nutzen fortschrittliche
            Algorithmen, um tiefgreifende Einblicke in deine Persönlichkeit,
            Interessen und Stärken zu gewinnen. Durch die Analyse von
            Verhaltensmustern und Präferenzen können diese Tests ein genaues
            Bild von dir zeichnen und dir helfen, deine einzigartigen Talente
            besser zu verstehen.
          </p>
          <br />
          <b>2. Individuelle Studienempfehlungen:</b>
          <p>
            Basierend auf den gesammelten Daten kann die KI personalisierte
            Studiengangempfehlungen ableiten, die zu deinem Profil passen. Diese
            Empfehlungen berücksichtigen nicht nur deine Interessen, sondern
            auch deine Fähigkeiten und Lernstile. Dadurch erhältst du eine
            gezielte Auswahl an Studiengängen, die deinen persönlichen Neigungen
            und Zielen entsprechen.
          </p>
          <br />
          <b>3. Reduzierung von Unsicherheiten:</b>
          <p>
            Die Vielzahl an Studienoptionen kann zu Verwirrung und Unsicherheit
            führen. KI-gestützte Persönlichkeitstests geben dir eine klare
            Richtung vor, indem sie dir Studiengänge vorstellen, die am besten
            zu dir passen. Dies hilft dir, Unsicherheiten zu minimieren und mit
            Zuversicht in deine Studienwahl einzusteigen.
          </p>
          <br />
          <b>4. Zeit- und Ressourceneinsparung:</b>
          <p>
            Die traditionelle Recherche nach Studiengängen erfordert viel Zeit
            und Aufwand. Die Nutzung von KI-gestützten Tests ermöglicht dir eine
            effiziente und effektive Studienwahl. Du sparst Zeit und Energie,
            indem du auf automatisierte Empfehlungen zurückgreifst, die auf
            umfangreichen Datenanalysen basieren.
          </p>
          <br />
          <b>5. Anpassung an sich ändernde Trends:</b>
          <p>
            Die Bildungslandschaft verändert sich kontinuierlich. KI-gestützte
            Systeme können diese Trends schnell erfassen und in ihre
            Empfehlungen integrieren. Dadurch erhältst du stets aktuelle und
            relevante Informationen über aufstrebende Studiengänge und
            Berufsfelder.
          </p>
          <br />
          <p>
            Insgesamt bieten KI-gestützte Persönlichkeitstests eine moderne und
            intelligente Lösung für die Herausforderungen bei der Studienwahl.
            Durch ihre präzise Analyse und personalisierten Empfehlungen helfen
            sie dir, den besten Studiengang für deine individuellen Bedürfnisse
            zu finden. Nutze die Vorteile dieser innovativen Technologie, um mit
            Selbstvertrauen in eine erfolgreiche akademische Zukunft zu starten.
          </p>
        </div>
        <div className="text-center pt-7">
          <div className="btn btn-accent text-primary rounded-3xl shadow-lg font-bold px-16 mx-auto">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              Zurück zur Startseite
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
