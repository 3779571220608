import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase/firebaseConfig"; // Assume you've exported firebaseApp from your Firebase config file
import { Layout } from "./Layout";

const sequences = [
  { id: "a)", sequence: "1, 3, 6, 10, 15, 21, ..." },
  { id: "b)", sequence: "1, 1, 2, 3, 5, 8, ..." },
  { id: "c)", sequence: "1, 4, 9, 16, 25, ..." },
  { id: "d)", sequence: "3, 4, 2, 5, 1, 6, ..." },
  { id: "e)", sequence: "4, 8, 5, 10, 7, 14, ..." },
  { id: "f)", sequence: "1, 3, 7, 15, 31, 63, ..." },
  { id: "g)", sequence: "48, 36, 26, 18, 12, 8, ..." },
  { id: "h)", sequence: "2, 3, 5, 7, 11, 13, ..." },
  { id: "i)", sequence: "6, 11, 4, 9, 2, 7, ..." },
  { id: "j)", sequence: "13, 38, 54, 90, 85, 81, ..." },
  { id: "k)", sequence: "6, 9, 20, 39, 66, ..." },
];

export const Zahlenreihen = () => {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Zahlenreihentest - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const navigate = useNavigate();
  const [answers, setAnswers] = useState(
    sequences.reduce((acc, { id }) => ({ ...acc, [id]: "" }), {})
  );
  const [uid, setUid] = useState(null);
  const [timerStarted, setTimerStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 Minuten in Sekunden

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        navigate("/registrieren");
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [navigate]);

  useEffect(() => {
    let timer;
    if (timerStarted) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    }

    if (timeLeft === 0) {
      handleSubmit();
    }

    return () => clearInterval(timer);
  }, [timerStarted, timeLeft]);

  const handleChange = (id, value) => {
    setAnswers((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    const solution = [28, 13, 36, 0, 11, 127, 6, 17, 0, 75, 101]; // The correct answers
    let score = 0; // Initialize score
    console.log(answers);
    // Calculate score by comparing each answer with the solution
    sequences.forEach(({ id }, index) => {
      if (parseInt(answers[id], 10) === solution[index]) {
        score += 1; // Increment score for each correct answer
      }
    });

    try {
      await setDoc(
        doc(db, "users", uid),
        { Zahlenreihen: (score - 1) / solution.length },
        { merge: true }
      );
      navigate("/overview/");
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  const handleStartTimer = () => {
    setTimerStarted(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Layout pageTitle={"Zahlenreihen"}>
      <section className="py-10 px-5">
        {!timerStarted && (
          <div className="max-w-xl mx-auto text-center my-10">
            <p className="bg-white rounded-lg text-primary p-10 shadow-lg mb-10">
              Dir werden gleich 11 Zahlenreihen gezeigt, welche du
              vervollständigen musst. Du hast dafür 10 Minuten Zeit. Wenn der
              Timer abgelaufen ist, wirst du automatisch zur Testübersicht
              zurückgeleitet.
              <span className="font-semibold text-left">
                <br /> Beispiel: 1, 2, 3, 4, 5, ...
                <br /> Antwort: 6
              </span>
            </p>
            <button
              className="btn btn-accent rounded-3xl shadow-lg font-bold mt-5 px-8 py-3"
              onClick={handleStartTimer}
            >
              Starten
            </button>
          </div>
        )}

        {timerStarted && (
          <>
            <div className="sticky top-[0px] bg-neutral text-primary text-xl max-w-xl mx-auto py-3 md:py-6 font-bold text-center z-10">
              <p>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</p>
            </div>

            <form onSubmit={handleSubmit}>
              {sequences.map(({ id, sequence }) => (
                <div
                  key={id}
                  className="bg-primary flex flex-col justify-center items-center max-w-xl mx-auto gap-5 my-5 rounded-xl py-7"
                >
                  <p className="tracking-widest" id={`sequence-${id}`}>
                    <strong>{sequence}</strong>
                  </p>
                  <input
                    type="tel"
                    id={`answer-${id}`}
                    className="w-16 py-1 px-2 text-primary text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={answers[id]}
                    onChange={(e) => handleChange(id, e.target.value)}
                  />
                </div>
              ))}
              <div align="center">
                <button
                  type="submit"
                  className="btn btn-accent rounded-3xl shadow-lg font-bold px-16"
                  id="zahlenreihenButton"
                >
                  Fertig
                </button>
              </div>
            </form>
          </>
        )}
      </section>
    </Layout>
  );
};
