import React, { useContext, useRef, useState } from "react";
import { Radar } from "react-chartjs-2";
import { ChartContext } from "./ChartContext";

const chartStyle = { width: "700px", height: "700px", display: "invisible" };

const imageCapturePlugin = {
  id: "imageCapturePlugin",
  afterRender: (chart) => {
    console.log("Rendering chart to image...");
    const imgData = chart.toBase64Image();
    const { category, addImage } = chart.options.contextData;
    addImage(imgData, category);
    console.log("Image captured and added:", imgData);
  },
};

export const RadarChartPDF = ({ category, data, color }) => {
  const chartRef = useRef();
  const { addOrReplaceImage } = useContext(ChartContext);
  const [counter, setCounter] = useState(0);

  const backgroundColor = `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)`;
  const borderColor = `rgb(${color.r}, ${color.g}, ${color.b})`;

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: category,
        data: Object.values(data),
        fill: true,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        pointBackgroundColor: borderColor,
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: borderColor,
      },
    ],
  };

  const chartOptions = {
    scales: {
      r: {
        angleLines: {
          display: true,
          lineWidth: 3,
        },
        border: {
          display: true,
        },
        suggestedMin: 0, // Minimum scale value
        suggestedMax: 1, // Maximum scale value
        ticks: {
          stepSize: 0.2, // Steps of 0.2
          backdropColor: "transparent", // Optional: to make tick labels background transparent
          font: {
            size: 14, // Adjust this value as needed
          },
          // Additional tick configuration can go here
        },
        pointLabels: {
          font: {
            size: 16, // Adjust this value to change the point label font size
            weight: "bold",
          },
          // Additional point label configuration can go here
        },
      },
    },
    plugins: [
      {
        legend: {
          display: false, // Set to true if you want to display the legend
          labels: {
            font: {
              size: 14, // Example size, adjust as needed
            },
          },
        },
      },
    ],
    animation: {
      onComplete: function (chart) {
        //if (isRendered) return; // Skip if already rendered
        if (counter == 2) return
        console.log("Line Chart Rendered Completely!");
        const imgData = this.toBase64Image();
        addOrReplaceImage(imgData, category);
        setCounter(counter+1); // Mark as rendered
        //set display of element to none 
        if ((counter + 1) == 2) chartRef.current.style.display = "none"; 
      },
    },
    // Include other configuration options as necessary
  };

  return (
    <div ref={chartRef} style={chartStyle} id={"test"}>
      <Radar data={chartData} options={chartOptions} />
    </div>
  );
};
