// ChartContext.js
import React, { createContext, useState } from 'react';

export const ChartContext = createContext();

export const ChartProvider = ({ children }) => {
    const [images, setImages] = useState({}); // Initializes storage as an object

    const addOrReplaceImage = (imgData, category) => {
        setImages(prev => {
            const updated = { ...prev }; // Copy existing storage

            updated[category] = { image: imgData, category }; // Replace or add image to the category key
            return updated;
        });
    };

    return (
        <ChartContext.Provider value={{ images, addOrReplaceImage }}>
            {children}
        </ChartContext.Provider>
    );
};
