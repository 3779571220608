import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase/firebaseConfig"; // Ensure this imports your configured Firestore and Auth instances
import { Layout } from "./Layout";
import { useNavigate } from "react-router-dom";

async function triggerSpracheCloudFunction(prompt, userID) {
  try {
    const promptsCollectionRef = collection(db, `users/${userID}/messages`);
    const docRef = await addDoc(promptsCollectionRef, {
      prompt: prompt,
      status: { state: null },
      type: "Sprache",
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef; // Return the DocumentReference for further use if needed
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // Rethrow the error for handling by the caller
  }
}

export async function getSpracheResult(userID) {
  const messagesRef = collection(db, `users/${userID}/messages`);
  const q = query(
    messagesRef,
    where("response", "!=", null),
    where("type", "==", "Sprache")
  );

  try {
    const querySnapshot = await getDocs(q);
    const responses = [];
    querySnapshot.forEach((doc) => {
      responses.push(doc.data().response);
    });

    if (responses.length > 0) {
      console.log("Found messages with responses:", parseFloat(responses[0]));
      const userDocRef = doc(db, "users", userID);
      await updateDoc(userDocRef, {
        Sprache: parseFloat(responses[0]),
      });
      return responses; // Return an array of response values
    } else {
      console.log("No messages with responses found.");
      return null; // Return null to indicate no responses were found
    }
  } catch (error) {
    console.error("Error fetching messages with responses:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export const Sprachtest = () => {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Sprachtest - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [essayText, setEssayText] = useState("");
  const [timer, setTimer] = useState(30 * 60); // 30 minutes in seconds
  const [taskStarted, setTaskStarted] = useState(false); // Track if the task has started
  const [userID, setUserID] = useState(null); // To store the current user's ID
  const navigate = useNavigate();
  const essayTextRef = useRef("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
      } else {
        return navigate("/registrieren");
      }
    });

    let countdown;
    if (taskStarted) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(countdown);
            handleSubmit({ preventDefault: () => {} }); // Simulate event object
            console.log("Timer expired, submitting essay");
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      unsubscribe();
      clearInterval(countdown);
    };
  }, [taskStarted]);

  useEffect(() => {
    essayTextRef.current = essayText;
  }, [essayText]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userID) {
      console.error("No userID found, user must be logged in to submit.");
      return;
    }
    // Submit the essay
    await triggerSpracheCloudFunction(
      `Ich habe einen Aufsatz geschrieben und hatte 30 min Zeit. Erwartet wurden 300 Wörter. Das war die Aufgabe: 'Stellen Sie sich vor, Sie hätten die Chance, eine Botschaft zu verfassen, die von der ganzen Welt gelesen wird. Diese Botschaft sollte eine positive Veränderung inspirieren oder zum Nachdenken anregen. Was wäre Ihre Botschaft?' Bitte bewerte mein Sprachgefühl und Sprachfähigkeiten. Antworte mit einer einzelnen Punktzahl zwischen 0 und 1. zB. '0.8'. Hier ist mein Aufsatz: ` +
        essayTextRef.current,
      userID
    );
    
    console.log("Essay submitted:", essayTextRef.current);
    navigate("/overview");
  };

  const handleTextChange = (event) => {
    setEssayText(event.target.value);
  };

  const handleStartTask = () => {
    setTaskStarted(true);
  };

  const getWordCount = (text) => {
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt

  return (
    <Layout>
      <div className="container mx-auto px-4">
        {!taskStarted ? (
          <>
            <div className="text-center flex flex-col py-44">
              <h2 className="text-xl text-gray-800 font-semibold my-4 max-w-lg mx-auto bg-white p-5 rounded-xl">
                Du wirst gleich gebeten einen Aufsatz zu schreiben. Das Thema
                erfährst du, sobald du auf den Knopf drückst. Du hast dafür 30
                Minuten Zeit. Es werden ca. 300 Wörter erwartet.
              </h2>
              <button
                onClick={handleStartTask}
                className="btn btn-accent rounded-3xl shadow-lg font-bold px-16 mx-auto"
              >
                Bereit
              </button>
            </div>
          </>
        ) : (
          <div className="py-10">
            <div className="py-5">
              <h2 className="text-xl text-gray-800 font-semibold my-5 p-5 bg-white rounded-xl text-center md:text-left ">
                Stell dir vor, du hättest die Chance, eine Botschaft zu
                verfassen, die von der ganzen Welt gelesen wird. Diese Botschaft
                sollte eine positive Veränderung inspirieren oder zum Nachdenken
                anregen. Was wäre deine Botschaft?
              </h2>
            </div>
            <div className="mb-4 font-medium text-primary text-center">
              Verbleibende Zeit:{" "}
              <span className="text-red-500">{formatTime()}</span>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <textarea
                value={essayText}
                onChange={handleTextChange}
                placeholder="Schreibe deinen Aufsatz hier..."
                rows="10"
                className="w-full p-4 text-gray-700 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
              <div className="mt-2 text-right text-sm text-gray-600">
                Wörter: {getWordCount(essayText)}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-accent rounded-3xl shadow-lg font-bold px-16"
                >
                  Fertig
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Layout>
  );
};
