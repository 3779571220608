import React, { useState, useEffect } from "react";
import { Layout } from "./Layout";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export function Prioritäten() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Prioritäten - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const navigate = useNavigate();
  let uid;
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      uid = user.uid;
    } else {
      //not signed in
      return navigate("/registrieren");
    }
  });

  const [selection, setSelection] = useState(["", "", ""]);

  const handleRankingChange = (newRanking) => {
    setSelection(newRanking);
    console.log(selection);
  };

  const handleButtonClick = () => {
    setDoc(
      doc(db, "users", uid),
      {
        prioritäten0: "done",
        prioritäten1: selection[0] ? selection[0] : "",
        prioritäten2: selection[1] ? selection[1] : "",
        prioritäten3: selection[2] ? selection[2] : "",
      },
      { merge: true }
    )
      .then(() => {
        console.log("User added to Firestore vorstellung");
        return navigate("../Overview");
      })
      .catch((error) => {
        console.log("Error adding user to Firestore:", error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt

  return (
    <Layout pageTitle="Prioritäten">
      <div className="max-w-4xl mx-auto flex flex-col justify-center items-center px-2 py-8 ">
        <div className="bg-white rounded-xl text-primary shadow-sm py-5 px-2 text-center max-w-lg">
          Wähle aus den folgenden Optionen diejenigen aus, die in deiner
          Karriere eine Rolle spielen sollen.
          <br />
          Klicke dazu einfach auf die Optionen, welche für dich wichtig sind.
          Durch erneutes Anklicken kannst du die Auswahl wieder aufheben.
          <br />
          Du kannst maximal 3 auswählen. Wenn keine der Auswahlmöglichkeiten für
          dich relevant ist, klicke ohne Auswahl auf "Fertig".
        </div>
        <ButtonGroup onRankingChange={handleRankingChange} />

        <button
          onClick={handleButtonClick}
          className="btn btn-accent rounded-3xl shadow-lg font-bold px-16 mx-auto"
        >
          Fertig
        </button>
      </div>
    </Layout>
  );
}

const ButtonGroup = ({ onRankingChange }) => {
  const options = [
    "international",
    "draußen arbeiten",
    "Kinder",
    "guter Zweck",
    "Musik",
    "viel Verantwortung",
    "gesellschaftliche Anerkennung",
    "Sport",
    "Reisen",
    "Unternehmertum",
    "Tiere",
    "Kunst",
    "Technik",
    "Wissenschaft",
    "Kundenkontakt",
    "Kreativität",
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    onRankingChange(selectedOptions); // Rangliste an Hauptkomponente übergeben
  }, [selectedOptions, onRankingChange]); // Include 'onRankingChange' in the dependency array

  const handleSelect = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((o) => o !== option)
      );
    } else if (selectedOptions.length < 3) {
      setSelectedOptions((prevOptions) => [...prevOptions, option]);
    } else {
      console.log("Maximal 3 Optionen auswählbar");
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-center gap-1 bg-primary rounded-xl px-5 py-10 my-5 w-full max-w-lg font-semibold">
      {options.map((option, index) => (
        <button
          key={index}
          className={`rounded-lg text-primary py-1 px-3 hover:brightness-90 active:scale-95 ${
            selectedOptions.includes(option) ? "bg-accent" : "bg-white"
          }`}
          onClick={() => handleSelect(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};
