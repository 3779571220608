import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { blogPosts } from "./posts.js"; // Adjust the path as necessary
import { Layout } from "../Layout";
import analyticsEventLog from "../../helperFunctions/AnalyticsEvent.js";
export function createUrlFriendlyString(str) {
  return str
    .toLowerCase()
    .replace(/ä/g, "ae") // Replace ä with ae
    .replace(/ö/g, "oe") // Replace ö with oe
    .replace(/ü/g, "ue") // Replace ü with ue
    .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphen
    .replace(/^-|-$/g, ""); // Remove leading and trailing hyphens
}

export function BlogÜbersicht() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Blog - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  return (
    <Layout>
      <div className="p-5 md:p-10 py-16 items-center flex flex-col max-w-2xl mx-auto">
        <div className="text-center w-full">
          <h1 className="text-primary text-3xl pb-5">Futedu Blog</h1>
          {blogPosts.map((post) => (
            <Link
              className="text-indigo-500 hover:brightness-90 mt-3 block"
              to={`/${createUrlFriendlyString(post.title)}`}
            >
              <div
                className="bg-white text-left p-5 rounded-xl my-5 mx-auto flex flex-col md:flex-row items-start"
                key={post.id}
              >
                {post.image && (
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full md:w-32 h-32 object-cover rounded-md mr-0 md:mr-5 mb-5 md:mb-0"
                  />
                )}
                <div className="w-full">
                  <p className="text-xl text-primary font-bold">{post.title}</p>
                  <p className="text-primary">{post.summary}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="text-center pt-7">
          <div className="btn btn-accent text-primary rounded-3xl shadow-lg font-bold px-16 mx-auto">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
                analyticsEventLog("toHomefromBlogOverview_button");
              }}
            >
              Zurück zur Startseite
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
