import { Header, Footer, Layout } from "./Layout";
import React, { useState, useEffect } from "react";
import { CheckoutButton } from "./Auswertung/CheckOutButton";
import { NavLink } from "react-router-dom";
import ComparisonTable from "../components/ComparisonTabel";
import Mockup from "../assets/iPhone 15 Pro.png";
import BspAuswertung from "../assets/BspAuswertung.png";
import Paul from "../assets/IMG_6490.PNG";
import Lina from "../assets/Lina_Pb.png";
import Valli from "../assets/Valli_Pb.png";
import logoImage from "../assets/Futedu_logo.png";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase Authentication
import Lottie from "react-lottie";
import presentLottie from "../assets/lotties/present.json";
import DynamicRadarChart from "../components/randomRadarCharts";
import analyticsEventLog from "../helperFunctions/AnalyticsEvent";

export function Home() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title =
      "Was soll ich studieren? Finde das passende Studium | Futedu";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  const [paymentStatus, setPaymentStatus] = useState("");
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userID) {
      const db = getFirestore();
      const paymentsQuery = query(
        collection(db, "users", userID, "payments"),
        where("status", "==", "succeeded")
      );

      const checkPaymentStatus = async () => {
        try {
          const querySnapshot = await getDocs(paymentsQuery);
          if (querySnapshot.empty) {
            setPaymentStatus("notSucceeded");
          } else {
            setPaymentStatus("succeeded");
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
          setPaymentStatus("error");
        }
      };
      checkPaymentStatus();
    }
  }, [userID]);

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: presentLottie,
  };
  const chartStyle = { width: "600px", height: "600px" };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.slice(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const handlePresentClick = (sectionId) => (event) => {
    analyticsEventLog("heroRabattHinweis_button");
    event.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    sectionElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <head>
        <meta
          name="description"
          content="Finde heraus, welches Studium zu dir passt mit unserem KI-gestützten Orientierungstest. Entdecke deinen Traumstudiengang jetzt!"
        />
        <meta
          name="keywords"
          content="was soll ich studieren, studium finden, welches studium passt zu mir, studiengang finden, orientierungstest studium"
        />
      </head>
      <Header />
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-start justify-center gap-16 lg:gap-36 px-8 py-12 lg:py-24">
        <div className="relative text-center lg:text-left lg:w-3/5">
          <style jsx>{`
            @keyframes shine {
              0% {
                background-position: -1000px;
              }
              30% {
                background-position: 1000px;
              }
              100% {
                background-position: 1000px;
              }
            }
            .shine {
              display: inline;
              background: linear-gradient(
                90deg,
                transparent,
                rgba(255, 255, 255, 0.8),
                transparent
              );
              background-size: 2000px 100%;
              animation: shine 4s linear infinite;
              -webkit-background-clip: text;
              -webkit-text-fill-color: rgba(255, 255, 255, 0);
            }
          `}</style>

          <div className="backdrop-filter-[12px] inline-flex h-7 bg-white/40 mb-3 items-center justify-between rounded-full border border-primary px-3 text-xs transition-all ease-in-out hover:cursor-pointer hover:bg- group gap-1">
            <p className="mx-auto max-w-md text-gray-400 flex items-center">
              <a href="/#preise" onClick={handlePresentClick("preise")}>
                🎁&nbsp;&nbsp;
                <span className="shine font-bold">
                  Code "F50" für 50% auf das Launch-Angebot
                </span>
              </a>{" "}
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-1 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5"
              >
                <path
                  d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </p>
          </div>
          <h1 className="text-4xl lg:text-6xl p-0 m-0">
            Welches Studium passt zu mir?
          </h1>
          <p className="py-7 text-lg">
            Mit unserem KI-gestützten Orientierungstest finden wir heraus, was
            wirklich zu dir passt und helfen dir, dein Traumstudium auf Anhieb
            zu finden. Starte kostenlos und unverbindlich mit unseren Tests.
          </p>
          <div className="md:hidden flex items-center justify-center lg:justify-start gap-2 font-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-[18px] h-[18px] text-green-500"
            >
              <path
                fillRule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clipRule="evenodd"
              ></path>
            </svg>
            30 Tage Geld-zurück-Garantie
          </div>
          <ul className="hidden md:block text-base-content-secondary leading-relaxed space-y-1 mt-4">
            <li className="flex items-center justify-center lg:justify-start gap-2 font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[18px] h-[18px] text-green-500"
              >
                <path
                  fillRule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Wissenschaftlich fundiert
            </li>
            <li className="flex items-center justify-center lg:justify-start gap-2 font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[18px] h-[18px] text-green-500"
              >
                <path
                  fillRule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Ausführliche Analyse und Auswertung
            </li>
            <li className="flex items-center justify-center lg:justify-start gap-2 font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[18px] h-[18px] text-green-500"
              >
                <path
                  fillRule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clipRule="evenodd"
                ></path>
              </svg>
              30 Tage Geld-zurück-Garantie
            </li>
          </ul>
          <NavLink
            to="/overview"
            onClick={() => analyticsEventLog("heroLegLos_button")}
          >
            <button className="btn btn-accent text-primary px-8 font-bold group shadow-lg mt-8">
              <div>Kostenfrei loslegen</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
              >
                <path
                  fillRule="evenodd"
                  d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </NavLink>
        </div>
        <div className="relative w-full lg:mt-8 max-w-lg flex flex-col gap-16 md:gap-24 items-center mx-auto md:w-2/5">
          <NavLink
            to="/overview"
            className="relative rounded-lg bg-base-100 max-md:py-1.5 p-2 pl-14 flex gap-10 items-center w-full animate-popup border-2 md:border-[3px] border-red-300 z-30"
            onClick={() => analyticsEventLog("heroToOverview_graphic")}
          >
            <div className="absolute left-0 -translate-x-1/3">
              <div className="w-12 h-12 text-xl lg:text-3xl md:w-16 md:h-16 inline-flex items-center justify-center rounded-full bg-red-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 lg:w-8 lg:h-8 text-red-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                  />
                </svg>
              </div>
            </div>
            <div>
              <p className="font-semibold text-neutral-100 text-base md:text-xl">
                Was soll ich studieren?
              </p>
            </div>
          </NavLink>
          <div className="relative w-full z-20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 800 800"
              className="h-24 md:h-36 absolute top-3.5 -translate-y-full left-1/2 -translate-x-1/2 animate-opacity-short"
            >
              <g
                strokeWidth="20"
                className="stroke-red-300"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="62 62"
                transform="rotate(45, 400, 400)"
              >
                <line
                  x1="167.5"
                  y1="167.5"
                  x2="632.5"
                  y2="632.5"
                  markerEnd="url(#SvgjsMarker1641)"
                ></line>
              </g>
            </svg>
            <div className="relative rounded-lg bg-base-100 max-md:py-1.5 p-2 pl-14 flex gap-10 items-center w-full animate-popup-short border-2 md:border-[3px] border-sky-900 z-10">
              <div className="absolute left-0 -translate-x-1/3">
                <img
                  src={logoImage}
                  alt="Futedu Logo"
                  className="w-14 lg:w-20"
                />
              </div>
              <div>
                <p className="font-semibold text-base md:text-xl">
                  Mit Futedu Persönlichkeit analysieren
                </p>
              </div>
            </div>
          </div>
          <div className="relative w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 800 800"
              className="h-24 md:h-36 absolute top-3.5 -translate-y-full left-1/2 -translate-x-1/2 animate-opacity-long"
            >
              <g
                strokeWidth="20"
                className="stroke-sky-900"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="62 62"
                transform="rotate(45, 400, 400)"
              >
                <line
                  x1="167.5"
                  y1="167.5"
                  x2="632.5"
                  y2="632.5"
                  markerEnd="url(#SvgjsMarker1641)"
                ></line>
              </g>
            </svg>
            <div className="relative rounded-lg bg-base-100 max-md:py-1.5 p-2 pl-14 flex gap-10 items-center w-full animate-popup-long border-2 border-green-200 md:border-[3px] z-10">
              <div className="absolute left-0 -translate-x-1/3">
                <div className="w-12 h-12 text-2xl lg:text-3xl md:w-16 md:h-16 inline-flex items-center justify-center rounded-full bg-green-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 lg:w-8 lg:h-8 text-green-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <p className="font-semibold text-base md:text-xl">
                  Traumstudium herausfinden!
                </p>
              </div>
            </div>
            <div className="mt-8 md:mt-12 text-center"></div>
          </div>
        </div>
      </div>

      <section className="relative">
        <div className="hero my-50 bg-neutral text-slate-900">
          <div className="hero-content text-center">
            <div className="mt-10 flex flex-col justify-center items-center">
              <h1 className="text-3xl lg:text-5xl font-extrabold mb-5">
                <div className="relative">
                  <span className="text-red-700">11.208€ </span>
                  <span className="absolute top-0 right-0 font-light text-sm text-gray-400">
                    *
                  </span>
                  kostet ein Jahr studieren{" "}
                </div>
              </h1>
              <p className="max-w-3xl">
                Daher solltest du dir sicher sein, was du studieren möchtest, um
                diesen Betrag und die Zeit nicht zu verschwenden. Trotzdem
                brechen 4 von 10 Studierende ihr Studium ab. Das ist zusätzlich
                auch eine frustrierende Erfahrung und mit viel Aufwand
                verbunden, wie bspw. einem Umzug.
                <br />
                Daher lohnt es sich, in die Entscheidungsfindung zu investieren.
              </p>
              <h2 className="py-6 font-bold text-lg">
                Triff direkt die richtige Wahl
              </h2>
              <div className="flex justify-center items-center">
                <Carousel autoSlide={true} autoSlideInterval={7000}>
                  <FeedbackCard
                    zitat="Ich war völlig planlos für mein Studium. Nach dem Orientierungstest von Futedu, wusste ich, was zu mir passt und bin jetzt mehr als zufrieden!"
                    name="Valentin"
                    beschreibung="Ingenieurswissenschaften"
                    pb={Valli}
                  />
                  <FeedbackCard
                    zitat="Obwohl ich bereits eine Vorstellung hatte in welche Richtung es gehen sollte, hat Futedu mir die nötige Gewissheit gegeben, dass mein gewählter Studiengang auch wirklich zu mir passt."
                    name="Lina"
                    beschreibung="Jura"
                    pb={Lina}
                  />
                  <FeedbackCard
                    zitat="Weil ich keine genaue Idee hatte, habe ich damals Bwl angefangen und konnte erst über Umwege zur Informatik finden. Futedu hätte mir damals sehr geholfen."
                    name="Paul"
                    beschreibung="Softwareentwickler"
                    pb={Paul}
                  />
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-1 text-gray-400 text-xs">
          *Schätzung: Bafög für 12 Monate
        </div>
      </section>
      <section className="bg-gray-800/30 py-16 lg:py-24">
        <div className="max-w-screen-2xl mx-auto flex-col justify-center text-center">
          <h1 className="text-4xl lg:text-5xl mb-0 mt-3 sm:mb-8 lg:mb-16">
            Warum Futedu?
          </h1>
          <ComparisonTable />
        </div>
      </section>
      <section className="pt-16 lg:pt-24 pb-16 lg:pb-0 bg-neutral text-primary">
        <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-start justify-center gap-5 lg:gap-24 px-8 lg:px-5">
          <div className="relative text-center lg:text-left">
            <h1 className="text-4xl lg:text-6xl p-0 m-0 ">
              Du willst wissen, wie das Ergebnis aussieht?
            </h1>
            <p className="py-7 text-lg">
              In unserer Muster-Auswertung erhältst du einen detaillierten
              Einblick in unsere umfassende Testauswertung und den Aufbau der
              PDF-Datei.{" "}
              <span className="hidden md:block">
                Umfang und Struktur werden bei deinem persönlichen Ergebnis auf
                dem gleichen Niveau sein.
              </span>{" "}
              So weißt du genau, was dich erwartet.
            </p>
            <div className="hidden lg:block">
              <NavLink to="/beispiel" onClick={() => window.scrollTo(0, 0)}>
                <button
                  className="btn btn-accent text-primary px-8 font-bold group shadow-lg mt-8"
                  onClick={() => analyticsEventLog("homeBspAuswertung_button")}
                >
                  <div>Zur Beispielauswertung</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </NavLink>
            </div>
          </div>
          <div className="relative w-full flex flex-col items-center mx-auto overflow-hidden rounded-t-xl">
            <div>
              <NavLink to="/beispiel" onClick={() => window.scrollTo(0, 0)}>
                <img
                  src={BspAuswertung}
                  alt="Beispiel Auswertung"
                  className="w-full h-auto"
                  onClick={() => analyticsEventLog("homeBspAuswertung_image")}
                />

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "80%",
                    background:
                      "linear-gradient(0deg, #eef0f2 18%, rgba(238, 240, 242, 0.5) 50%, transparent 100%)",
                  }}
                />
              </NavLink>
            </div>
            <div className="lg:hidden z-10">
              <NavLink to="/beispiel" onClick={() => window.scrollTo(0, 0)}>
                <button
                  className="btn btn-accent text-primary px-8 font-bold group shadow-lg mt-3"
                  onClick={() => analyticsEventLog("homeBspAuswertung_button")}
                >
                  <div>Zur Beispielauswertung</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 lg:py-24">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-start justify-center gap-5 px-8">
          <div className="relative w-full max-w-lg flex flex-col gap-16 md:gap-24 items-center mx-auto">
            <img
              src={Mockup}
              alt="iPhone 15 Mockup"
              className="w-full h-auto"
            />
          </div>
          <div className="relative text-center lg:text-left lg:mt-4">
            <h1 className="text-4xl lg:text-6xl p-0 m-0 lg:mt-8">
              Eine PDF-Datei für deine Zukunft.
            </h1>
            <p className="py-7 text-lg">
              Deine Auswertung erhältst du in einer mehrseitigen PDF-Datei, in
              welcher wir deine Ergebnisse erläutern. Ein zusammenfassendes
              Fazit und die Empfehlung von 3 Studiengängen runden sie ab und
              stellen die Weichen für deine erfolgreiche Zukunft.
            </p>
            <div className="flex items-center justify-center lg:justify-start gap-2 font-bold mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-stopwatch w-[18px] h-[18px] text-green-500"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
                <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3" />
              </svg>
              In wenigen Minuten erstellt
            </div>
            <div className="flex items-center justify-center lg:justify-start gap-2 font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[18px] h-[18px] text-green-500"
              >
                <path
                  fillRule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clipRule="evenodd"
                ></path>
              </svg>
              30 Tage Geld-zurück-Garantie
            </div>
            <NavLink to="/overview#top" onClick={() => window.scrollTo(0, 0)}>
              <button
                className="btn btn-accent text-primary px-8 font-bold group shadow-lg mt-8"
                onClick={() => analyticsEventLog("homelegLos2_button")}
              >
                <div>Leg los</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
      </section>

      {/* <section className="bg-gray-800/30 py-16 lg:py-24">
        <div className="max-w-screen-2xl mx-auto flex-col justify-center text-center">
          <h1 className="text-4xl lg:text-5xl mb-0 mt-3 sm:mb-8 lg:mb-16">
            Warum Futedu?
          </h1>
          <ComparisonTable />
        </div>
      </section> */}
      <section
        id="geschenk"
        className="pb-20 md:pb-12 md:pt-8 text-primary bg-neutral"
      >
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-start justify-center px-8">
          <div className="relative md:w-4/12 h-full">
            <Lottie
              style={{ width: "100%", height: "100%" }}
              options={defaultLottieOptions}
            />
          </div>
          <div className="relative md:w-8/12 text-center lg:text-left lg:mt-12">
            <h1 className="text-4xl lg:text-5xl">
              Das perfekte Geschenk zum Abitur
            </h1>
            <p className="py-7 text-lg">
              Verschenke eine Karriere Starthilfe an diejenigen, die noch nicht
              genau wissen, wo ihre persönlichen Stärken und Schwächen liegen
              und welche Studiengänge dazu passen könnten.
            </p>
            <div className="flex flex-col lg:flex-row gap-3 lg:gap-7 justify-center items-center lg:justify-start">
              <div className="w-full md:w-1/3">
                <CheckoutButton
                  label="Verschenken"
                  priceId="price_1PqW9BLCsjAecyK8iwZ5gc88"
                  isPresent={true}
                  className="btn btn-primary text-white btn-block group hover:brightness-150 font-bold"
                />
              </div>
              <button
                className="text-slate-700 flex gap-2 hover:bg-slate-300 rounded-lg p-3 hover:text-primary"
                onClick={() => {
                  document.getElementById("my_modal_1").showModal();
                  analyticsEventLog("homeMehrInfoGutschein_button");
                }}
              >
                Mehr erfahren
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
              <dialog id="my_modal_1" className="modal">
                <div className="modal-box text-white">
                  <h3 className="font-bold text-xl text-center">
                    Infos zum Gutschein Kauf
                  </h3>
                  <ul className="list-disc max-w-md mx-auto space-y-4 py-5">
                    <li className="flex items-start gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-6 flex-shrink-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>
                        Klicke auf den "Verschenken" Knopf und schließe den
                        Kaufprozess wie gewohnt ab
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-6 flex-shrink-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>
                        Nach erfolgreicher Transaktion erhälst du kurze Zeit
                        später den Gutscheincode per E-Mail (Überprüfe auch
                        deinen Spam-Ordner)
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-6 flex-shrink-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>
                        Achte also darauf, bei der Anmeldung eine gültige E-Mail
                        Adresse anzugeben
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-6 flex-shrink-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>
                        Den Gutscheincode kann man dann ganz einfach auf unserer
                        Auswertungsseite einlösen
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-6 flex-shrink-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Es gibt kein Ablaufdatum</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-6 flex-shrink-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>
                        Bei weiteren Fragen, kannst du uns jederzeit per E-Mail
                        schreiben: info@futedu.de
                      </span>
                    </li>
                    <li className="flex justify-center gap-2 pt-1">
                      <span>Viel Spaß beim Verschenken!</span>
                    </li>
                  </ul>
                  <div className="modal-action">
                    <form method="dialog">
                      <button className="btn bg-slate-600 hover:bg-slate-500">
                        Schließen
                      </button>
                    </form>
                  </div>
                </div>
              </dialog>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className="bg-primary py-16 lg:py-24">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-5 lg:gap-10 justify-center items-center lg:items-start">
          <div className="flex flex-col lg:gap-3 items-center lg:items-start basis-1/3 ml-2">
            <span className="text-slate-500 font-bold">FAQ</span>
            <h1 className="text-3xl lg:text-5xl">Häufig gestellte Fragen</h1>
          </div>
          <div className="px-5 basis-2/3">
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" defaultChecked />
              <div className="collapse-title text-xl font-medium">
                Was genau macht Futedu?
              </div>
              <div className="collapse-content">
                <p>
                  Wir bieten detaillierte und wissenschaftlich fundierte
                  Persönlichkeits- und Fertigkeitstests, die von künstlicher
                  Intelligenz analysiert werden, um individuelle Empfehlungen
                  für den am besten geeigneten Studiengang zu geben.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Wie lange dauert es, die Tests abzuschließen?
              </div>
              <div className="collapse-content">
                <p>
                  Ein Test dauert zwischen 10 und 20 Minuten. Die genaue Dauer
                  kann je nach dem gewählten Test und der individuellen
                  Geschwindigkeit variieren.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Muss ich alle Tests machen?
              </div>
              <div className="collapse-content">
                <p>
                  Nein. Je mehr Tests du allerdings abschließt, desto besser
                  kann unsere KI deine Persönlichkeit analysieren und die
                  Qualität der Auswertung steigt.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Muss ich alle Tests auf einmal abschließen?
              </div>
              <div className="collapse-content">
                <p>
                  Nein. Dein Fortschritt wird gespeichert. Einen angefangenen
                  Test solltest du allerdings vollständig beenden, damit wir den
                  Fortschritt speichern können.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Wie kann die KI mir bei der Wahl eines Studiengangs helfen?
              </div>
              <div className="collapse-content">
                <p>
                  Die KI analysiert deine Persönlichkeits- und
                  Fertigkeitsergebnisse und gleicht sie mit Daten von
                  erfolgreichen Studenten in verschiedenen Studiengängen ab.
                  Basierend darauf gibt sie Empfehlungen, die am besten zu
                  deinem Profil passen.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Wann bezahle ich?
              </div>
              <div className="collapse-content">
                <p>
                  Du bezahlst, wenn du alle relevanten Tests abgeschlossen hast.
                  Um loszulegen, brauchst du nur einen Account.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Wie läuft der Gutschein Kauf ab?
              </div>
              <div className="collapse-content">
                <p>
                  Klicke dazu auf den "Verschenken" Knopf und schließe den
                  Kaufprozess wie gewohnt ab. Nach erfolgreicher Transaktion
                  erhälst du von uns den Gutscheincode direkt per E-Mail
                  (Überprüfe auch deinen Spam-Ordner).
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Ich habe einen Gutschein. Wie löse ich diesen ein?
              </div>
              <div className="collapse-content">
                <p>
                  Um einen Gutschein einzulösen, gehe zu unserer
                  Auswertungsseite. Dort kannst du ihn ganz einfach in einem
                  Eingabefeld eingeben und deine Auswertung wird sofort
                  freigeschaltet.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Was ist, wenn ich nicht zufrieden bin?
              </div>
              <div className="collapse-content">
                <p>
                  Kein Problem! Wir bieten eine 100% Geld-zurück-Garantie an,
                  solltest du nicht zufrieden sein.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-gray-800 mb-1">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Wie ist meine Privatsphäre beim Ausfüllen der Tests
                gewährleistet?
              </div>
              <div className="collapse-content">
                <p>
                  Der Datenschutz unserer Nutzer ist uns sehr wichtig. Alle
                  persönlichen Daten und Testergebnisse werden vertraulich
                  behandelt und nicht an Dritte weitergegeben.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="preise"
        className="bg-gray-800/20 py-16 lg:py-24 px-5 flex flex-col"
      >
        <div className="mx-auto backdrop-filter-[12px] inline-flex h-7 bg-white/40 mb-3 items-center justify-between rounded-full border border-primary px-3 text-xs  group gap-1">
          <p className="mx-auto max-w-md text-white flex items-center">
            🎁&nbsp;&nbsp;
            <span className="font-semibold">
              Code "F50" für zusätzlich 50% Rabatt
            </span>
          </p>
        </div>
        <div className="max-w-screen-2xl mx-auto flex-col justify-center text-center">
          <span className="text-slate-500 font-bold">Pricing</span>

          <h1 className="text-4xl lg:text-5xl mt-3">
            Spar dir ein verschwendetes Jahr an der Uni
          </h1>
          <p className="max-w-xl mx-auto mt-6 mb-10 lg:mb-16 text-slate-300">
            Finde lieber direkt, was dich erfüllt und lass dir von uns einen
            Studiengang empfehlen, der an deine Persönlichkeit und deine
            Fertigkeiten angepasst ist.
          </p>

          <div className="relative flex justify-center flex-col lg:flex-row items-center lg:items-stretch gap-8">
            <div className="relative w-full max-w-lg">
              <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                <span className="badge text-xs text-white font-medium border-0 bg-green-500 whitespace-nowrap">
                  Launch-Angebot
                </span>
              </div>
              <div className="absolute -inset-[1px] rounded-[9px] bg-white z-10"></div>
              <div className="relative flex flex-col h-full gap-8 z-10 bg-neutral text-primary p-8 rounded-lg">
                <div className="flex flex-wrap gap-2">
                  <div className="flex flex-col justify-end mb-[4px] text-lg">
                    <p className="relative">
                      <span className="absolute bg-primary h-[1.5px] inset-x-0 top-[54%]"></span>
                      <span className="text-primary/80">29€</span>
                    </p>
                  </div>
                  <p className="text-4xl lg:text-5xl tracking-tight font-black">
                    4,99€
                  </p>
                  <div className="flex flex-col justify-end mb-[4px]">
                    <p className="text-xs text-primary/60 uppercase font-semibold">
                      EUR
                    </p>
                  </div>
                </div>
                <ul className="space-y-2.5 leading-relaxed text-base flex-1">
                  <li className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-[18px] h-[18px] opacity-80 shrink-0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Auswertung in wenigen Minuten</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-[18px] h-[18px] opacity-80 shrink-0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>PDF-Datei zum Downloaden</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-[18px] h-[18px] opacity-80 shrink-0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>30 Tage Geld-zurück-Garantie</span>
                  </li>
                </ul>
                <div className="space-y-2">
                  {paymentStatus === "succeeded" ? (
                    <NavLink to="/evaluation">
                      <button className="btn btn-accent text-primary btn-block group font-bold">
                        <div>Zur Auswertung</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </NavLink>
                  ) : (
                    <CheckoutButton
                      label="Jetzt kaufen"
                      priceId="price_1PqW59LCsjAecyK8XoY8d4Qu"
                      className="btn btn-accent btn-block group font-bold"
                    />
                  )}
                  <p className="text-sm text-center text-primary/40 font-medium relative">
                    Einmalige Zahlung
                  </p>
                </div>
                <div className="flex items-center justify-center my-4">
                  <div className="flex-grow border-t-2 border-gray-300"></div>
                  <span className="px-4 text-sm text-gray-600">oder</span>
                  <div className="flex-grow border-t-2 border-gray-300"></div>
                </div>
                <div className="flex flex-wrap gap-1">
                  <div className="flex flex-col justify-end mb-[4px] text-lg"></div>
                  <p className="text-3xl lg:text-4xl tracking-tight font-black">
                    Futedu verschenken
                  </p>
                  <div className="flex flex-col justify-end mb-[4px]"></div>
                </div>
                <span className="text-left">
                  Ob für dein Kind oder einfach jemanden in deinem Umkreis, der
                  noch nicht weiß, wohin es mit dem Studium gehen soll. Das ist
                  die perfekte Möglichkeit eine Starthilfe zu verschenken.
                </span>
                <ul className="space-y-2.5 leading-relaxed text-base flex-1">
                  <li className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-[18px] h-[18px] opacity-80 shrink-0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Gutschein-Code sofort verfügbar</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-[18px] h-[18px] opacity-80 shrink-0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Kein Ablaufdatum</span>
                  </li>
                </ul>
                <div className="space-y-2">
                  <CheckoutButton
                    label="Verschenken"
                    priceId="price_1PqW9BLCsjAecyK8iwZ5gc88"
                    isPresent={true}
                    className="btn btn-primary text-white btn-block group hover:brightness-150 font-bold"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

function Carousel({
  children: slides,
  autoSlide = true,
  autoSlideInterval = 7000,
}) {
  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="overflow-hidden relative max-w-xs md:max-w-xl rounded-md mb-10 p-2">
      <div
        className="flex transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="w-full flex-shrink-0">
            {slide}
          </div>
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-between">
        <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-slate-100/80 text-gray-800 hover:bg-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-slate-100/80 text-gray-800 hover:bg-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

const FeedbackCard = ({ zitat, pb, name, beschreibung }) => {
  return (
    <>
      <div className="my-5 mx-5 p-5 px-10 bg-slate-100 rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="fill-primary flex justify-center"
        >
          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
        </svg>
        <p className="py-5 text-left">{zitat}</p>
        <div className="flex flex-row items-end gap-2">
          <img
            src={pb}
            alt={`Foto von ${name}`}
            className="rounded-full w-9 h-auto"
          />
          <div className="flex flex-col items-start">
            <span className="text-sm">{name}</span>
            <span className="text-xs text-slate-400">{beschreibung}</span>
          </div>
        </div>
      </div>
    </>
  );
};
