import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase/firebaseConfig.js";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Layout } from "../Layout";
import { NavLink, Link } from "react-router-dom";
import { getKreativiteatResult } from "../Kreativiteat.js";
import { getSpracheResult } from "../Sprache.js";
import analyticsEventLog from "../../helperFunctions/AnalyticsEvent.js";

export function Overview() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Testübersicht - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [user, setUser] = useState(null);
  const [fieldsVisibility, setFieldsVisibility] = useState({});
  const keysOfInterest = [
    "Offenheit",
    "Kreativität",
    "Verhandeln",
    "Zahlenreihen",
    "Einkommen",
    // "Analytik",
    "Empathie",
    // "Sprache",
  ];
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      getKreativiteatResult(user.uid)
        .then((result) => {
          console.log("Kreativität result:", result);
        })
        .catch((error) => {
          console.error("Error fetching Kreativität result:", error);
        });

      getSpracheResult(user.uid)
        .then((result) => {
          console.log("Kreativität result:", result);
        })
        .catch((error) => {
          console.error("Error fetching Kreativität result:", error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    const checkFieldVisibility = async (usersRef) => {
      try {
        const querySnapshot = await getDoc(usersRef);
        if (querySnapshot.exists()) {
          setFieldsVisibility(querySnapshot.data());
        }
      } catch (error) {
        console.error("Error getting documents:", error.message);
      }
    };

    const { uid } = user;
    const usersRef = doc(db, "users", uid);
    checkFieldVisibility(usersRef);
  }, [user]);

  useEffect(() => {
    const progressCount = keysOfInterest.reduce((count, key) => {
      return fieldsVisibility[key] !== undefined ? count + 1 : count;
    }, 0);

    // Calculate the percentage outside the reduce function
    const progressPercentage = (progressCount / keysOfInterest.length) * 100;

    setProgress(progressPercentage);
  }, [fieldsVisibility, keysOfInterest]); // Dependency array to prevent unnecessary effect executions
  // Dependency array to prevent unnecessary effect executions
  console.log(progress);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt

  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout pageTitle={"Übersicht"}>
      <div className="flex flex-col items-center bg-primary px-5 ">
        <h1
          id="top"
          className="text-3xl text-center lg:text-5xl mt-4 lg:mt-8 lg:mb-8"
        >
          Willkommen in unserem Testzentrum!
        </h1>
      </div>
      <div className="bg-primary sticky top-[0px] z-10 flex items-center justify-center h-full gap-4 py-5">
        <div
          className={`tooltip ${
            showTooltip ? "tooltip-open" : ""
          } tooltip-right lg:tooltip-top tooltip-neutral`}
          data-tip="Klicke hier, um nützliche Informationen zu den Tests zu erhalten"
        >
          <button
            className="btn group bg-slate-600 hover:bg-slate-500 "
            onClick={() => {
              document.getElementById("my_modal_1").showModal();
              analyticsEventLog("overviewMehrInfoTestübersicht_button");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-6 group-hover:translate-y-[-5px] duration-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
          </button>
        </div>
        <dialog id="my_modal_1" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-xl text-center">
              Nützliche Hinweise
            </h3>
            <ul className="list-disc max-w-md mx-auto space-y-4 py-5">
              <li className="flex items-start gap-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>
                  Sei ehrlich zu dir selbst und verwende keine Hilfsmittel. Nur
                  so erhälst du die passendsten Ergebnisse
                </span>
              </li>
              <li className="flex items-start gap-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>
                  Es kann einen Moment dauern, bis ein Test als Erledigt
                  markiert ist (Kreativität bis zu 10 min)
                </span>
              </li>
              <li className="flex items-start gap-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Du musst nicht alle Tests auf einmal machen</span>
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>
                  Ein einzelner Test sollte allerdings am Stück erledigt werden
                </span>
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Dein Fortschritt wird sicher gespeichert</span>
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Es müssen nicht alle Tests zwingend gemacht werden.</span>
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>
                  Je mehr Tests du allerdings machst, desto besser wird deine
                  Auswertung{" "}
                </span>
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-6 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Tests mit Zeitlimit starten nicht direkt</span>
              </li>
            </ul>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn bg-slate-600 hover:bg-slate-500">
                  Schließen
                </button>
              </form>
            </div>
          </div>
        </dialog>

        <ProgressBtn progress={progress} />
      </div>

      <div className="bg-neutral px-5 pb-3">
        <div className="lg:grid lg:grid-cols-3 ">
          <OverviewCard
            title="Prioritäten"
            duration="ca. 1 min"
            description="Hier hast du die Möglichkeit uns mitzuteilen, was dir in deiner Karriere wichtg ist."
            to="/Prioritäten"
            id="Einkommen"
            display={
              fieldsVisibility["prioritäten0"] !== undefined &&
              fieldsVisibility["prioritäten0"] !== null
            }
          />
          <OverviewCard
            title="Persönlichkeit"
            description="Hier wirst du in den Big Five Persönlichkeitskategorien getestet."
            duration="10-15 min"
            to="/personality"
            id="Offenheit"
            display={
              fieldsVisibility["Offenheit"] !== undefined &&
              fieldsVisibility["Offenheit"] !== null
            }
          />
          <OverviewCard
            title="Kreativität"
            duration="1 min Zeitlimit"
            description="Diese Aufgabe lässt Rückschlüsse auf deine Kreativität und deinen Einfallsreichtum zu."
            to="/Kreativiteat"
            id="Kreativität"
            display={
              fieldsVisibility["Kreativität"] !== undefined &&
              fieldsVisibility["Kreativität"] !== null
            }
          />
          <OverviewCard
            title="Interessen"
            description="Dieser Test verschafft uns einen Überblick über deine Interessen und Neigungen"
            duration="10-15 min"
            to="/Interessen"
            id="Verhandeln"
            display={
              fieldsVisibility["Verhandeln"] !== undefined &&
              fieldsVisibility["Verhandeln"] !== null
            }
          />
          <OverviewCard
            title="Zahlenreihen"
            description="Mit diesem Test prüfen wir deine Fähigkeit zur Mustererkennung."
            duration="10 min Zeitlimit"
            id="Zahlenreihen"
            to="/zahlenreihen"
            display={
              fieldsVisibility["Zahlenreihen"] !== undefined &&
              fieldsVisibility["Zahlenreihen"] !== null
            }
          />

          <OverviewCard
            title="Empathie"
            description="Hier testen wir deine Empathiefähigkeit."
            duration="10-15 min"
            to="/Empathie"
            id="Empathie"
            display={
              fieldsVisibility["Empathie"] !== undefined &&
              fieldsVisibility["Empathie"] !== null
            }
          />
          <OverviewCard
            title="Sprache"
            description="Mit Hilfe eines Essay testen wir, wie gut du mit Sprache und dem Schreiben von Texten umgehen kannst."
            to="/sprache"
            optional={true}
            duration="30 min Zeitlimit"
            id="Sprache"
            display={
              fieldsVisibility["Sprache"] !== undefined &&
              fieldsVisibility["Sprache"] !== null
            }
          />
          <OverviewCard
            title="Logik"
            description="Dieser Test gibt Auskunft über deine Fähigkeit zum analytischen Denken."
            link="https://futedu.de/analytisches-reasoning/"
            id="Analytik"
            comingSoon={true}
            display={
              fieldsVisibility["Analytik"] !== undefined &&
              fieldsVisibility["Analytik"] !== null
            }
          />
        </div>
      </div>
    </Layout>
  );
}

const OverviewCard = ({
  title,
  description,
  duration,
  to,
  display,
  optional,
  comingSoon,
}) => {
  return (
    <div
      className="flex flex-col justify-between items-center text-center bg-primary px-8 py-8 lg:py-10 my-5 lg:mx-5 rounded-2xl shadow-lg shadow-slate-900  transform transition duration-300 
    lg:hover:scale-105"
    >
      <div class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
        <span
          class={`${
            optional
              ? "badge text-md text-white font-bold border-0 bg-slate-400 whitespace-nowrap"
              : "hidden "
          }`}
        >
          optional
        </span>
      </div>

      <h2 className="font-bold text-3xl text-center mb-4">{title}</h2>

      <p className="pb-16 text-center">{description}</p>
      {comingSoon ? (
        <div className="block">
          <span className="bg-slate-500 text-neutral py-2 font-semibold hover:brightness-90 px-14 rounded-3xl">
            bald verfügbar
          </span>
        </div>
      ) : (
        <>
          <p
            className={`${
              display ? "hidden" : "flex items-end text-slate-400 pb-3"
            }`}
          >
            Dauer: {duration}
          </p>

          <div className={`${display ? "hidden" : "block"}`}>
            <Link
              to={to}
              className="bg-accent py-2 text-primary font-semibold hover:brightness-90 px-16 rounded-3xl"
              onClick={() => analyticsEventLog(`overview${title}Test_button`)}
            >
              Starten
            </Link>
          </div>
        </>
      )}
      <div
        className={`${
          display
            ? "flex flex-row py-2 px-14 bg-green-100 gap-2 rounded-3xl"
            : "hidden"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 text-green-500"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
            clipRule="evenodd"
          />
        </svg>

        <div className="text-green-600">Erledigt</div>
      </div>

      <div
        className={`${
          display ? "absolute bottom-0 gap-2 py-1 lg:py-2 " : "hidden"
        }`}
      >
        <Link to={to} className="text-slate-400 hover:text-slate-300 group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-4 h-4 inline-block mr-1 group-hover:rotate-180 duration-500"
          >
            <path
              fillRule="evenodd"
              d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
              clipRule="evenodd"
            />
          </svg>
          Wiederhohlen
        </Link>
      </div>
    </div>
  );
};

const ProgressBtn = ({ progress }) => {
  console.log(progress);
  return (
    <>
      {progress < 80 ? (
        <div className="flex items-center justify-center h-full">
          <progress
            className="progress progress-success w-56 md:w-[36rem] lg:w-[54rem] h-6 mx-3"
            value={progress}
            max="100"
          ></progress>
          <span className="font-semibold">
            {parseFloat(progress).toFixed(0)}%
          </span>
        </div>
      ) : (
        <NavLink to="/evaluation">
          <button className="btn group btn-accent text-base px-12">
            <div>Zur Auswertung</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
            >
              <path
                fillRule="evenodd"
                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </NavLink>
      )}
    </>
  );
};
