import { NavLink } from "react-router-dom";
// import "./Layout.css";
import "./Footer.css";
import React, { useState, useEffect, useRef } from "react";
// import "./Header.css";
import { auth, analytics } from "../firebase/firebaseConfig";
import { logEvent } from "firebase/analytics";
import logoImage from "../assets/Futedu_logo.png";
import analyticsEventLog from "../helperFunctions/AnalyticsEvent";
//import "./Header.css"; // Import your CSS file for styling

export function Layout({ pageTitle, children }) {
  return (
    <>
      <Header pageTitle={pageTitle}></Header>
      <main className="bg-neutral">
        <div className="content-container">{children}</div>
      </main>
      <Footer />
    </>
  );
}

export function Header({ pageTitle }) {
  const [user, setUser] = useState(null);
  const [showLogoutNotification, setShowLogoutNotification] = useState(false);

  useEffect(() => {
    // Überwache den Authentifizierungsstatus des Benutzers
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    // Aufräumen beim unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    logEvent(analytics, "headerLogout_button");
    auth.signOut().then(() => {
      setTimeout(() => {
        window.location.reload(); // Verzögere den Seitenreload um die Benachrichtigung anzuzeigen
      }, 600); // Verzögerung von 1000ms (1 Sekunde), kannst du anpassen
      setShowLogoutNotification(true); // Setze die Benachrichtigung auf true, bevor die Seite neu geladen wird
    });
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  document.body.style.overflow = !menuOpen ? "auto" : "hidden";

  const homeRef = useRef(null);
  const handleHeaderClick = (sectionId) => (event) => {
    event.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = `/#${sectionId}`;
    }
    if (homeRef.current) {
      homeRef.current.click();
    }
  };

  return (
    <>
      <div className="navbar bg-base-100 h-24">
        <div className="navbar-start">
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost text-white lg:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-1 z-[11] p-2 shadow-[0_3px_10px_rgb(0,0,0,0.2)] font-bold bg-primary brightness-125 rounded-box w-52"
            >
              {/* <li>
                <NavLink className="my-2" to="/">
                  Home
                </NavLink>
              </li> */}
              <li>
                <NavLink className="my-2 " to="/">
                  Home
                </NavLink>
                <ul className="p-2 ">
                  <li>
                    <a
                      className="my-2"
                      href="/#faq"
                      onClick={handleHeaderClick("faq")}
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="/#preise" onClick={handleHeaderClick("preise")}>
                      Preise
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a>Leistungen</a>
                <ul className="p-2">
                  <li>
                    <NavLink className="my-2" to="/beispiel">
                      Beispielauswertung
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="my-2" to="/casestudy">
                      Case Study
                    </NavLink>
                  </li>
                  <li className="hover:bg-gray-200 rounded-lg">
                    <NavLink className="my-2" to="/blog">
                      Blog
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="my-2" to="/overview">
                  Testübersicht
                </NavLink>
              </li>
              <li>
                <NavLink className="my-2" to="/evaluation">
                  Auswertung
                </NavLink>
              </li>
            </ul>
          </div>
          <NavLink
            to="/"
            onClick={() => analyticsEventLog("headerFuteduLogo_button")}
          >
            <div
              className="btn btn-ghost text-xl text-gray-50 flex items-center"
              style={{ whiteSpace: "nowrap" }}
            >
              <img
                src={logoImage}
                alt="Logo"
                className="w-8 md:w-10 flex-shrink-0"
              />
              <span className="text-sm md:text-xl flex-shrink-0">Futedu</span>
            </div>
          </NavLink>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1 gap-2 text-gray-50 font-bold ">
            <li>
              <details>
                <summary ref={homeRef}>Home</summary>
                <ul className="p-2 bg-white text-primary">
                  <li className="hover:bg-gray-200 rounded-lg">
                    <a href="/#faq" onClick={handleHeaderClick("faq")}>
                      FAQ
                    </a>
                  </li>
                  <li className="hover:bg-gray-200 rounded-lg">
                    <a href="/#preise" onClick={handleHeaderClick("preise")}>
                      Preise
                    </a>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>Leistungen</summary>
                <ul className="p-2 bg-white text-primary z-20">
                  <li className="hover:bg-gray-200 rounded-lg">
                    <NavLink className="my-2" to="/beispiel">
                      Beispielauswertung
                    </NavLink>
                  </li>
                  <li className="hover:bg-gray-200 rounded-lg">
                    <NavLink className="my-2" to="/casestudy">
                      Case Study
                    </NavLink>
                  </li>
                  <li className="hover:bg-gray-200 rounded-lg">
                    <NavLink className="my-2" to="/blog">
                      Blog
                    </NavLink>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <NavLink className="mx-2" to="/overview">
                Testübersicht
              </NavLink>
            </li>
            <li>
              <NavLink className="mx-2" to="/evaluation">
                Auswertung
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="navbar-end mr-5">
          {user ? (
            // Wenn der Benutzer eingeloggt ist, zeige den Logout-Button
            <button
              className="btn btn-outline btn-neutral "
              onClick={handleLogout}
            >
              <div className="text-xs md:text-sm">Logout</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4 md:w-5 md:h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M3 4.25A2.25 2.25 0 0 1 5.25 2h5.5A2.25 2.25 0 0 1 13 4.25v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 0-.75-.75h-5.5a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-2a.75.75 0 0 1 1.5 0v2A2.25 2.25 0 0 1 10.75 18h-5.5A2.25 2.25 0 0 1 3 15.75V4.25Z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M6 10a.75.75 0 0 1 .75-.75h9.546l-1.048-.943a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 1 1-1.004-1.114l1.048-.943H6.75A.75.75 0 0 1 6 10Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            // Wenn der Benutzer ausgeloggt ist, zeige den Login-Link
            <NavLink
              className="btn btn-outline btn-neutral"
              to="/Registrieren"
              onClick={() => analyticsEventLog("headerLogin_button")}
            >
              <div className="text-xs md:text-sm">Anmelden</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4 md:w-5 md:h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M17 4.25A2.25 2.25 0 0 0 14.75 2h-5.5A2.25 2.25 0 0 0 7 4.25v2a.75.75 0 0 0 1.5 0v-2a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 .75.75v11.5a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 0-1.5 0v2A2.25 2.25 0 0 0 9.25 18h5.5A2.25 2.25 0 0 0 17 15.75V4.25Z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M1 10a.75.75 0 0 1 .75-.75h9.546l-1.048-.943a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 1 1-1.004-1.114l1.048-.943H1.75A.75.75 0 0 1 1 10Z"
                  clipRule="evenodd"
                />
              </svg>
            </NavLink>
          )}
        </div>
      </div>
      <div className="relative">
        {/* Hier fügst du den Alert hinzu, der nur angezeigt wird, wenn alertVisible true ist */}
        {showLogoutNotification && (
          <div
            className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-8 bg-green-200 border border-green-400 text-green-700 px-4 py-3 rounded-md shadow-lg font-semibold z-50"
            role="alert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>Erfolgreich ausgeloggt</span>
          </div>
        )}
      </div>
    </>
  );
}

export function Footer() {
  return (
    <footer className="footer p-10 bg-base-300 text-base-content text-md">
      <aside className="items-center grid-flow-row">
        <div className="flex flex-row items-center gap-2">
          <img src={logoImage} alt="Logo" className="w-10" />
          <p className="font-semibold text-xl">Futedu</p>
          <p></p>
        </div>
        <p className="max-w-xs">
          KI gestützte Studien- und Eignungsanalyse basierend auf deiner
          Persönlichkeit.
        </p>
        <p className="text-slate-500">
          Copyright © 2024 - Alle Rechte vorbehalten
        </p>
        <a href="https://www.it-recht-kanzlei.de/" target="_blank">
          <img
            src="https://www.it-recht-kanzlei.de/logo/Logo_Juristisch_betreut_durch_IT-Recht_Kanzlei.png?i=57574-3dcf6-39c6-907c-1"
            alt="Vertreten durch die IT-Recht Kanzlei"
            className="w-24"
          />
        </a>
      </aside>
      <nav>
        <h6 className="footer-title">Unternehmen</h6>
        <NavLink
          to="/AboutUs"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          Über uns
        </NavLink>
        <NavLink
          to="/Blog"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          Blog
        </NavLink>
        <NavLink
          to="/Beispiel"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          Beispielauswertung
        </NavLink>
        <NavLink
          to="/caseStudy"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          Case Study
        </NavLink>
      </nav>
      <nav>
        <h6 className="footer-title">RECHTLICHES</h6>
        <NavLink
          to="/Impressum"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          Impressum
        </NavLink>
        <NavLink
          to="/Geschäftsbedingungen"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          AGB
        </NavLink>

        <NavLink
          to="/Datenschutzvereinbarung"
          className="link link-hover"
          onClick={() => window.scrollTo(0, 0)}
        >
          Datenschutzerklärung
        </NavLink>
        <a className="link link-hover text-blue-800" href="/?cmpscreen">
          Cookie Einstellungen
        </a>
      </nav>
      <nav>
        <h6 className="footer-title">Kontakt</h6>
        <a className="pb-1 link link-hover" href="mailto: info@futedu.de">
          info@futedu.de
        </a>
        <div className="grid grid-flow-col gap-4">
          <a
            href="https://www.tiktok.com/@futedu_de"
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-tiktok"
              viewBox="0 0 16 16"
            >
              <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/futedu_de/?igsh=MXZkbmVnNnNqdzdpYQ%3D%3D"
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-instagram"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/futedugermany/"
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-linkedin"
              viewBox="0 0 16 16"
            >
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
            </svg>
          </a>
        </div>
      </nav>
    </footer>
  );
}
