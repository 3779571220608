import { db } from "../../firebase/firebaseConfig"; // Your Firestore configuration file
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getData } from "./pdfUtils";

//trigger cloud funciton for evaluation
async function getEvaluation(prompt, userID, type) {
  try {
    // Correctly target the 'prompts' subcollection for the given user
    const promptsCollectionRef = collection(db, `users/${userID}/messages`);
    const docRef = await addDoc(promptsCollectionRef, {
      prompt: prompt,
      status: { state: null },
      type: type,
    });
    console.log("Document written with ID: ", docRef);
    return docRef; // Return the DocumentReference for further use if needed
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // Rethrow the error for handling by the caller
  }
}

//check for response in data base. returns all responses if true
export async function getResponsesForUser(userID, type) {
  // Reference to the user's messages subcollection
  const messagesRef = collection(db, `users/${userID}/messages`);

  // Create a query that looks for messages where the 'response' field exists
  const q = query(
    messagesRef,
    where("response", "!=", null),
    where("type", "==", type)
  );

  try {
    // Execute the query
    const querySnapshot = await getDocs(q);
    const responses = [];

    querySnapshot.forEach((doc) => {
      // For each document, add its 'response' field value to the 'responses' array
      responses.push(doc.data().response);
    });

    // Check if we found any responses
    if (responses.length > 0) {
      console.log("Found messages with responses:", responses);
      return responses[0]; // Return an array of response values
    } else {
      console.log("No messages with responses found.");
      return null; // Return null to indicate no responses were found
    }
  } catch (error) {
    console.error("Error fetching messages with responses:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export async function createPrompt(userID) {
  
  try {
    const data = await getData(userID); // Await the async call to getData
    if (data) {
      const interessenPrompt =
        `Ich habe einen Interessentest gemacht. Bitte schreibe mir eine Zusammenfassung. Verwende "du". hier sind die ergebnisse:

      Kreieren: ` +
        data.Kreieren +
        `
      Koordinieren: ` +
        data.Koordinieren +
        `
      Helfen: ` +
        data.Helfen +
        `
      Verhandeln: ` +
        data.Verhandeln +
        `
      Analysieren: ` +
        data.Analysieren +
        `;
      Technik: ` +
        data.Technik +
        `
      Künste: ` +
        data.Künste +
        `
      Gesellschaft: ` +
        data.Gesellschaft +
        `
      Naturwissenschaften: ` +
        data.Naturwissenschaften + `welche Kombinationen sind interessant bzw ungewöhnlich?`;
      //------------------------------------------------------------------------------------------
      const prioritätenPrompt =
        `Ich habe einen Test bezüglich meiner beruflichen Vorstellungen gemacht. Bitte schreibe mir eine Zusammenfassung. Verwende "du" hier sind die ergebnisse:
      Einkommen: ` +
        data.Einkommen +
        `;
        Freiheit: ` +
        data.Freiheit +
        `;
        Verantwortung: ` +
        data.Verantwortung +
        `;
        Sinnstiftend: ` +
        data.Sinnstiftend +
        `;
        Kundenkontakt: ` +
        data.Kundenkontakt;
      //------------------------------------------------------------------------------------------
      const fähigkeitenPrompt =
        `Ich habe Test bezüglich meiner Fähigkeiten durchgeführt. Bitte schreibe mir eine Zusammenfassung. Nimm Bezug auf meine Stärken und Schwächen, erkläre wie ich diese nutzen bzw. verbessern kann. Verwende "du". Hier sind meine Ergebnisse:
      Kreativität: ` +
        data.Kreativität +
        `;
        Empathische Fähigkeiten: ` +
        data.Empathie +
        `Sprachliche Fähigkeiten: ` +
        data.Sprache + 
        `numerische Intelligenz: ` + 
        data.Zahlenreihen;
      const persönlichkeitPrompt =
        `Ich habe einen Persönlichleitstest gemacht. Bitte schreibe mir eine Zusammenfassung. Verwende "du". Antworte in folgender Struktur:
        Verträglichkeit: Erkläre das Spektrum der Verträglichkeit im Rahmen der Big 5 und interpretiere meine Werte.
        Gewissenhaftigkeit: Erkläre das Spektrum der Gewissenhaftigkeit im Rahmen der Big 5 und interpretiere meine Werte.
        Extraversion: Erkläre das Spektrum der Extraversion im Rahmen der Big 5 und interpretiere meine Werte.
        Neurotizismus: Erkläre das Spektrum des Neurotizismus im Rahmen der Big 5 und interpretiere meine Werte.
        Offenheit: Erkläre das Spektrum der Offenheit im Rahmen der Big 5 und interpretiere meine Werte.
        
        hier sind die ergebnisse: 
      Verträglichkeit:` +
        data.Verträglichkeit +
        `
      Gewissenhaftigkeit: ` +
        data.Gewissenhaftigkeit +
        `Extraversion: ` +
        data.Extrovertiert +
        `
      Neurotizismus: ` +
        data.Neurotizismus +
        `
      Offenheit: ` +
        data.Offenheit;

      //------------------------------------------------------------------------------------------
      const prioString = data.prioritäten1 ? `Bei dieser empfehlung möchte ich etwas sehr ${data.prioritäten1}, ${data.prioritäten2} oder ${data.prioritäten3} zu machen.` : "";
 
      const auswertungPrompt =
        `Nutze die folgenden Informationen um mir 3 Studiengänge zu empfehlen. Begründe deine Entscheidung basierend auf meinen Ergebnissen ausführlich. Analysiere meine Werte und beziehe alle in deine Überlegung mit ein. Interpretiere und nutze auch die niedrigen Werte. Die Skala ist von 0 sehr niedrig, 0.5 normal, 1 sehr hoch.
      Nutze folgende Struktur:
      
      1. Name
      Begründung: 
      Erkläre ausführlich und interessant, wieso ich für dieses Studium gut geeignet bin. 
      Persönlichkeit: 
      erkläre welche teile meiner Persönlichkeit dafür sprechen.
      Fähigkeiten: 
      erkläre umfassend welche teile meiner Fähigkeiten dafür sprechen.
      Interessen: 
      erkläre umfassend welche teile meiner Interessen dafür sprechen.
      
      Erkläre auch welche Teile meines Profils weniger zu dem Studium passen. 
      Inhalte des Studiums: Beschreibe den typischen Aufbau des Studiums und welche Inhalte mich erwarten.
      Berufsfelder: Nenne mir 3 mögliche Berufe und erkläre jeweils die typischen Tätigkeiten .
      
      ...
      2. Name
      Begründung: 
      Erkläre ausführlich und interessant, wieso ich für dieses Studium gut geeignet bin. 
      Persönlichkeit: 
      erkläre welche teile meiner Persönlichkeit dafür sprechen.
      Fähigkeiten: 
      erkläre umfassend welche teile meiner Fähigkeiten dafür sprechen.
      Interessen: 
      erkläre umfassend welche teile meiner Interessen dafür sprechen.
      
      Erkläre auch welche Teile meines Profils weniger zu dem Studium passen. 
      Inhalte des Studiums: Beschreibe den typischen Aufbau des Studiums und welche Inhalte mich erwarten.
      Berufsfelder: Nenne mir 3 mögliche Berufe und erkläre jeweils die typischen Tätigkeiten .
      
      ...
      3. Name
      `+ prioString + 
      `Begründung: 
      Erkläre ausführlich und interessant, wieso ich für dieses Studium gut geeignet bin. 
      Persönlichkeit: 
      erkläre welche teile meiner Persönlichkeit dafür sprechen.
      Fähigkeiten: 
      erkläre umfassend welche teile meiner Fähigkeiten dafür sprechen.
      Interessen: 
      erkläre umfassend welche teile meiner Interessen dafür sprechen.
      
      Erkläre auch welche Teile meines Profils weniger zu dem Studium passen. 
      Inhalte des Studiums: Beschreibe den typischen Aufbau des Studiums und welche Inhalte mich erwarten.
      Berufsfelder: Nenne mir 3 mögliche Berufe und erkläre jeweils die typischen Tätigkeiten .
      
      ...
        
        Big 5 Personality: {
        Verträglichkeit:` +
        data.Verträglichkeit +
        `;
        Gewissenhaftigkeit: ` +
        data.Gewissenhaftigkeit +
        `;
        Extraversion: ` +
        data.Extrovertiert +
        `;
        Neurotizismus: ` +
        data.Neurotizismus +
        `;
        Offenheit: ` +
        data.Offenheit +
        `;}
        
        Fähigkeiten: {
        Kreativität: ` +
        data.Kreativität +
        `,
        Empathische Fähigkeiten: ` +
        data.Empathie +
        `,
        numerische Intelligenz: ` +
        data.Zahlenreihen +
        `,
        Schreibkompetenz: ` +
        data.Sprachtest +
        `;}
        
        Interessen: {
        Koordinieren und Organisieren: ` +
        data.Koordinieren +
        `,
        Gestalten und Designen: ` +
        data.Kreieren +
        `,
        Helfen und Kümmern: ` +
        data.Helfen +
        `,
        Verhandeln und Argumentieren: ` +
        data.Verhandeln +
        `,
        analytische Tätigkeiten: ` +
        data.Analysieren +
        `
      Computer und Maschinen: ` +
        data.Technik +
        `
      Künste: ` +
        data.Künste +
        `
      Gesellschaft: ` +
        data.Gesellschaft +
        `
      Naturwissenschaften: ` +
        data.Naturwissenschaften //------------------------------------------------------------------------------------------
      const berufPrompt =
        `Nutze die folgenden Informationen um mir 3 spezifische Berufe zu empfehlen. Begründe deine Entscheidung basierend auf meinen Ergebnissen ausführlich. Analysiere meine Werte und beziehe alle in deine Überlegung mit ein. Interpretiere und nutze auch die niedrigen Werte. Die Skala ist von 0 sehr niedrig, 0.5 normal, 1 sehr hoch.
      Nutze folgende Struktur:
      
      1. Name
      Begründung: 
      Erkläre ausführlich und interessant, wieso ich für diesen Beruf gut geeignet bin. 
      Persönlichkeit: 
      erkläre welche teile meiner Persönlichkeit dafür sprechen.
      Fähigkeiten: 
      erkläre umfassend welche teile meiner Fähigkeiten dafür sprechen.
      Interessen: 
      erkläre umfassend welche teile meiner Interessen dafür sprechen.
      
      Erkläre auch welche Teile meines Profils weniger zu dem Studium passen. 
      Inhalte des Berufs: Beschreibe den typischen Aufgaben und welche Inhalte mich erwarten.
      Studiengänge: Nenne mir 3 mögliche Studiengänge, die mir diese Karriere ermöglichen.
      
      ...
      2. Name
      Begründung: 
      Erkläre ausführlich und interessant, wieso ich für diesen Beruf gut geeignet bin. 
      Persönlichkeit: 
      erkläre welche teile meiner Persönlichkeit dafür sprechen.
      Fähigkeiten: 
      erkläre umfassend welche teile meiner Fähigkeiten dafür sprechen.
      Interessen: 
      erkläre umfassend welche teile meiner Interessen dafür sprechen.
      
      Erkläre auch welche Teile meines Profils weniger zu dem Studium passen. 
      Inhalte des Berufs: Beschreibe den typischen Aufgaben und welche Inhalte mich erwarten.
      Studiengänge: Nenne mir 3 mögliche Studiengänge, die mir diese Karriere ermöglichen.
      ...
      3. Name
      Begründung: 
      Erkläre ausführlich und interessant, wieso ich für diesen Beruf gut geeignet bin. 
      Persönlichkeit: 
      erkläre welche teile meiner Persönlichkeit dafür sprechen.
      Fähigkeiten: 
      erkläre umfassend welche teile meiner Fähigkeiten dafür sprechen.
      Interessen: 
      erkläre umfassend welche teile meiner Interessen dafür sprechen.
      
      Erkläre auch welche Teile meines Profils weniger zu dem Studium passen. 
      Inhalte des Berufs: Beschreibe den typischen Aufgaben und welche Inhalte mich erwarten.
      Studiengänge: Nenne mir 3 mögliche Studiengänge, die mir diese Karriere ermöglichen.
      
      ...
        
        Persönlichkeit: {
        Verträglichkeit:` +
        data.Verträglichkeit +
        `;
        Gewissenhaftigkeit: ` +
        data.Gewissenhaftigkeit +
        `;
        Extraversion: ` +
        data.Extrovertiert +
        `;
        Neurotizismus: ` +
        data.Neurotizismus +
        `;
        Offenheit: ` +
        data.Offenheit +
        `;}
        
        Fähigkeiten: {
        Kreativität: ` +
        data.Kreativität +
        `,
        Empathische Fähigkeiten: ` +
        data.Empathie +
        `,
        numerische Intelligenz: ` +
        data.Zahlenreihen +
        `,
        Schreibkompetenz: ` +
        data.Sprachtest +
        `;}
        
        Interessen: {
        Koordinieren: ` +
        data.Koordinieren +
        `,
        Gestalten und Designen: ` +
        data.Kreieren +
        `,
        Helfen: ` +
        data.Helfen +
        `,
        Verhandeln: ` +
        data.Verhandeln +
        `,
        analytische Tätigkeiten: ` +
        data.Analysieren +
        `
      Technik: ` +
        data.Technik +
        `
      Künste: ` +
        data.Künste +
        `
      Gesellschaft: ` +
        data.Gesellschaft +
        `
      Naturwissenschaften: ` +
        data.Naturwissenschaften +
        `;}`;
      //------------------------------------------------------------------------------------------
      const allPrompts = [
        persönlichkeitPrompt,
        prioritätenPrompt,
        fähigkeitenPrompt,
        interessenPrompt,
        auswertungPrompt,
        berufPrompt,
      ];
      return allPrompts;
    } else {
      // Handle cases where data is null (user document not found or no data)
      return "Kreativität: Daten nicht verfügbar"; // Example fallback prompt
    }
  } catch (error) {
    // Handle errors that might occur during data fetching or prompt creation
    console.error("Error creating prompt:", error);
    return "Kreativität: Fehler beim Erstellen des Prompts"; // Example error prompt
  }
}

// A function to fetch evaluation and store it in Firestore
export async function submitEvaluation(prompt, userID, type) {
  try {
    const docRef = await getEvaluation(prompt, userID, type);

    // Return a promise that resolves when the AI response is received.
    return new Promise((resolve, reject) => {
      const unsub = onSnapshot(
        docRef,
        (doc) => {
          const data = doc.data();
          if (data && data.status && data.status.state === "COMPLETED") {
            console.log("AI Response:", data.response); // Assuming 'response' is where the AI response is stored.
            resolve(data.response); // Resolve the promise with the AI response.
            unsub(); // Unsubscribe after handling the response.
          } else if (data && data.status && data.status.state === "ERRORED") {
            reject(new Error("Error processing evaluation."));
            unsub(); // Unsubscribe on error.
          }
        },
        (error) => {
          reject(error); // Reject the promise on subscription error.
        }
      );
    });
  } catch (error) {
    console.error("Error submitting evaluation:", error);
    throw error; // Rethrow the error for handling by the caller.
  }
}
