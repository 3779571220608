import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { analytics, auth, db } from "../../firebase/firebaseConfig.js";
import { doc, setDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../assets/Futedu_logo.png";
import GoogleButton from "react-google-button";
import LoginError from "./LoginError.js";
import analyticsEventLog from "../../helperFunctions/AnalyticsEvent.js";

export function Registrieren() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Registrieren - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    analyticsEventLog("registrieren_button");
    e.preventDefault();
    setErrorMessage("");

    if (password !== confirmPassword) {
      setErrorMessage("Die Passwörter stimmen nicht überein.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(user);

      setDoc(
        doc(db, "users", user.uid),
        {
          email: user.email,
        },
        { merge: true }
      )
        .then(() => {
          console.log("User added to Firestore vorstellung");
        })
        .catch((error) => {
          console.log("Error adding user to Firestore:", error);
        });

      navigate("../Overview");
    } catch (error) {
      console.error(error);
      setErrorMessage(error.code);
    }
  };

  const handleGoogleSignIn = async () => {
    analyticsEventLog("googleSignUp_button");
    const provider = new GoogleAuthProvider();
    setErrorMessage("");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user);

      setDoc(
        doc(db, "users", user.uid),
        {
          email: user.email,
        },
        { merge: true }
      )
        .then(() => {
          console.log("User added to Firestore vorstellung");
        })
        .catch((error) => {
          console.log("Error adding user to Firestore:", error);
        });

      navigate("../Overview");
    } catch (error) {
      console.error(error);
      setErrorMessage(error.code);
    }
  };

  return (
    <>
      {errorMessage && <LoginError errorMessage={errorMessage} />}
      <div className="flex justify-center items-center min-h-screen bg-primary">
        <div className="w-96 bg-primary shadow-md rounded-lg md:border md:border-white">
          <div className="flex justify-center pt-5">
            <Link to="/">
              <img src={logoImage} alt="Logo" className="w-10 h-10" />
            </Link>
          </div>
          <div className="p-5">
            <h2 className="text-center text-2xl font-semibold">Registrieren</h2>
            <p className="text-sm text-center mt-1">
              Hast du bereits ein Konto? Hier{" "}
              <Link to="../Login" className="text-blue-500 hover:text-blue-600">
                Anmelden
              </Link>
            </p>
          </div>
          <form
            className="space-y-4 text-primary px-5 pb-5"
            onSubmit={handleSubmit}
          >
            <div>
              <input
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                name="email"
                required
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div>
              <input
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                name="password"
                required
                type="password"
                placeholder="Passwort"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div>
              <input
                id="confirmPassword"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                name="confirmPassword"
                required
                type="password"
                placeholder="Passwort bestätigen"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>
            <div className="flex justify-center">
              <button
                className="px-4 py-2 btn btn-accent rounded-md w-60"
                type="submit"
              >
                Registrieren
              </button>
            </div>
          </form>
          <div className="flex items-center justify-center my-4">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="px-4 text-sm text-gray-600">oder</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          <div className="flex justify-center pb-5 ">
            <GoogleButton onClick={handleGoogleSignIn} />
          </div>
          <div className="text-sm text-center px-5 pb-5">
            <p>
              Durch die Registrierung akzeptierst du unsere{" "}
              <Link
                to="../Geschäftsbedingungen"
                className="text-blue-500 hover:text-blue-600"
              >
                AGB
              </Link>{" "}
              und{" "}
              <Link
                to="../Datenschutzvereinbarung"
                className="text-blue-500 hover:text-blue-600"
              >
                Datenschutzvereinbarung
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
