import { Layout } from "./Layout";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export function Geschäftsbedingungen() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Geschäftsbedingungen - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  return (
    <Layout pageTitle="Geschäftsbedinungen">
      <div className="text-primary p-5">
        <h1 class="text-2xl font-bold mb-4">
          Allgemeine Geschäftsbedingungen mit Kundeninformationen
        </h1>

        <h2 class="text-xl font-semibold mb-2">Inhaltsverzeichnis</h2>
        <ol class="list-decimal ml-5 mb-4">
          <li class="mb-1">Geltungsbereich</li>
          <li class="mb-1">Vertragsschluss</li>
          <li class="mb-1">Widerrufsrecht</li>
          <li class="mb-1">Preise und Zahlungsbedingungen</li>
          <li class="mb-1">Anwendbares Recht</li>
          <li class="mb-1">Alternative Streitbeilegung</li>
        </ol>

        <h2 class="text-xl font-semibold mb-2">1) Geltungsbereich</h2>
        <p class="mb-4">
          <b>1.1</b> Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB")
          der Futedu GbR (nachfolgend "Anbieter"), gelten für alle Verträge zur
          Erbringung von Dienstleistungen, die ein Verbraucher oder Unternehmer
          (nachfolgend „Kunde“) mit dem Anbieter hinsichtlich der vom Anbieter
          auf seiner Website dargestellten Leistungen abschließt. Hiermit wird
          der Einbeziehung von eigenen Bedingungen des Kunden widersprochen, es
          sei denn, es ist etwas anderes vereinbart.
        </p>
        <p class="mb-4">
          <b>1.2</b> Verbraucher im Sinne dieser AGB ist jede natürliche Person,
          die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
          ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit
          zugerechnet werden können. Unternehmer im Sinne dieser AGB ist eine
          natürliche oder juristische Person oder eine rechtsfähige
          Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in
          Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit
          handelt.
        </p>

        <h2 class="text-xl font-semibold mb-2">2) Vertragsschluss</h2>
        <p class="mb-4">
          <b>2.1</b> Die auf der Website des Anbieters befindlichen
          Leistungsbeschreibungen stellen keine verbindlichen Angebote seitens
          des Anbieters dar, sondern dienen zur Abgabe eines verbindlichen
          Angebots durch den Kunden.
        </p>
        <p class="mb-4">
          <b>2.2</b> Der Kunde kann das Angebot über das in die Website des
          Anbieters integrierte Online-Bestellformular abgeben. Dabei gibt der
          Kunde, nachdem er die ausgewählten Leistungen in den virtuellen
          Warenkorb gelegt und den elektronischen Bestellprozess durchlaufen
          hat, durch Klicken des den Bestellvorgang abschließenden Buttons ein
          rechtlich verbindliches Vertragsangebot in Bezug auf die im Warenkorb
          enthaltenen Leistungen ab.
        </p>
        <p class="mb-4">
          <b>2.3</b> Der Anbieter kann das Angebot des Kunden innerhalb von fünf
          Tagen annehmen,
        </p>
        <ul class="list-disc ml-8 mb-4">
          <li class="mb-1">
            indem er dem Kunden eine schriftliche Auftragsbestätigung oder eine
            Auftragsbestätigung in Textform (Fax oder E-Mail) übermittelt, wobei
            insoweit der Zugang der Auftragsbestätigung beim Kunden maßgeblich
            ist, oder
          </li>
          <li class="mb-1">
            indem er auf Anforderung des Kunden mit der Ausführung der Leistung
            beginnt und dies dem Kunden anzeigt, oder
          </li>
          <li class="mb-1">
            indem er den Kunden nach Abgabe von dessen Bestellung zur Zahlung
            auffordert.
          </li>
        </ul>
        <p class="mb-4">
          Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in
          dem Zeitpunkt zustande, in dem eine der vorgenannten Alternativen
          zuerst eintritt. Die Frist zur Annahme des Angebots beginnt am Tag
          nach der Absendung des Angebots durch den Kunden zu laufen und endet
          mit dem Ablauf des fünften Tages, welcher auf die Absendung des
          Angebots folgt. Nimmt der Anbieter das Angebot des Kunden innerhalb
          vorgenannter Frist nicht an, so gilt dies als Ablehnung des Angebots
          mit der Folge, dass der Kunde nicht mehr an seine Willenserklärung
          gebunden ist.
        </p>
        <p class="mb-4">
          <b>2.4</b> Bei der Abgabe eines Angebots über das
          Online-Bestellformular des Anbieters wird der Vertragstext nach dem
          Vertragsschluss vom Anbieter gespeichert und dem Kunden nach Absendung
          von dessen Bestellung in Textform (z. B. E-Mail, Fax oder Brief)
          übermittelt. Eine darüber hinausgehende Zugänglichmachung des
          Vertragstextes durch den Anbieter erfolgt nicht. Sofern der Kunde vor
          Absendung seiner Bestellung ein Nutzerkonto im Website des Anbieters
          eingerichtet hat, werden die Bestelldaten auf der Website des
          Anbieters archiviert und können vom Kunden über dessen
          passwortgeschütztes Nutzerkonto unter Angabe der entsprechenden
          Login-Daten kostenlos abgerufen werden.
        </p>
        <p class="mb-4">
          <b>2.5</b> Vor verbindlicher Abgabe der Bestellung über das
          Online-Bestellformular des Anbieters kann der Kunde mögliche
          Eingabefehler durch aufmerksames Lesen der auf dem Bildschirm
          dargestellten Informationen erkennen. Ein wirksames technisches Mittel
          zur besseren Erkennung von Eingabefehlern kann dabei die
          Vergrößerungsfunktion des Browsers sein, mit deren Hilfe die
          Darstellung auf dem Bildschirm vergrößert wird. Seine Eingaben kann
          der Kunde im Rahmen des elektronischen Bestellprozesses so lange über
          die üblichen Tastatur- und Mausfunktionen korrigieren, bis er den
          Button anklickt, welcher den Bestellvorgang abschließt.
        </p>
        <p class="mb-4">
          <b>2.6</b> Für den Vertragsschluss steht die deutsche Sprache zur
          Verfügung.
        </p>
        <p class="mb-4">
          <b>2.7</b> Die Bestellabwicklung und Kontaktaufnahme finden in der
          Regel per E-Mail und automatisierter Bestellabwicklung statt. Der
          Kunde hat sicherzustellen, dass die von ihm zur Bestellabwicklung
          angegebene E-Mail-Adresse zutreffend ist, so dass unter dieser Adresse
          die vom Anbieter versandten E-Mails empfangen werden können.
          Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern
          sicherzustellen, dass alle vom Anbieter oder von diesem mit der
          Bestellabwicklung beauftragten Dritten versandten E-Mails zugestellt
          werden können.
        </p>

        <h2 class="text-xl font-semibold mb-2">3) Widerrufsrecht</h2>
        <p class="mb-4">
          Verbrauchern steht grundsätzlich ein Widerrufsrecht zu. Nähere
          Informationen zum Widerrufsrecht ergeben sich aus der
          Widerrufsbelehrung des Anbieters.{" "}
          <Link
            to="../Widerrufsbelehrung"
            className="text-blue-500 hover:text-blue-600"
          >
            Widerrufsbelehrung
          </Link>
        </p>

        <h2 class="text-xl font-semibold mb-2">
          4) Preise und Zahlungsbedingungen
        </h2>
        <p class="mb-4">
          Sofern sich aus der Leistungsbeschreibung des Anbieters nichts anderes
          ergibt, handelt es sich bei den angegebenen Preisen um Gesamtpreise.
          Umsatzsteuer wird nicht ausgewiesen, da der Anbieter Kleinunternehmer
          im Sinne des § 19 Abs. 1 UStG ist.
        </p>

        <h2 class="text-xl font-semibold mb-2">5) Anwendbares Recht</h2>
        <p class="mb-4">
          <b>5.1</b> Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht
          der Bundesrepublik Deutschland unter Ausschluss der Gesetze über den
          internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt diese
          Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende
          Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen
          gewöhnlichen Aufenthalt hat, entzogen wird.
        </p>
        <p class="mb-4">
          <b>5.2</b> Ferner gilt diese Rechtswahl im Hinblick auf das
          gesetzliche Widerrufsrecht nicht bei Verbrauchern, die zum Zeitpunkt
          des Vertragsschlusses keinem Mitgliedstaat der Europäischen Union
          angehören und deren alleiniger Wohnsitz und Lieferadresse zum
          Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union
          liegen.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          6) Alternative Streitbeilegung
        </h2>
        <p class="mb-4">
          <b>6.1</b> Die EU-Kommission stellt im Internet unter folgendem Link
          eine Plattform zur Online-Streitbeilegung bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            class="text-blue-500 underline"
            rel="noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
        </p>
        <p class="mb-4">
          Diese Plattform dient als Anlaufstelle zur außergerichtlichen
          Beilegung von Streitigkeiten aus Online-Kauf- oder
          Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.
        </p>
        <p class="mb-4">
          <b>6.2</b> Der Anbieter ist zur Teilnahme an einem
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          weder verpflichtet noch bereit.
        </p>
      </div>
    </Layout>
  );
}
