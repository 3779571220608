import { Layout } from "./Layout";
import React, { useContext, useRef, useState } from "react";
import { Radar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import analyticsEventLog from "../helperFunctions/AnalyticsEvent";

export function BeispielAuswertung() {
  return (
    <Layout>
      <meta
        name="description"
        content="Finden Sie heraus, welches Studium am besten zu Ihnen passt. Nutzen Sie unseren Persönlichkeitstest, um herauszufinden, welche Studiengänge für Ihre Interessen und Fähigkeiten geeignet sind."
      />
      <meta
        name="keywords"
        content="Was soll ich studieren, Studium Test, welches Studium passt zu mir, Studienwahl, Studiengänge, Persönlichkeitstest"
      />
      <meta
        property="og:title"
        content="Welches Studium passt zu mir? | Studium Test"
      />
      <meta
        property="og:description"
        content="Entdecken Sie passende Studiengänge mit unserem Persönlichkeitstest. Finden Sie heraus, welches Studium Ihren Interessen und Fähigkeiten entspricht."
      />

      <div className="text-primary px-10 p-6 max-w-4xl mx-auto bg-white rounded-lg">
        <header className="text-4xl text-center mt-7">
          <h1>Beispiel Auswertung</h1>
        </header>
        <section className="text-center text-3xl mt-5">
          <h2>Persönlichkeit</h2>
        </section>
        <section className="flex justify-center items-center">
          <RadarChartWeb
            category={"Persönlichkeit"}
            data={{
              Gewissenhaftigkeit: 0.475,
              Neurotizismus: 0.1625,
              Offenheit: 0.4875,
              Verträglichkeit: 0.8125,
              Extrovertiert: 0.5,
            }}
            color={{ r: 255, g: 99, b: 132 }}
          />
        </section>
        <section className="text-justify">
          <p>
            <strong>Verträglichkeit:</strong> Das Spektrum der Verträglichkeit
            im Rahmen der Big 5 reicht von niedrig (weniger kooperativ, manchmal
            konfrontativ) bis hoch (sehr kooperativ und harmoniebedacht). Dein
            Wert von 0.8125 zeigt, dass du tendenziell sehr freundlich,
            mitfühlend und bereit bist, anderen zu helfen. Du legst viel Wert
            auf zwischenmenschliche Harmonie.
          </p>
          <br></br>
          <p>
            <strong>Gewissenhaftigkeit:</strong> Das Spektrum der
            Gewissenhaftigkeit reicht von niedrig (spontan, weniger
            strukturiert) bis hoch (organisiert, zuverlässig). Mit einem Wert
            von 0.475 liegst du im mittleren Bereich. Das bedeutet, dass du eine
            ausgewogene Mischung aus Flexibilität und Verlässlichkeit besitzt.
            Du kannst gut planen, bist aber auch in der Lage, spontan zu
            handeln, wenn nötig.
          </p>
          <br></br>
          <p>
            <strong>Extraversion:</strong> Das Spektrum der Extraversion reicht
            von introvertiert (ruhig, zurückhaltend) bis extrovertiert
            (gesellig, energetisch). Dein Wert von 0.5 deutet darauf hin, dass
            du ausgeglichen bist. Du genießt sowohl soziale Interaktionen als
            auch Zeit für dich selbst. Du findest Freude in Gesellschaft,
            schätzt aber auch Momente der Ruhe.
          </p>
          <br></br>
          <p>
            <strong>Neurotizismus:</strong> Das Spektrum des Neurotizismus
            reicht von emotional stabil (gelassen, stressresistent) bis hoch
            neurotisch (ängstlich, leicht gestresst). Mit einem Wert von 0.1625
            bist du eher emotional stabil. Du gehst gelassen mit Stress um und
            lässt dich nicht leicht aus der Ruhe bringen.
          </p>
          <br></br>
          <p>
            <strong>Offenheit:</strong> Das Spektrum der Offenheit reicht von
            wenig offen (traditionell, weniger fantasievoll) bis sehr offen
            (kreativ, neugierig). Dein Wert von 0.4875 zeigt, dass du moderat
            offen bist. Du hast ein gesundes Interesse an neuen Erfahrungen und
            Ideen, bist aber auch in der Lage, traditionelle Ansichten zu
            schätzen und bewahren.
          </p>
        </section>
        <section className="text-center text-3xl mt-5">
          <h2>Fähigkeiten</h2>
        </section>
        <section className="flex justify-center items-center">
          <RadarChartWeb
            category={"Fähigkeiten"}
            data={{
              Zahlenreihen: 0.9,
              Sprache: 0.4,
              Empathie: 0.4,
              Kreativität: 0.7,
            }}
            color={{ r: 99, g: 132, b: 255 }}
          />
        </section>
        <br></br>
        <section className="text-justify">
          <div className="text-xl">
            <h3>Stärken:</h3>
          </div>
          <br></br>
          <div>
            <strong>1. Numerische Intelligenz (0.9): </strong>
            <span>
              Deine numerischen Fähigkeiten sind hervorragend. Du kannst
              komplexe mathematische Probleme schnell und effizient lösen. Nutze
              diese Stärke in Bereichen wie Datenanalyse, Finanzwesen oder
              Ingenieurwesen.
            </span>
          </div>
          <div>
            <br></br>
            <strong>2. Kreativität (0.7): </strong>
            <span>
              Du besitzt eine gute kreative Ader. Dies kann dir in
              Problemlösungen, Designprojekten oder innovativen Ideenfindungen
              helfen. Versuche, noch mehr kreative Aktivitäten in deinen Alltag
              einzubauen, um diese Fähigkeit weiter zu stärken.
            </span>
          </div>
          <br></br>
          <div className="text-xl">
            <h3>Schwächen:</h3>
          </div>
          <div>
            <br></br>
            <strong>1. Empathische Fähigkeiten (0.4):</strong>
            <span>
              Hier besteht Raum für Verbesserungen. Empathie ist wichtig für
              gute Teamarbeit und Kommunikation. Arbeite daran, aktiver
              zuzuhören und dich in die Perspektiven anderer hineinzuversetzen.
              Übungen wie aktives Zuhören und das Lesen emotionaler Signale
              können hilfreich sein.
            </span>
          </div>
          <div>
            <br></br>
            <strong>2. Sprachliche Fähigkeiten (0.4):</strong>
            <span>
              Da keine spezifischen Ergebnisse vorliegen, ist es ratsam, deine
              Sprachkenntnisse weiter zu evaluieren. Falls du Schwächen
              vermutest, könntest du durch Lesen, Schreiben und mündliche
              Übungen in verschiedenen Sprachen Fortschritte erzielen.
            </span>
          </div>
          <br></br>
          <div className="text-xl">
            <h3>Empfehlungen:</h3>
          </div>
          <br></br>
          <div>
            <span>
              <strong>Nutze deine numerische Intelligenz</strong> in deinem
              beruflichen und privaten Umfeld, um analytische Herausforderungen
              zu meistern.
            </span>
          </div>
          <br></br>
          <div>
            <span>
              <strong>Fördere deine Kreativität</strong> durch regelmäßige
              kreative Tätigkeiten wie Malen, Schreiben oder
              Brainstorming-Sitzungen.
            </span>
          </div>
          <br></br>
          <div>
            <span>
              <strong>Verbessere deine empathischen Fähigkeiten</strong> durch
              sozial engagierte Aktivitäten, Kommunikationsworkshops oder
              interaktive Übungen.
            </span>
          </div>
          <br></br>
          <div>
            <span>
              <strong>
                Prüfe und entwickle deine sprachlichen Fähigkeiten
              </strong>{" "}
              gezielt weiter, indem du entsprechende Kurse belegst oder dich in
              herausfordernde Sprachsituationen begibst.
            </span>
          </div>
          <br></br>
          <div>
            <span>
              Durch die gezielte Arbeit an deinen Schwächen und die optimale
              Nutzung deiner Stärken kannst du dein volles Potenzial entfalten.
            </span>
          </div>
        </section>
        <section className="text-center text-3xl mt-5">
          <h2>Interessen</h2>
        </section>
        <section className="flex justify-center items-center">
          <RadarChartWeb
            category={"Interessen"}
            data={{
              Kreieren: 0.8,
              Helfen: 0.35,
              Analysieren: 0.825,
              Verhandeln: 0.425,
              Argumentieren: 0.425,
              Technik: 0.75,
              Künste: 0.2,
              Gesellschaft: 0.275,
            }}
            color={{ r: 255, g: 204, b: 102 }}
          />
        </section>
        <br></br>
        <section className="text-justify">
          <div>
            <h3 className="text-xl">
              Interessante bzw. Ungewöhnliche Kombinationen
            </h3>
          </div>
          <br></br>
          <div>
            <strong>Hohes Interesse an Analysieren und Kreieren:</strong>
            <br></br>
            <div>
              Diese Kombination ist interessant, weil sie sowohl analytisches
              Denken als auch kreatives Schaffen umfasst. Das bedeutet, dass du
              sowohl ein Faible für Datenanalyse, Problemlösung und logisches
              Denken hast, als auch für kreative Prozesse wie das Erstellen von
              Inhalten oder das Entwickeln neuer Ideen. Diese duale Orientierung
              könnte dich in Bereichen wie Innovationsmanagement,
              Produktentwicklung oder in der Schnittstelle zwischen Technologie
              und Kreativität sehr erfolgreich machen.
            </div>
          </div>
          <br></br>
          <div>
            <strong>
              Technik gepaart mit geringem Interesse an Helfen und Künste:
            </strong>
            <div>
              Diese Kombination deutet darauf hin, dass du dich stärker auf
              technische und praktische Lösungen konzentrierst und weniger an
              sozialen oder künstlerischen Tätigkeiten interessiert bist. Das
              könnte darauf hindeuten, dass du technologische Probleme gerne
              selbstständig und pragmatisch löst, anstatt dich mit sozialen oder
              künstlerischen Aspekten auseinanderzusetzen. Dies ist besonders
              typisch für Berufe im technischen Bereich, die eine geringe
              soziale Interaktion erfordern.
            </div>
          </div>
          <br></br>
          <div>
            <strong>
              Mittleres Interesse an Naturwissenschaften bei hohem Interesse an
              Analysieren:
            </strong>
            <div>
              Diese Kombination zeigt, dass du analytische Fähigkeiten schätzt,
              jedoch weniger Interesse an spezifischen naturwissenschaftlichen
              Inhalten hast. Dies könnte bedeuten, dass du analytische
              Tätigkeiten in anderen Kontexten als den traditionellen
              Naturwissenschaften bevorzugst, wie z.B. in der Datenanalyse,
              Wirtschaftsanalysen oder in der Technik.
            </div>
          </div>
          <br></br>
          <div>
            <strong>
              Geringes Interesse an Gesellschaft bei hohem Interesse an
              Analysieren und Kreieren:
            </strong>
            <div>
              Diese Kombination könnte darauf hinweisen, dass du lieber
              unabhängig arbeitest und weniger an sozialen Interaktionen
              interessiert bist. Du könntest ein starkes Bedürfnis nach
              kreativer Freiheit und intellektueller Herausforderung haben, ohne
              dich unbedingt in sozialen oder gemeinschaftlichen Kontexten
              wohlzufühlen.
            </div>
          </div>
          <br></br>
          <div>
            Dein Profil zeigt eine starke Neigung zu analytischen und kreativen
            Tätigkeiten, wobei technische Themen ebenfalls eine wichtige Rolle
            spielen. Die Kombination aus hohen und mittleren Interessen in
            diesen Bereichen mit geringer Neigung zu sozialen und künstlerischen
            Tätigkeiten ergibt ein spezifisches Profil, das besonders für
            technische, analytische und innovative Rollen geeignet ist. Die eher
            zu erwartenden Kombinationen in deinem Profil unterstützen diese
            Tendenzen und verdeutlichen eine klare Orientierung hin zu
            technischen und analytischen Berufen.
          </div>
        </section>
        <section className="text-center text-3xl my-10">
          <h2>Studiengänge</h2>
        </section>
        <section className="text-justify">
          <div className="text-xl">
            <h3>1. Wirtschaftsingenieurwesen</h3>
          </div>
          <br></br>
          <div>
            <strong>Begründung:</strong> Wirtschaftsingenieurwesen kombiniert
            technische Kenntnisse mit wirtschaftlichem Know-how, eine ideale
            Wahl für jemanden mit starken analytischen Fähigkeiten und Interesse
            an Computer und Maschinen.
          </div>
          <br></br>
          <div>
            <strong>Persönlichkeit:</strong> Deine moderate Offenheit (0.4875)
            zeigt, dass du bereit bist, neue Ideen zu erkunden, während deine
            hohe Verträglichkeit (0.8125) gut in Teamarbeit und Führung passt.
            Deine niedrige Neurotizismus-Werte (0.1625) deuten auf eine stabile
            Persönlichkeit hin, was in stressigen Projekten von Vorteil ist.
          </div>
          <br></br>
          <div>
            <strong>Fähigkeiten:</strong> Deine numerische Intelligenz (0.9) ist
            ein großer Vorteil im Bereich Ingenieurwesen und Betriebswirtschaft.
            Kreativität (0.7) hilft dir, innovative Lösungen zu entwickeln.
          </div>
          <br></br>
          <div>
            <strong>Interessen:</strong> Hohe Werte bei analytischen Tätigkeiten
            (0.825) und Computer und Maschinen (0.75) passen perfekt zum
            technischen Teil des Studiums. Dein Interesse am Gestalten und
            Designen (0.8) kann ebenfalls von Vorteil sein, besonders im
            Produktdesign und Projektmanagement.
          </div>
          <br></br>
          <div>
            <strong>Prioritäten:</strong> Eine gewisse Gewissenhaftigkeit
            (0.475) ist nützlich, auch wenn hier Verbesserungspotenzial besteht.
            Deine Fähigkeit zu koordinieren und organisieren (0.4) könnte durch
            das Studium gestärkt werden.
          </div>
          <br></br>
          <div>
            <strong>Inhalte des Studiums:</strong> Das Studium umfasst
            Mathematik, Physik, Informatik, Betriebswirtschaftslehre,
            Produktionsmanagement und Innovationsmanagement. Praktika und
            Projekte sind ebenfalls Bestandteil des Studiums.
          </div>
          <br></br>
          <div>
            <strong>Berufsfelder:</strong>
            <ul>
              <li>
                Produktionsmanager: Überwacht und optimiert die
                Fertigungsprozesse.
              </li>
              <li>
                Logistikmanager: Koordiniert den Fluss von Waren und
                Dienstleistungen.
              </li>
              <li>
                Technischer Vertrieb: Verbindet technisch komplexe Produkte und
                Lösungen mit den Bedürfnissen der Kunden.
              </li>
            </ul>
          </div>
          <br></br>
          <div className="text-xl">
            <h3>2. Psychologie</h3>
          </div>
          <br></br>
          <div>
            <strong>Begründung:</strong> Psychologie erfordert analytisches
            Denken und eine hohe Verträglichkeit, um menschliches Verhalten zu
            verstehen und zu analysieren.
          </div>
          <br></br>
          <div>
            <strong>Persönlichkeit:</strong> Hohe Verträglichkeit (0.8125)
            deutet auf Empathie und die Fähigkeit, gut mit Menschen zu arbeiten.
            Niedriger Neurotizismus (0.1625) hilft dabei, emotionale Stabilität
            zu bewahren. Moderate Extraversion (0.5) unterstützt beim Umgang mit
            Klienten und in therapeutischen Situationen.
          </div>
          <br></br>
          <div>
            <strong>Fähigkeiten:</strong> Kreativität (0.7) ist wertvoll in der
            Entwicklung neuer Therapiemethoden. Auch wenn empathische
            Fähigkeiten (0.4) etwas niedriger sind, können diese durch das
            Studium weiterentwickelt werden.
          </div>
          <br></br>
          <div>
            <strong>Interessen:</strong> Analytische Tätigkeiten (0.825) sind
            zentral in der Psychologie, sei es in der Forschung oder Diagnostik.
            Das moderate Interesse an Gesellschaft (0.275) kann durch das
            Studium und praktische Erfahrungen gesteigert werden.
          </div>
          <br></br>
          <div>
            <strong>Prioritäten:</strong> Durch das Studium wird deine
            Gewissenhaftigkeit (0.475) sicherlich zunehmen, was wichtig für
            wissenschaftliche Genauigkeit und Ethik ist.
          </div>
          <br></br>
          <div>
            <strong>Inhalte des Studiums:</strong> Das Studium deckt
            Entwicklungspsychologie, klinische Psychologie, Sozialpsychologie,
            Statistik und Forschungsmethodik ab. Praktika und Fallstudien sind
            ebenfalls Teil des Programms.
          </div>
          <br></br>
          <div>
            <strong>Berufsfelder:</strong>
            <ul>
              <li>
                Klinischer Psychologe: Diagnose und Behandlung psychischer
                Störungen.
              </li>
              <li>
                Organisationspsychologe: Verbesserung der Arbeitsbedingungen und
                Effizienz in Unternehmen.
              </li>
              <li>
                Schulpsychologe: Unterstützung von Schülern bei Lern- und
                Verhaltensproblemen.
              </li>
            </ul>
          </div>
          <br></br>
          <div className="text-xl">
            <h3>3. Biotechnologie</h3>
          </div>
          <br></br>
          <div>
            <strong>Begründung:</strong> Biotechnologie verbindet
            Naturwissenschaften mit Technik, ideal für jemanden mit hohen
            analytischen Fähigkeiten und Interesse an naturwissenschaftlichen
            sowie technischen Themen.
          </div>
          <br></br>
          <div>
            <strong>Persönlichkeit:</strong> Moderate Offenheit (0.4875) und
            geringer Neurotizismus (0.1625) sind vorteilhaft für die Arbeit in
            einem dynamischen und potenziell stressigen Umfeld. Hohe
            Verträglichkeit (0.8125) fördert Teamarbeit und Zusammenarbeit.
          </div>
          <br></br>
          <div>
            <strong>Fähigkeiten:</strong> Numerische Intelligenz (0.9) ist
            entscheidend für das Verständnis komplexer biologischer Systeme und
            biostatistischer Analysen. Kreativität (0.7) fördert innovative
            Ansätze in der Forschung und Entwicklung.
          </div>
          <br></br>
          <div>
            <strong>Interessen:</strong> Großes Interesse an analytischen
            Tätigkeiten (0.825), Computer und Maschinen (0.75) sowie
            Naturwissenschaften (0.5) passt hervorragend zur Biotechnologie, die
            alle diese Bereiche miteinander verknüpft.
          </div>
          <br></br>
          <div>
            <strong>Prioritäten:</strong> Deine derzeit moderate
            Gewissenhaftigkeit (0.475) wird durch die Anforderungen des Studiums
            verbessert, da präzises Arbeiten und akkurate Dokumentation
            essentiell sind.
          </div>
          <br></br>
          <div>
            <strong>Inhalte des Studiums:</strong> Das Studium umfasst
            Biochemie, Molekularbiologie, Genetik, Bioinformatik,
            Prozessengineering und Labormethoden. Praktika und Projektarbeiten
            in Laboren sind ebenfalls enthalten.
          </div>
          <br></br>
          <div>
            <strong>Berufsfelder:</strong>
            <ul>
              <li>
                Forschungswissenschaftler: Durchführung von Experimenten und
                Entwicklung neuer biotechnologischer Produkte.
              </li>
              <li>
                Bioprozessingenieur: Optimierung von Produktionsprozessen in der
                pharmazeutischen oder Lebensmittelindustrie.
              </li>
              <li>
                Qualitätssicherung: Sicherstellung der Einhaltung von
                Qualitätsstandards in der Produktion von biotechnologischen
                Produkten.
              </li>
            </ul>
          </div>
        </section>
        <br></br>
        <section className="text-center text-3xl my-10">
          <h2>Studiengänge für eine passende Karriere</h2>
        </section>
        <br></br>
        <section className="text-justify">
          <div className="text-xl">
            <h3>1. Data Scientist</h3>
          </div>
          <br></br>
          <div>
            <strong>Begründung:</strong> Ein Data Scientist nutzt
            fortschrittliche Analysetechniken und maschinelles Lernen, um Daten
            auszuwerten und daraus wertvolle Erkenntnisse zu gewinnen. Aufgrund
            deiner hohen numerischen Intelligenz und analytischen Fähigkeiten
            bist du für diese Rolle hervorragend geeignet.
          </div>
          <br></br>
          <div>
            <strong>Persönlichkeit:</strong> Deine geringe Neurotizismus-Werte
            deuten darauf hin, dass du stressresistent und stabil bleibst, was
            in der oft anspruchsvollen und komplexen Arbeit eines Data
            Scientists von Vorteil ist. Deine moderate Offenheit und
            Gewissenhaftigkeit helfen dabei, kreativ zu denken und gleichzeitig
            strukturiert zu arbeiten.
          </div>
          <br></br>
          <div>
            <strong>Fähigkeiten:</strong> Deine extrem hohe numerische
            Intelligenz (0.9) ist die wichtigste Fähigkeit für einen Data
            Scientist. Auch deine Kreativität (0.7) kann dir helfen, innovative
            Lösungen für Datenprobleme zu entwickeln.
          </div>
          <br></br>
          <div>
            <strong>Interessen:</strong> Du hast ein starkes Interesse an
            analytischen Tätigkeiten (0.825) und Technik (0.75), was perfekt zu
            den Anforderungen dieses Berufs passt.
          </div>
          <br></br>
          <div>
            <strong>Prioritäten:</strong> Ein Data Scientist hat oft die
            Freiheit, flexible Arbeitszeiten zu wählen und remote zu arbeiten,
            was deinem hohen Wert für Freiheit (0.75) entspricht. Zudem ist das
            Einkommen in dieser Branche sehr gut, passend zu deinem höchsten
            Prioritätswert Einkommen (1).
          </div>
          <br></br>
          <div>
            <strong>
              Erkläre auch welche Teile meines Profils weniger zu dem Studium
              passen:
            </strong>{" "}
            Dein relativ niedriger Wert bei Koordinieren (0.4) und Verhandeln
            (0.425) deutet darauf hin, dass du eher im Hintergrund analytisch
            arbeitest, als direkt in Teams zu führen oder zu verhandeln, was
            jedoch durch die Natur des Berufs ausgeglichen wird.
          </div>
          <br></br>
          <div>
            <strong>Inhalte des Berufs:</strong> Typische Aufgaben sind das
            Sammeln, Bereinigen und Analysieren großer Datensätze, Erstellen und
            Testen von Algorithmen und Modellen, sowie das Präsentieren von
            Datenanalysen und Handlungsempfehlungen.
          </div>
          <div className="bg-orange-200 border-2 border-accent bg-oppacity-30 p-5 rounded-2xl my-5">
            <strong>Studiengänge:</strong>
            <ul>
              <li>Data Science</li>
              <li>Informatik</li>
              <li>Statistik</li>
            </ul>
          </div>
          <div className="text-xl">
            <h3>2. Ingenieur für künstliche Intelligenz</h3>
          </div>
          <br></br>
          <div>
            <strong>Begründung:</strong> Dieser Beruf erfordert ein tiefes
            technisches Verständnis und die Fähigkeit, innovative technische
            Lösungen zu entwickeln. Deine Interessen und Fähigkeiten passen gut
            zu diesem herausfordernden und zukunftsorientierten Bereich.
          </div>
          <br></br>
          <div>
            <strong>Persönlichkeit:</strong> Deine moderate Extraversion (0.5)
            hilft dir dabei, in einem Team zu arbeiten, während deine geringe
            Neurotizismus-Werte sicherstellen, dass du auch unter Druck ruhig
            und effektiv bleiben kannst.
          </div>
          <br></br>
          <div>
            <strong>Fähigkeiten:</strong> Mit hoher numerischer Intelligenz
            (0.9) und Kreativität (0.7) bist du bestens ausgestattet, um
            komplexe technische Probleme zu lösen und neue KI-Modelle zu
            entwickeln.
          </div>
          <br></br>
          <div>
            <strong>Interessen:</strong> Dein starkes Interesse an Technik
            (0.75) und analytischen Tätigkeiten (0.825) macht diesen Beruf
            besonders attraktiv für dich.
          </div>
          <br></br>
          <div>
            <strong>Prioritäten:</strong> Die Freiheit in der Forschung und
            Entwicklung sowie ein hohes Einkommen in diesem Feld entsprechen
            deinen Prioritäten Freiheit (0.75) und Einkommen (1).
          </div>
          <br></br>
          <div>
            <strong>
              Erkläre auch welche Teile meines Profils weniger zu dem Studium
              passen:
            </strong>{" "}
            Dein niedriger Wert für Kundenkontakt (0) bedeutet, dass du
            wahrscheinlich direkte Interaktionen mit Kunden vermeiden möchtest.
            Das könnte eine Herausforderung sein, wenn Projekte direkten
            Kundenkontakt erfordern.
          </div>
          <br></br>
          <div>
            <strong>Inhalte des Berufs:</strong> Du arbeitest an der Entwicklung
            und Optimierung von KI-Systemen, modellierst neuronale Netze,
            programmierst Algorithmen und setzt Machine Learning-Techniken ein.
          </div>
          <br></br>
          <div className="bg-orange-200 border-2 border-accent bg-oppacity-30 p-5 rounded-2xl my-5">
            <strong>Studiengänge:</strong>
            <ul>
              <li>Künstliche Intelligenz</li>
              <li>Computer Engineering</li>
              <li>Elektrotechnik</li>
            </ul>
          </div>
          <div className="text-xl">
            <h3>3. User Experience (UX) Designer</h3>
          </div>
          <br></br>
          <div>
            <strong>Begründung:</strong> Als UX-Designer gestaltest du
            benutzerfreundliche Oberflächen und Anwendungen. Deine kreative
            Seite und dein Interesse am Gestalten und Designen machen diesen
            Beruf besonders passend für dich.
          </div>
          <br></br>
          <div>
            <strong>Persönlichkeit:</strong> Deine Verträglichkeit (0.8125)
            ermöglicht es dir, effektiv mit verschiedenen Stakeholdern
            zusammenzuarbeiten. Deine moderate Offenheit (0.4875) hilft dir,
            neue Ideen zu generieren und diese umzusetzen.
          </div>
          <br></br>
          <div>
            <strong>Fähigkeiten:</strong> Deine Kreativität (0.7) ist essentiell
            für die Gestaltung ansprechender und funktionaler
            Benutzeroberflächen.
          </div>
          <br></br>
          <div>
            <strong>Interessen:</strong> Mit einem starken Interesse am
            Gestalten und Designen (0.8) sowie einer technischen Affinität
            (0.75) findest du den richtigen Mix aus Kreativität und Technologie
            in diesem Beruf.
          </div>
          <br></br>
          <div>
            <strong>Prioritäten:</strong> Die Freiheit in kreativen Prozessen
            und ein gutes Einkommen entsprechen deinen Prioritäten Freiheit
            (0.75) und Einkommen (1). Da User Experience Design oft
            projektbasiert ist, kannst du deine Arbeitsweise flexibel gestalten.
          </div>
          <br></br>
          <div>
            <strong>
              Erkläre auch welche Teile meines Profils weniger zu dem Studium
              passen:
            </strong>{" "}
            Dein geringer Wert für Kundenkontakt (0) könnte ein Nachteil sein,
            da UX-Designer häufig Nutzerinterviews führen müssen. Dies könnte
            jedoch durch den Fokus auf interne Tests und Analysen kompensiert
            werden.
          </div>
          <br></br>
          <div>
            <strong>Inhalte des Berufs:</strong> Du gestaltest Wireframes,
            Prototypen und Layouts, führst Usability-Tests durch, analysierst
            Nutzerverhalten und arbeitest eng mit Entwicklern und Designern
            zusammen, um optimale Benutzererfahrungen zu schaffen.
          </div>
          <br></br>
          <div className="bg-orange-200 border-2 border-accent bg-oppacity-30 p-5 rounded-2xl my-5">
            <strong>Studiengänge:</strong>
            <ul>
              <li>Interaction Design</li>
              <li>Kommunikationsdesign</li>
              <li>Medieninformatik</li>
            </ul>
          </div>
        </section>
        <div className="text-center pt-7">
          <div className="btn btn-primary text-white rounded-3xl shadow-lg font-bold px-16 mx-auto">
            <Link
              to="/overview"
              onClick={() => analyticsEventLog("bspToOverview_button")}
            >
              Zu den Tests
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const RadarChartWeb = ({ category, data, color }) => {
  const chartStyle = { width: "600px" };
  const chartRef = useRef();

  const backgroundColor = `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)`;
  const borderColor = `rgb(${color.r}, ${color.g}, ${color.b})`;

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: category,
        data: Object.values(data),
        fill: true,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        pointBackgroundColor: borderColor,
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: borderColor,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true,
          lineWidth: 3,
        },
        border: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 1,
        ticks: {
          stepSize: 0.2,
          backdropColor: "transparent",
          font: {
            size: 10,
          },
        },
        pointLabels: {
          font: {
            size: 12,
            weight: "normal",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 800,
      easing: "easeOut",
      onProgress: function (animation) {
        console.log(`Current step: ${animation.currentStep}`);
      },
      onComplete: function () {
        console.log("Radar chart animation complete");
      },
    },
  };

  return (
    <div ref={chartRef} style={chartStyle} id="test">
      <Radar data={chartData} options={chartOptions} />
    </div>
  );
};
