import { Layout } from "./Layout";
import { useEffect } from "react";

export function Widerrufsbelehrung() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Widerrufsbelehrung - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  return (
    <Layout pageTitle={"Widerrufsbelehrung"}>
      <div className="text-primary p-8">
        <h1>Widerrufsbelehrung (Dienstleistungen) &amp; Widerrufsformular</h1>

        <p>
          Verbrauchern steht ein Widerrufsrecht nach folgender Ma&szlig;gabe zu,
          wobei Verbraucher jede nat&uuml;rliche Person ist, die ein
          Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t, die &uuml;berwiegend
          weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen
          T&auml;tigkeit zugerechnet werden k&ouml;nnen:
        </p>

        <h2>A. Widerrufsbelehrung (Dienstleistungen)</h2>

        <h3>Widerrufsrecht</h3>

        <p>
          Sie haben das Recht, binnen 30 Tage ohne Angabe von Gr&uuml;nden
          diesen Vertrag zu widerrufen.
        </p>

        <p>
          Die Widerrufsfrist betr&auml;gt 30 Tage ab dem Tag des
          Vertragsabschlusses.
        </p>

        <p>
          Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns (Futedu GbR,
          Stieglitzstr. 8, 04229 Leipzig, Deutschland, Tel.: 01729647870,
          E-Mail: info@futedu.de) mittels einer eindeutigen Erkl&auml;rung (z.
          B. ein mit der Post versandter Brief oder E-Mail) &uuml;ber Ihren
          Entschluss, diesen Vertrag zu widerrufen, informieren.
        </p>

        <p>
          Sie k&ouml;nnen daf&uuml;r das beigef&uuml;gte
          Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben
          ist.
        </p>

        <p>
          Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
          &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor Ablauf der
          Widerrufsfrist absenden.
        </p>

        <h3>Folgen des Widerrufs</h3>

        <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
          die wir von Ihnen erhalten haben, einschlie&szlig;lich der
          Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich
          daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns
          angebotene, g&uuml;nstigste Standardlieferung gew&auml;hlt haben),
          unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag
          zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf
          dieses Vertrags bei uns eingegangen ist. F&uuml;r diese
          R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei
          der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit
          Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall
          werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet.
        </p>

        <p>
          Haben Sie verlangt, dass die Dienstleistungen w&auml;hrend der
          Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen
          Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie
          uns von der Aus&uuml;bung des Widerrufsrechts hinsichtlich dieses
          Vertrags unterrichten, bereits erbrachten Dienstleistungen im
          Vergleich zum Gesamtumfang der im Vertrag vorgesehenen
          Dienstleistungen entspricht.
        </p>

        <h3>Ausschluss bzw. vorzeitiges Erl&ouml;schen des Widerrufsrechts</h3>

        <p>
          Das Widerrufsrecht erlischt vorzeitig, wenn wir die Dienstleistung
          vollst&auml;ndig erbracht haben und wir mit der Ausf&uuml;hrung der
          Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre
          ausdr&uuml;ckliche Zustimmung gegeben haben und gleichzeitig Ihre
          Kenntnis davon best&auml;tigt haben, dass Sie Ihr Widerrufsrecht bei
          vollst&auml;ndiger Vertragserf&uuml;llung durch uns verlieren.
        </p>

        <h2>B. Widerrufsformular</h2>

        <p>
          Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie bitte
          dieses Formular aus und senden es zur&uuml;ck.
        </p>

        <p>An</p>

        <p>
          Futedu GbR
          <br />
          Stieglitzstr. 8<br />
          04229 Leipzig
          <br />
          Deutschland
        </p>

        <p>E-Mail: info@futedu.de</p>

        <p>
          Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
          Vertrag &uuml;ber den Kauf der folgenden Waren (*)/die Erbringung der
          folgenden Dienstleistung (*)
        </p>

        <p>_______________________________________________________</p>

        <p>_______________________________________________________</p>

        <p>Bestellt am (*) ____________ / erhalten am (*) __________________</p>

        <p>
          ________________________________________________________
          <br />
          Name des/der Verbraucher(s)
        </p>

        <p>
          ________________________________________________________
          <br />
          Anschrift des/der Verbraucher(s)
        </p>

        <p>
          ________________________________________________________
          <br />
          Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        </p>

        <p>
          _________________________
          <br />
          Datum
        </p>

        <p>(*) Unzutreffendes streichen</p>
      </div>
    </Layout>
  );
}
