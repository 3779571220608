import React, { useState, useEffect, useRef } from "react";
import { Layout } from "./Layout";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import "./Kreativität.css";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

async function triggerKreativiteatCloudFunction(prompt, userID) {
  try {
    // Correctly target the 'prompts' subcollection for the given user
    const promptsCollectionRef = collection(db, `users/${userID}/messages`);
    const docRef = await addDoc(promptsCollectionRef, {
      prompt: prompt,
      status: { state: null },
      type: "Kreativität",
    });
    console.log("Document written with ID: ", docRef);
    return docRef; // Return the DocumentReference for further use if needed
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // Rethrow the error for handling by the caller
  }
}

//check for response in data base. returns all responses if true
export async function getKreativiteatResult(userID) {
  // Reference to the user's messages subcollection
  const messagesRef = collection(db, `users/${userID}/messages`);

  // Create a query that looks for messages where the 'response' field exists
  const q = query(
    messagesRef,
    where("response", "!=", null),
    where("type", "==", "Kreativität")
  );

  try {
    // Execute the query
    const querySnapshot = await getDocs(q);
    const responses = [];

    querySnapshot.forEach((doc) => {
      // For each document, add its 'response' field value to the 'responses' array
      responses.push(doc.data().response);
    });

    // Check if we found any responses
    if (responses.length > 0) {
      console.log("Found messages with responses:", parseFloat(responses[0]));
      const userDocRef = doc(db, "users", userID);
      // Update the document
      await updateDoc(userDocRef, {
        Kreativität: parseFloat(responses[0]),
      });
      return responses; // Return an array of response values
    } else {
      console.log("No messages with responses found.");
      return null; // Return null to indicate no responses were found
    }
  } catch (error) {
    console.error("Error fetching messages with responses:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export async function addPlaceholderValue(userID) {
  const userDocRef = doc(db, "users", userID);
  // Update the document
  await updateDoc(userDocRef, {
    Kreativität: 0.5,
  });
}

// Your async functions here

export function Kreativiteat() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Kreativitätstest - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [isReady, setIsReady] = useState(false);
  const [timer, setTimer] = useState(60);
  const [inputValue, setInputValue] = useState("");
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();
  const hasTriggered = useRef(false); // useRef to track if the cloud function has been triggered

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("signed in");
        setUid(user.uid);
      } else {
        console.log("not signed in");
        navigate("/registrieren");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    let interval;
    if (isReady) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            if (!hasTriggered.current && uid) {
              console.log(inputValue); // This should now log the updated inputValue
              addPlaceholderValue(uid);
              hasTriggered.current = true; // Update the ref to indicate the function has been triggered
              triggerKreativiteatCloudFunction(
                `Ich habe einen Alternativenverwendungstest gemacht. 'Was kann man mit einem Pappbecher machen?'. Ich hatte dafür 1 Minuten Zeit. Bitte bewerte folgende Antworten hinsichtlich quantität und kreativität. Bewerte die antwort mit einem score zwischen 0 und 1. gibt mir nur die Zahl zurück, zum Beispiel: '0.32'. 

                Das hier ist eine 0.8:
                
                als hut aufsetzen
                
                darin eis herstellen
                
                ihn als schnurr telefon verwenden
                
                darin stifte aufbewahren
                
                als feueranzünder verwenden
                
                als kleine trommel nehmen
                
                daraus eine schaukel für hamster bauen
                
                damit das hütchenspiel spielen 
                
                eine pyramide zum dosenwerfen bauen
                
                bierpong spielen
                
                beeren sammeln 
                
                den boden heraus nehmen und so tun, als wäre es ein fernrohr
                
                darauf eine liebesbotschaft schreiben und ihn einem hübschen mädchen übergeben
                
                damit eine spinne einfangen
                
                ihn künstlerisch bemalen und teuer verkaufen
                
                Hier sind meine Ideen: ` + inputValue,
                uid
              )
                .then(() => navigate("/overview")) // Correctly passing a function to `.then()`
                .catch((error) =>
                  console.error("Error in trigger function:", error)
                );
            }
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isReady, uid, inputValue]); // Include inputValue in the dependencies array

  const handleReadyClick = () => {
    setIsReady(true);
    setTimer(60); // Reset timer whenever ready is clicked
    hasTriggered.current = false; // Reset trigger check
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt
  return (
    <Layout pageTitle="Kreativität">
      <div className="kreativ-container bg-neutral p-16">
        <div className={`${isReady ? "" : "hidden"}`}>
          <div
            className="text-5xl text-primary font-bold p-5"
            id="timerDisplay"
          >
            {timer}
          </div>
        </div>

        <div id="aufgabe" style={{ display: isReady ? "block" : "none" }}>
          <div className="text-primary text-center rounded-xl font-semibold bg-white px-8 py-5 m-3">
            <p>
              Was kann man alles mit einem Pappbecher machen?
              <br />
              Sei kreativ!
            </p>
          </div>
        </div>

        <div
          className="kreativ-input"
          style={{ display: isReady ? "block" : "none" }}
        >
          <textarea
            disabled={!isReady}
            id="my-input"
            name="name"
            rows="5"
            className="bg-white border border-primary rounded p-1 text-black"
            style={{
              width: "100%",
              height: "200px",
            }}
            placeholder={`Meine Ideen sind...\n-\n-\n-`}
            value={inputValue}
            onChange={handleChange}
          />
        </div>

        <div id="aufgabe" style={{ display: isReady ? "none" : "block" }}>
          <div className="info-text max-w-2xl">
            <p>
              Du wirst gleich gebeten verschiedene Verwendungen für einen
              Gegenstand zu liefern.
              <br />
              <br />
            </p>
            <p>
              <strong>
                Beispiel: "Was kannst du alles mit einem Backstein machen?"
              </strong>
            </p>
            <ul>
              <li> - mich drauf setzen</li>
              <li> - ihn als hammer verwenden</li>
              <li> - daraus eine Skulptur formen</li>
            </ul>
            <br />
            <p>
              Du hast dafür <strong>60 Sekunden</strong> Zeit. Der Timer stoppt,
              während du tippst, damit es nicht auf deine Schreibgeschwindigkeit
              ankommt.
              <br />
              <br /> Sobald du auf den Knopf drückst, geht es los und du wirst
              danach automatisch zu den Tests zurück gebracht. (Bis der Test
              dann als erledigt markiert wird, kann es einen Moment dauern)
            </p>
            <p style={{ textAlign: "center" }}>
              <strong>Viel Erfolg!</strong>
            </p>
          </div>
        </div>

        {!isReady && (
          <div className="pt-5">
            <button
              id="bereit"
              className="btn btn-accent font-bold px-10"
              onClick={handleReadyClick}
            >
              {" "}
              Bereit{" "}
            </button>
          </div>
        )}
      </div>
    </Layout>
  );
}
