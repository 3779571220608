import React, { useState, useEffect } from "react";
import { Layout } from "./Layout";
import { db, auth } from "../firebase/firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Slider.css";
import "./Layout.css";

export function Button() {
  let uid;
  const navigate = useNavigate();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      uid = user.uid;
    } else {
      //not signed in
      return navigate("/registrieren");
    }
  });
  //const [uid, setUid] = useState(''); // Initialize uid state with empty string
  // Define state to manage slider values
  const [sliderValues, setSliderValues] = useState({
    Koordinieren: 0,
    Kreieren: 0,
    Helfen: 0,
    Analysieren: 0,
    Verhandeln: 0,
    Technik: 0,
    Künste: 0,
    Gesellschaft: 0,
    Naturwissenschaften: 0,
  });

  const handleButtonClick = () => {
    const sliders = document.querySelectorAll('input[type="range"]');

    // Loop through each slider and collect its value
    const sums = Array.from(sliders).reduce((acc, slider, index) => {
      const sum = acc[Math.floor(index / 8)] || 0;
      acc[Math.floor(index / 8)] = sum + parseInt(slider.value);
      return acc;
    }, []);

    const docData = {
      Koordinieren: sums[0] / 40,
      Kreieren: sums[1] / 40,
      Helfen: sums[2] / 40,
      Analysieren: sums[3] / 40,
      Verhandeln: sums[4] / 40,
      Technik: sums[5] / 40,
      Künste: sums[6] / 40,
      Gesellschaft: sums[7] / 40,
      Naturwissenschaften: sums[8] / 40,
    };

    setDoc(
      doc(db, "users", uid),
      {
        ...docData,
      },
      {
        merge: true,
      }
    )
      .then(() => {
        console.log("User added to Firestore");
        return navigate("../Overview");
      })
      .catch((error) => {
        console.log("Error adding user to Firestore:", error);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt
  return (
    <div className="text-center">
      {/* Your JSX for rendering sliders and button */}
      <button
        className="btn btn-accent rounded-3xl shadow-lg font-bold px-16"
        id="persButton"
        onClick={handleButtonClick}
      >
        Fertig
      </button>
    </div>
  );
}

function InteressenCard({ text }) {
  const [value, setValue] = useState(3);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className="flex flex-col justify-between bg-primary my-10 gap-5 py-10 rounded-xl ml-2 mr-2">
      <p className="pb-14 px-3 text-center text-2xl md:text-3xl">{text}</p>
      <div className="flex flex-col px-5 md:px-10">
        <div className="flex items-center justify-between pb-2 md:pb-3 font-semibold">
          <div className="text-sm">Stimmt nicht</div>
          <div className="text-sm">Stimmt sehr</div>
        </div>
        <input
          id="interest-range"
          className="range range-md range-accent"
          max="5"
          min="1"
          step="1"
          type="range"
          value={value}
          onChange={handleChange}
        />

        <div className="w-full flex justify-between text-xs mt-3">
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </div>
      </div>
    </div>
  );
}

function InteressenContainer() {
  return (
    <div className="py-10 max-w-4xl mx-auto" style={{ align: "center" }}>
      <InteressenCard text="Ich habe Spaß daran Veranstaltungen und Partys zu planen und zu organisieren." />
      <InteressenCard text="Bei Gruppenarbeiten kümmere ich mich darum, Aufgaben zu verteilen und dass alle Fristen eingehalten werden." />
      <InteressenCard text="Es bereitet mir Freude, die Logistik für komplexe Abläufe zu koordinieren und Probleme proaktiv zu lösen." />
      <InteressenCard text="Ich bin gut darin, mehrere Aufgaben gleichzeitig zu überblicken und Prioritäten zu setzen, um effiziente Ergebnisse zu erzielen." />
      <InteressenCard text="Die Koordination und Kommunikation zwischen verschiedenen Personen oder Gruppen ist eine Herausforderung, die ich gerne annehme." />
      <InteressenCard text="Ich habe kein Problem damit lange E-Mails zu schreiben und Telefonate zu führen, bis jedes Missverständnis geklärt ist." />
      <InteressenCard text="Ich bin immer up to date und vergesse nie wichtige Termine, wie z.B. Deadlines oder die Geburtstage meiner Freunde." />
      <InteressenCard text="Es macht mir Spaß alle Flüge, Unterkünfte, Mietautos und Aktivitäten für den Urlaub zu buchen." />

      <InteressenCard text="Ich fühle mich inspiriert, wenn es darum geht, neue Ideen zu entwickeln und kreative Lösungen zu finden." />
      <InteressenCard text="Das Gestalten von etwas Neuem, seien es Kunstwerke, Produkte oder Konzepte, ist für mich eine erfüllende Tätigkeit." />
      <InteressenCard text="Ich interessiere mich für die Ästhetik von Formen, Farben und Layouts." />
      <InteressenCard text="Grafikdesign und visuelle Kommunikation faszinieren mich." />
      <InteressenCard text="Ich fühle mich wohl dabei, Risiken einzugehen und neue Wege auszuprobieren, um meine kreativen Ideen umzusetzen. Nur weil es ungewöhnlich ist, ist es nicht falsch." />
      <InteressenCard text="Ich interessiere mich für die Gestaltung von Räumen und deren Atmosphäre." />
      <InteressenCard text="Ich möchte wissen, wie man Designsoftware wie Adobe Creative Suite oder ähnliche Programme nutzt." />
      <InteressenCard text="In meiner Freizeit gehe ich gerne und häufig kreativen Tätigkeiten nach." />

      <InteressenCard text="Es erfüllt mich, anderen Menschen in schwierigen Situationen zur Seite zu stehen und Unterstützung anzubieten." />
      <InteressenCard text="Wenn ich anderen helfe ihre Ziele zu erreichen, freue ich mich mehr, als über das Erreichen meiner eigenen Ziele." />
      <InteressenCard text="Die Möglichkeit, das Leben anderer positiv zu beeinflussen, ist für mich ein wichtiger Motivationsfaktor." />
      <InteressenCard text="Ich sehe es als meine Aufgabe, Menschen, die es im Leben schwer haben, zu unterstützen." />
      <InteressenCard text="Auch wenn eine Person an ihrem Problem selbst Schuld ist, hat sie viel Hilfe verdient." />
      <InteressenCard text="Ich stelle oftmals das Glück anderer Personen über mein eigenes." />
      <InteressenCard text="Auch wenn ich für meine Hilfe keinen Dank bekomme, bin ich trotzdem sehr motiviert mein Bestes zu geben." />
      <InteressenCard text="Wenn jemand meine Tasche klaut, aber sich beim Abhauen verletzt, würde ich ihn dennoch best möglich versorgen." />

      <InteressenCard text="Ich finde Freude darin, komplizierte Probleme zu untersuchen und nach Lösungen zu suchen." />
      <InteressenCard text="Das Sammeln und Auswerten von Daten, um Muster und Trends zu identifizieren, fasziniert mich." />
      <InteressenCard text="Ich habe Spaß daran, verschiedene Interessen und Standpunkte zu analysieren und darauf basierend Handlungsstrategien zu entwickeln." />
      <InteressenCard text="Die Analyse von Informationen und Daten, um fundierte Entscheidungen zu treffen oder Prognosen zu erstellen, ist der beste Weg." />
      <InteressenCard text="Ich bin gut darin, komplexe Sachverhalte zu durchdringen und verstehe es, logische Schlussfolgerungen aus verschiedenen Informationen zu ziehen." />
      <InteressenCard text="Ich lasse mich nicht von viel Text oder vielen Zahlen abschrecken." />
      <InteressenCard text="Ich liebe es, mir Statistiken anzusehen." />
      <InteressenCard text="Wenn ich eine Aufgabe lösen soll, gehe ich immer methodisch vor. Ich analysiere, suche passende Methoden und wende diese Schritt für Schritt an." />

      <InteressenCard text="Ich finde Freude darin, Kompromisse zu finden und Win-Win-Situationen bei Meinungsverschiedenheiten zu schaffen." />
      <InteressenCard text="Ich bin gut darin, immer fair und objektiv zu bleiben, auch wenn ich z.B. wütend auf jemand bin." />
      <InteressenCard text="Ich habe Spaß daran, verschiedene Interessen und Standpunkte zu analysieren und Verhandlungsstrategien zu entwickeln." />
      <InteressenCard text="Wenn ich mit Leuten verhandle oder diskutiere, sehe ich es eher als ein Sport und habe Spaß daran." />
      <InteressenCard text="Ich bin gut darin, in schwierigen Gesprächssituationen ruhig und geschickt zu bleiben, um meine Ziele zu erreichen." />
      <InteressenCard text="Auch wenn eine Diskussion sehr aufgeladen ist, kann ich mich jederzeit beherrschen und dem Rahmen entsprechend höflich verhalten." />
      <InteressenCard text="Auch wenn ein Standpunkt nicht mit meinen eigenen Werten übereinstimmt, habe ich kein Problem damit, diesen in einer Argumentation zu vertreten." />
      <InteressenCard text="Es macht mir viel Spaß, in Verkaufsgesprächen über den Preis zu verhandeln." />

      <InteressenCard text="Ich liebe es, mit dem Computer zu arbeiten und kann damit gut umgehen." />
      <InteressenCard text="Große Baumaschinen faszinieren mich." />
      <InteressenCard text="Ich habe für lange Zeit und sehr gerne mit Lego gespielt." />
      <InteressenCard text="Wenn ich mit einem unbekannten technischen Gerät zu tun habe, kann ich es trotzdem schon nach kurzer Zeit bedienen." />
      <InteressenCard text="Ich programmiere häufig in meiner Freizeit." />
      <InteressenCard text="Ich interessiere mich für Raketenstarts. Einige habe ich live verfolgt." />
      <InteressenCard text="Ich kenne mich mit Autos sehr gut aus und interessiere mich dafür." />
      <InteressenCard text="Ich bin bereit, viel Zeit zu investieren, um auch sehr komplizierte technische Inhalte zu begreifen." />

      <InteressenCard text="Ich verbringe einen großen Teil meiner Freizeit mit Malen, Dichten oder Singen." />
      <InteressenCard text="Ich unterhalte mich regelmäßig (mindestens zweimal in der Woche) mit anderen über Kunst." />
      <InteressenCard text="Ich lese häufig Bücher oder Zeitschriften über Kunst." />
      <InteressenCard text="Es passiert häufig, dass Kunstwerke mich sehr faszinieren und etwas in mir auslösen." />
      <InteressenCard text="Ich trete gerne vor anderen auf der Bühne auf." />
      <InteressenCard text="Es ist mir sehr wichtig, mich auch mit vielen kleinen und unbekannten Künstlern zu beschäftigen, nicht nur mit den Berühmten." />
      <InteressenCard text="Ich lese sehr gerne poetische und anspruchsvolle Werke." />
      <InteressenCard text="Ich möchte Zeit investieren, um Kunstwerke verstehen, analysieren und interpretieren zu können. Kurzweiliges Betrachten reicht mir nicht." />

      <InteressenCard text="Ich beschäftige mich sehr viel mit der Politik in Deutschland oder anderen Ländern." />
      <InteressenCard text="Ich lese regelmäßig Zeitung und schaue Nachrichten." />
      <InteressenCard text="Ich engagiere mich in meinem Viertel, meinem Dorf oder meiner Stadt." />
      <InteressenCard text="Ich war oder wäre gerne Klassensprecher." />
      <InteressenCard text="Ich möchte die Ursachen und Folgen sozialer Ungleichheit untersuchen." />
      <InteressenCard text="Ich diskutiere mit meinen Eltern oder Freunden über aktuelle Themen, wie Klimawandel oder Politik." />
      <InteressenCard text="Ich bin neugierig darauf, wie Medien und Kommunikation die öffentliche Meinung beeinflussen." />
      <InteressenCard text="Gesellschaftliche Veränderungen und deren historische Hintergründe interessieren mich sehr." />

      <InteressenCard text="Ich bin gut in Biologie, Geographie, Physik oder Chemie." />
      <InteressenCard text="Ich mag es mit Formeln und Gleichungen zu hantieren." />
      <InteressenCard text="Die Vorstellung mehrere Stunden am Tag in einem sterilen Labor zu verbringen, finde ich nicht schlimm." />
      <InteressenCard text="Ich finde Biologie, Geographie, Physik oder Chemie interessant, egal wie gut ich bin." />
      <InteressenCard text="Nur Dinge die logisch bewiesen sind, sind wahr." />
      <InteressenCard text="Ich kann besser als die meisten meiner Klassenkameraden mit einer Formelsammlung umgehen." />
      <InteressenCard text="In meiner Freizeit lese ich gerne Artikel mit naturwissenschaftlichen Inhalten." />
      <InteressenCard text="Ich habe kein Problem damit, ein sehr anspruchsvolles und anstrengendes Studium anzutreten." />

      <Button></Button>
    </div>
  );
}

export function Interessen() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Interessentest - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);
  return (
    <div>
      <Layout pageTitle={"Interessen"}>
        <InteressenContainer />
      </Layout>
    </div>
  );
}
