import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  db,
  functions,
  auth,
  analytics,
} from "../../firebase/firebaseConfig.js";
import { onAuthStateChanged } from "firebase/auth";
import { logEvent } from "firebase/analytics";

const SendToCheckout = async (priceId, navigate, setIsLoading) => {
  let checkoutSessionData = {
    price: priceId,
    success_url: `${window.location.origin}/evaluation`,
    cancel_url: window.location.origin,
    mode: "payment",
  };
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const uid = user.uid;
      const checkoutSessionRef = await addDoc(
        collection(db, `users/${uid}/checkout_sessions`),
        checkoutSessionData
      );

      console.log("User ID:", uid);
      console.log("Checkout Session Reference:", checkoutSessionRef.id);

      onSnapshot(checkoutSessionRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.log(
            "Ein Fehler ist aufgetreten: " + error.message + error.code
          );
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } else {
      navigate("/login");
    }
  });
};

export const CheckoutButton = ({ priceId, className, label, isPresent }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheckoutClick = async () => {
    logEvent(analytics, "kaufen/verschenken_button");

    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // simulate loading
      await SendToCheckout(priceId, navigate, setIsLoading);
    } catch (error) {
      console.error("Error initiating checkout:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          className={`btn w-full md:w-1/3 md:flex-grow md:flex-shrink-0 btn-accent font-bold ${
            label === "Verschenken" ? "btn-primary" : "btn-accent"
          }`}
        >
          <span class="loading loading-spinner loading-md"></span>
        </div>
      ) : (
        <button
          className={className}
          onClick={handleCheckoutClick}
          disabled={isLoading}
        >
          {label}
          {isPresent && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          )}
        </button>
      )}
    </>
  );
};
