import React, { useState, useEffect } from "react";
import { CheckoutButton } from "./CheckOutButton.js";
import { Layout } from "../Layout.js";
import { AuswertungButton } from "./AuswertungButton.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase Authentication
import { useNavigate } from "react-router-dom";
import Mockup from "../../assets/MacBook Pro 16 (1).png";
import { checkAndUseCoupon } from "./couponUtils.js";

export function Evaluation() {
  useEffect(() => {
    const originalTitle = document.title; // Speichert den ursprünglichen Titel
    document.title = "Testauswertung - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [paymentStatus, setPaymentStatus] = useState("");
  const [couponStatus, setCouponStatus] = useState(null);
  const [popupMessage, setPopupMessage] = useState(null);
  const [userID, setUserID] = useState(null); // State to store the userID
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth(); // Get the Firebase Auth instance
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, set the userID
        setUserID(user.uid);
      } else {
        // User is signed out
      }
    });

    // Clean up the observer on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userID) {
      // Only check payment status if we have a userID
      const db = getFirestore();
      const paymentsQuery = query(
        collection(db, "users", userID, "payments"),
        where("status", "==", "succeeded"),
        where("priceId", "==", "price_1PqW59LCsjAecyK8XoY8d4Qu")
      );

      const checkPaymentStatus = async () => {
        try {
          const querySnapshot = await getDocs(paymentsQuery);
          console.log(querySnapshot);
          if (querySnapshot.empty) {
            setPaymentStatus("notSucceeded"); //notSucceeded
          } else {
            setPaymentStatus("succeeded");
            console.log(paymentStatus);
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
          setPaymentStatus("error");
        }
      };

      checkPaymentStatus();
    }
  }, [userID]); // This effect depends on userID

  const [isTimeExpired, setIsTimeExpired] = useState(false);

  // Effekt, um die Zeit ablaufen zu lassen und den Button zu aktivieren
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeExpired(true);
    }, 3000);

    // Aufräumen: Timer zurücksetzen, wenn die Komponente entladen wird
    return () => clearTimeout(timer);
  }, []);

  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout pageTitle="Auswertung">
      {/* <div className="flex flex-col items-center bg-primary px-5 pb-5">
        <h1
          id="top"
          className="text-3xl text-center lg:text-5xl mt-4 lg:mt-8 mb-3 lg:mb-8"
        >
          Auswertung
        </h1>
      </div> */}
      <section className="py-24 md:py-36 text-primary bg-neutral">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-start justify-center gap-1 md:gap-5 px-8  ">
          <div className="relative text-center lg:text-left lg:mt-4">
            {paymentStatus === "loading" || paymentStatus === "succeeded" ? (
              <>
                <h1 className="text-4xl lg:text-5xl p-0 m-0 lg:mt-8">
                  Deine persönliche Auswertung
                </h1>
                <p className="py-7 max-w-3xl text-lg">
                  Jetzt, da du alle Tests abgeschlossen hast, erhälst du
                  Einblicke in dein Persönlichkeitsprofil und unsere KI findet
                  heraus welche Studiengänge am besten zu dir passen. Klicke
                  einfach auf den Download-Knopf und die pdf-Datei wird im
                  Handumdrehen für dich generiert und automatisch
                  heruntergeladen. Dies kann 2-3 min dauern.
                </p>
              </>
            ) : (
              <>
                <h1 className="text-4xl lg:text-5xl p-0 m-0 lg:mt-8">
                  Der Wegweiser für deine Zukunft
                </h1>
                <p className="py-7  text-lg">
                  In deiner Testauswertung erhälst du Einblick in dein
                  Persönlichkeitsprofil und deine Interessen. Basierend darauf
                  empfiehlt dir unsere KI drei verschiedene Studiengänge die zu
                  deinem Profil passen.
                </p>
              </>
            )}
            <div className="flex flex-col md:flex-row md:justify-start items-center justify-center gap-5">
              <div
                className={`${
                  paymentStatus === "succeeded" ? "hidden" : ""
                } flex flex-wrap items-center gap-2`}
              >
                <div className="flex flex-col text-lg ">
                  <p className="relative">
                    <span className="absolute bg-primary h-[1.5px] inset-x-0 top-[54%]"></span>
                    <span className="text-primary/80">29€</span>
                  </p>
                </div>
                <p className="text-4xl  tracking-tight font-black">4,99€</p>
                <div className="flex flex-col justify-end mb-[4px]"></div>
              </div>

              {paymentStatus === "loading" ? (
                <p className="flex flex-row items-center justify-center gap-2 p-3 rounded-lg bg-accent text-center text-primary font-bold px-10">
                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin w-[18px] h-[18px] text-primary"
                  >
                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
                  </svg>
                  lädt...
                </p> // Show a loading state or similar
              ) : paymentStatus === "succeeded" ? (
                <div className="w-full lg:w-1/2">
                  <div className="relative">
                    {/* Überlagerndes Element, das den Button abdeckt, bis die Zeit abgelaufen ist */}
                    {!isTimeExpired && (
                      <div className="absolute bg-accent rounded-lg inset-0 flex justify-center items-center z-50">
                        <span className="loading loading-spinner loading-md"></span>
                      </div>
                    )}

                    {/* AuswertungButton wird gerendert */}
                    <AuswertungButton />
                  </div>
                </div>
              ) : (
                <>
                  {/* <div className="flex flex-col md:flex-row gap-2 md:gap-5 w-full md:w-fit"> */}
                  <CheckoutButton
                    label="Jetzt kaufen"
                    className="btn w-full md:w-1/3 md:flex-grow md:flex-shrink-0 btn-accent font-bold"
                    priceId="price_1PqW59LCsjAecyK8XoY8d4Qu"
                  />
                  <div
                    className={`tooltip relative w-full rounded-lg md:w-1/3 md:flex-grow md:flex-shrink-0  bg-white border border-gray-400 ${
                      showTooltip ? "tooltip-open" : ""
                    } tooltip-bottom md:tooltip-top tooltip-primary`}
                    data-tip="Hier kannst du nur Geschenkgutscheine einlösen! Rabattcodes kannst du im Bezahlvorgang anwenden."
                  >
                    <form
                      onSubmit={(e) => {
                        checkAndUseCoupon(e.target.elements[0].value)
                          .then((result) => {
                            setPaymentStatus(
                              result.status ? "succeeded" : "failed"
                            );
                            setCouponStatus(result.status);
                            setPopupMessage(result.message);

                            setTimeout(() => {
                              setCouponStatus(null);
                            }, 4000);
                          })
                          .catch((error) => {
                            console.error("Error using coupon: ", error);
                            setPaymentStatus("failed");
                            setCouponStatus(false);
                            setPopupMessage(error.message);
                          });
                        //if checkanduse was successfull do setPaymentStatus("succeeded")
                        e.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Gutscheincode einlösen"
                        className="w-full h-full my-1 px-3 py-2 bg-transparent outline-none"
                        required
                      />
                      <button
                        type="submit"
                        className="absolute h-full right-0 top-0 px-4 border rounded-lg border-white bg-primary active:scale-95 text-white group hover:brightness-150 font-bold"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-6 h-6 group-hover:translate-x-1 duration-200"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </form>
                  </div>
                  {couponStatus !== null && (
                    <div
                      className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 border px-4 py-3 rounded-md shadow-lg font-semibold z-50 ${
                        couponStatus
                          ? "bg-green-200 border-green-400 text-green-700"
                          : "bg-red-200 border-red-400 text-red-700"
                      }`}
                      role="alert"
                    >
                      <div className="grid grid-flow-col space-x-2">
                        {couponStatus ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        )}
                        <span>{popupMessage}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              className={`${
                paymentStatus === "succeeded" ? "hidden" : ""
              } flex flex-col md:flex-row items-center gap-1 md:justify-between md:items-center text-sm md:gap-5 mt-8 rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-3 bg-white`}
            >
              <div className=" flex items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[18px] h-[18px] text-primary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                  />
                </svg>
                wissenschaftlich fundiert
              </div>
              <div className=" flex items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-stopwatch w-[16px] h-[16px] text-primary"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
                  <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3" />
                </svg>
                in einer Minute erstellt
              </div>
              <div className=" flex items-center justify-center  gap-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-[18px] h-[18px] text-primary"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                30 Tage Geld-zurück-Garantie
              </div>
            </div>
          </div>
          <div className=" max-w-xl md:max-w-lg flex mx-auto md:mt-5">
            <img
              src={Mockup}
              alt="Iphone 15 Mockup"
              className="w-full h-auto"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}
