import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import { db, auth } from "../../firebase/firebaseConfig"; // Adjust the import path as needed
import { onAuthStateChanged } from "firebase/auth";
import { RadarChartPDF } from "./RadarChartPDF"; // Ensure this component is correctly implemented
import { exportAllToPdf, getData } from "./pdfUtils"; // Ensure this function handles the refs to charts correctly
import { submitEvaluation, getResponsesForUser } from "./chatGPTUtils";
import { getKreativiteatResult } from "../Kreativiteat";
import { createPrompt } from "./chatGPTUtils";
import { ChartContext } from "./ChartContext";

Chart.register(...registerables);

const categories = {
  PersonalityTraits: [
    "Gewissenhaftigkeit",
    "Verträglichkeit",
    "Extrovertiert",
    "Neurotizismus",
    "Offenheit",
  ],
  Prioritäten: [
    "Einkommen",
    "Freiheit",
    "Kundenkontakt",
    "Verantwortung",
    "Sinnstiftend",
  ],
  SkillLevels: [
    "Empathie",
    "Sprache",
    "Zahlenreihen",
    "Kreativität",
    "Stressresilienz",
  ],
  Interessen: [
    "Koordinieren",
    "Kreieren",
    "Helfen",
    "Verhandeln",
    "Analysieren",
    "Technik",
    "Künste",
    "Gesellschaft",
    "Naturwissenschaft",
  ],
};

const createSubset = (data, keys) => {
  return keys.reduce((subset, key) => {
    if (data.hasOwnProperty(key)) {
      subset[key] = data[key];
    }
    return subset;
  }, {});
};

export const AuswertungButton = () => {
  const [fetchedData, setFetchedData] = useState(null);
  const chartRefs = useRef([]);
  const [userID, setUserID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const colors = [
    { r: 255, g: 99, b: 132 }, // Reddish color
    { r: 99, g: 132, b: 255 }, // Bluish color
    { r: 255, g: 204, b: 102 }, // Orange-ish color
    { r: 51, g: 204, b: 255 }, // Cyan-ish color
  ];
  const { images } = useContext(ChartContext);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserID(user.uid);
        await getKreativiteatResult(user.uid);
      }
    });
    return () => unsubscribe(); // Correctly unsubscribe on cleanup
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (userID) {
        try {
          const data = await getData(userID); // Assuming getData is an async function and requires userID
          setFetchedData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [userID]);

  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleAuswertung = async () => {
    if (!userID) {
      console.log("User is not authenticated.");
      return; // Early return if user is not authenticated
    }

    if (!fetchedData || fetchedData["chatGPTAnswer"]) {
      console.log("No fetched data or Answer already exists.");
      return; // Similar early return if data conditions are not met
    }

    setIsLoading(true); // Start loading
    setProgress(0); // Reset progress

    try {
      let responseObj = {};

      const categoryNames = [
        "Persönlichkeit",
        "Prioritäten",
        "Fähigkeiten",
        "Interessen",
        "Auswertung",
        "Berufe",
      ]; //Object.keys(categories);
      let completedRequests = 0;

      const responses = await Promise.all(
        categoryNames.map(async (category) => {
          const response = await getResponsesForUser(userID, category);
          return response;
        })
      );

      console.log(responses);
      completedRequests++;

      if (
        responses.every((element) => element !== null) &&
        responses.length > 0
      ) {
        console.log("Responses for the user:", responses);
        categoryNames.forEach((category, index) => {
          responseObj[category] = responses[index];
        });
      } else {
        console.log(
          "No existing messages with responses found. Submitting new evaluation..."
        );
        const prompts = await createPrompt(userID);
        const responses = await Promise.all(
          categoryNames.map(async (category, index) => {
            const response = await submitEvaluation(
              prompts[index],
              userID,
              category
            );
            completedRequests++;
            setProgress(completedRequests * 10);
            return response;
          })
        );

        categoryNames.forEach((category, index) => {
          responseObj[category] = responses[index];
        });
      }

      console.log("Received evaluation response:", responseObj);
      await exportAllToPdf(chartRefs, responseObj, images);
    } catch (error) {
      console.error("Error in handling evaluation:", error);
    } finally {
      setIsLoading(false); // End loading regardless of outcome
    }
  };

  if (!userID) {
    return <div>Loading or user not authenticated...</div>;
  }

  return (
    <div className="relative">
      {isLoading ? (
        <>
          <div className="btn btn-accent w-full">
            <span className="loading loading-spinner loading-md"></span>
          </div>

          <div className="flex items-center justify-center md:w-[36rem] h-6 mt-7 relative">
            <progress
              className="progress progress-success w-full h-full absolute transition-all duration-500 ease-in-out"
              value={(progress / 6) * 10}
              max="100"
            ></progress>
            <span className="font-semibold z-10">
              {parseFloat((progress / 6) * 10).toFixed(0)} %
            </span>
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-full tooltip ${
              showTooltip ? "tooltip-open" : ""
            } tooltip-top md:tooltip-right tooltip-primary`}
            data-tip="Schließe genügend Tests ab bevor du deine Auswetung herunterlädtst! Nach dem Download kann sie nicht verändert werden."
          >
            <button
              className="btn btn-accent shadow-lg w-full min-w-full font-bold"
              onClick={handleAuswertung}
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-6 stroke-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              Download
            </button>
          </div>
        </>
      )}

      <div className="absolute left-[-700%]">
        {fetchedData &&
          Object.entries(categories).map(
            ([categoryName, categoryKeys], index) => (
              <div
                key={categoryName}
                ref={(el) => (chartRefs.current[index] = el)}
              >
                <RadarChartPDF
                  category={categoryName}
                  data={createSubset(fetchedData, categoryKeys)}
                  color={colors[index]}
                />
              </div>
            )
          )}
      </div>
    </div>
  );
};
