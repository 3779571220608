import React, { useEffect, useState, useRef } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const DynamicRadarChart = () => {
  const [data, setData] = useState({
    labels: ['Speed', 'Strength', 'Endurance', 'Agility', 'Skill'],
    datasets: [{
      label: 'Dynamic Data',
      data: [0.65, 0.59, 0.90, 0.81, 0.56], // Values between 0 and 1
      backgroundColor: 'rgba(255, 172, 28, 0.2)',
      borderColor: 'rgba(255, 172, 132, 1)',
      pointBackgroundColor: 'rgba(255, 172, 28, 1)',
      pointBorderColor: 'rgba(255, 172, 28, 1)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 172, 28, 1)',
      borderWidth: 5,
    }]
  });

  const [text, setText] = useState('Speed'); // Initial text corresponding to the first label
  const phrases = [
    "Medizin",
    "Informatik",
    "Kunstgeschichte",
    "Maschinenbau",
    "Psychologie",
    "Jura",
    "Biologie",
    "Betriebswirtschaftslehre",
    "Architektur",
    "Pädagogik"
]
  const [index, setIndex] = useState(0);
  const chartRef = useRef(null);

  const getRandomValue = () => (Math.random()).toFixed(2); // Values between 0 and 1

  useEffect(() => {
    const interval = setInterval(() => {
      setData(prevData => ({
        ...prevData,
        datasets: prevData.datasets.map(dataset => ({
          ...dataset,
          data: [
            getRandomValue(),
            getRandomValue(),
            getRandomValue(),
            getRandomValue(),
            getRandomValue()
          ]
        }))
      }));
      // Update the text label in sequence
      setIndex(prevIndex => (prevIndex + 1) % phrases.length);
      setText(phrases[index]);
    }, 2000); // Update every second

    return () => clearInterval(interval);
  }, [index, phrases]);

  const chartStyle = { width: "400px", height: "400px" }; // Increase size for sharpness

  return (
    <div ref={chartRef} style={chartStyle}>
      <Radar 
        data={data}
        options={{
          //devicePixelRatio: window.devicePixelRatio || 2, // Ensure high resolution
          plugins: {
            legend: {
              display: false // Hide the legend
            },
            tooltip: {
              enabled: false // Optionally disable tooltips
            }
          },
          scales: {
            r: {
              min: 0,
              max: 1,
              ticks: {
                display: false, // Remove the tick labels
                stepSize: 0.33, // Tick interval of 0.2
                backdropColor: 'transparent', // Make the tick labels transparent
                font: {
                  size: 14, // Increase font size for clarity
                },
              },
              pointLabels: {
                display: false, // Remove the axis labels
                font: {
                  size: 16, // Increase font size for clarity
                  weight: "bold",
                },
              },
              angleLines: {
                color: 'rgba(255, 255, 255, 0.5)', // Change the angle lines to white with 50% opacity
                lineWidth: 3 // Make the angle lines thicker
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.25)', // Change the grid lines to white with 50% opacity
                lineWidth: 3 // Make the grid lines thicker
              }
            }
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10
            }
          }
        }}
      />
      <div style={{ textAlign: 'center', marginTop: '10px', color: 'white', fontSize: '1.2em' }}>
        {text}
      </div>
    </div>
  );
};

export default DynamicRadarChart;


export function ChartTest() {
    const chartStyle = { width: "600px", height: "600px" };
  return (
    <div style={chartStyle}>
      <DynamicRadarChart />
    </div>
  );
}
