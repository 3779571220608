import { NavLink } from "react-router-dom";
import analyticsEventLog from "../helperFunctions/AnalyticsEvent";

export const ComparisonTable = () => {
  return (
    <>
      <div
        class="max-w-md md:max-w-7xl sm:mx-auto mr-5 ml-5 grid md:grid-cols-4  text-sm"
        x-data="{ isAnnual: true }"
      >
        {/* <!-- Column with labels --> */}
        <section class="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl">
          {/* <!-- Pricing toggle --> */}
          <div class="relative md:bg-inherit px-6 flex flex-col justify-end"></div>
          {/* <!-- # Platform --> */}
          {/* <div
            class="bg-primary md:bg-inherit px-6 flex flex-col justify-end max-md:hidden md:order-1"
            aria-hidden="true"
          >
            <div class="py-2 text-slate-900 font-medium mt-4">Platform</div>
          </div> */}
          {/* <!-- Account Access --> */}
          <div
            class="bg-primary md:bg-inherit px-6 flex flex-col justify-end max-md:hidden md:order-2"
            aria-hidden="true"
          >
            <div class="py-2 text-slate-400 border-b border-slate-700">
              Umfangreich
            </div>
          </div>
          {/* <!-- Custom Domains --> */}
          <div
            class="bg-primary md:bg-inherit px-6 flex flex-col justify-end max-md:hidden md:order-3"
            aria-hidden="true"
          >
            <div class="py-2 text-slate-400 border-b border-slate-700">
              Aussagekräftig
            </div>
          </div>
          {/* <!-- Receipts Forward --> */}
          <div
            class="bg-primary md:bg-inherit px-6 flex flex-col justify-end max-md:hidden md:order-4"
            aria-hidden="true"
          >
            <div class="py-2 text-slate-400 border-b border-slate-700">
              Persönlichkeitsanalyse
            </div>
          </div>
          {/* <!-- Supplier Management --> */}
          <div
            class="bg-primary md:bg-inherit px-6 flex flex-col justify-end max-md:hidden md:order-5"
            aria-hidden="true"
          >
            <div class="py-2 text-slate-400 border-b border-slate-700">
              Zeitlich flexibel
            </div>
          </div>

          {/* <!-- Custom Connection --> */}
          <div
            class="bg-primary md:bg-inherit px-6 flex flex-col justify-end max-md:hidden md:order-[14]"
            aria-hidden="true"
          >
            <div class="py-2 text-slate-400 border-b border-slate-700">
              Preiswert
            </div>
          </div>
        </section>
        {/* <!-- End: Column with labels --> */}

        {/* <!-- Standardtest table --> */}
        <section class="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl">
          <div class="relative bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end">
            <div class="grow mb-5">
              <div class="font-semibold text-slate-200 mb-0.5 text-lg">
                Standardtests
              </div>
              {/* <div class="mb-1">
                <span
                  class="text-3xl font-bold text-slate-200"
                  x-text="isAnnual ? '29' : '35'"
                >
                  0
                </span>
                <span class="text-xl font-medium text-slate-200">€</span>
              </div> */}
              <div class="text-sm text-slate-500">
                Diese fünf- bis zehnminütigen Tests sind oft sehr oberflächlich
                und vernachlässigen deine Persönlichkeit komplett. Ihre
                Auswertung hat meist wenig Aussagekraft, da sie auf simplen
                Interessensfragen basiert.
              </div>
            </div>
          </div>
          {/* <!-- # Platform --> */}
          {/* <div class="bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end md:order-1">
            <div class="py-2 text-slate-900 font-medium mt-4 md:sr-only">
              Platform
            </div>
          </div> */}
          {/* <!-- Account Access --> */}
          <div class="bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end md:order-2 ">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="shrink-0 w-4 h-4 fill-red-500 mr-2"
              >
                <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
              </svg>
              <span>
                <span class="md:sr-only">Umfangreich</span>
              </span>
            </div>
          </div>
          {/* <!-- Custom Domains --> */}
          <div class="bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end md:order-3">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="shrink-0 w-4 h-4 fill-red-500 mr-2"
              >
                <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
              </svg>
              <span>
                <span class="md:sr-only">Aussagekräftig</span>
              </span>
            </div>
          </div>
          {/* <!-- Receipts Forward --> */}
          <div class="bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end md:order-4">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="shrink-0 w-4 h-4 fill-red-500  mr-2"
              >
                <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
              </svg>
              <span>
                <span class="md:sr-only">Persönlichkeitsanalyse</span>
              </span>
            </div>
          </div>
          {/* <!-- Supplier Management --> */}
          <div class="bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end md:order-5">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span>
                <span class="md:sr-only">Zeitlich flexibel</span>
              </span>
            </div>
          </div>

          {/* <!-- Custom Connection --> */}
          <div class="bg-primary md:bg-inherit px-4 lg:px-6 flex flex-col justify-end md:order-[14]">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span>
                <span class="md:sr-only">Preiswert</span>
              </span>
            </div>
          </div>
        </section>
        {/* <!-- End: Essential table --> */}

        {/* <!-- Futedu table --> */}
        <section class="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl mt-5 lg:mt-0">
          <div class="relative bg-white px-4 lg:px-6 flex flex-col justify-end">
            <div class="absolute top-0 right-0 mr-6 -mt-4">
              <div class="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">
                KI-gestützt
              </div>
            </div>
            <div class="grow mb-5">
              <div class="font-semibold text-xl text-slate-900  mb-0.5">
                Futedu
              </div>
              {/* <div class="mb-1">
                <span class="text-3xl font-bold text-slate-900 ">15</span>
                <span class="text-xl font-medium text-slate-900 ">€</span>
              </div> */}
              <div class="text-sm text-slate-500">
                Wir kombinieren das beste aus beiden Welten. Unsere Tests lassen
                ein umfangreiches Bild deiner Persönlichkeit und Fertigkeiten
                zu. Dadurch weiß unsere KI genau, was zu dir passt.
              </div>
            </div>
            <div class="pb-4 border-b border-slate-200 ">
              <NavLink to="/overview#top" onClick={() => window.scrollTo(0, 0)}>
                <button
                  className="bg-accent group flex flex-row justify-center hover:brightness-95 items-center py-1 rounded-md text-primary w-full font-bold"
                  onClick={() => analyticsEventLog("tableLegLos_button")}
                >
                  <div>Leg los</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 lg:w-6 lg:h-6 group-hover:translate-x-1 duration-200"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </NavLink>
            </div>
          </div>
          {/* <!-- # Platform --> */}
          {/* <div class="bg-white  px-4 lg:px-6 flex flex-col justify-end md:order-1">
            <div class="py-2 text-slate-900 font-medium mt-4 md:sr-only">
              Benefits
            </div>
          </div> */}
          {/* <!-- Account Access --> */}
          <div class="bg-white  px-4 lg:px-6 flex flex-col justify-end md:order-2">
            <div class="flex items-center md:justify-center h-full border-b border-slate-200  py-2 text-slate-600 ">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span class="md:sr-only">Umfangreich</span>
            </div>
          </div>
          {/* <!-- Custom Domains --> */}
          <div class="bg-white  px-4 lg:px-6 flex flex-col justify-end md:order-3">
            <div class="flex items-center md:justify-center h-full border-b border-slate-200  py-2 text-slate-600 ">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span class="md:sr-only">Aussagekräftig</span>
            </div>
          </div>
          {/* <!-- Receipts Forward --> */}
          <div class="bg-white  px-4 lg:px-6 flex flex-col justify-end md:order-4">
            <div class="flex items-center md:justify-center h-full border-b border-slate-200  py-2 text-slate-600 ">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span class="md:sr-only">Persönlichkeitsanalyse</span>
            </div>
          </div>
          {/* <!-- Supplier Management --> */}
          <div class="bg-white  px-4 lg:px-6 flex flex-col justify-end md:order-5">
            <div class="flex items-center md:justify-center h-full border-b border-slate-200  py-2 text-slate-600 ">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span class="md:sr-only">Zeitlich flexibel</span>
            </div>
          </div>

          {/* <!-- Custom Connection --> */}
          <div class="bg-white  px-4 lg:px-6 flex flex-col justify-end md:order-[14]">
            <div class="flex items-center md:justify-center border-b border-slate-200  py-2 text-slate-600 ">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span class="md:sr-only">Preiswert</span>
            </div>
          </div>
        </section>
        {/* <!-- End: Perform table --> */}

        {/* <!-- Prof. Beratung table --> */}
        <section class="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl mt-5 lg:mt-0">
          <div class="relative bg-primary md:bg-inherit px-6 flex flex-col justify-end">
            <div class="grow mb-5">
              <div class="font-semibold text-slate-200 mb-0.5 text-lg">
                Prof. Beratungen
              </div>
              {/* <div class="mb-1">
                ab
                <span
                  class="text-3xl font-bold text-slate-200"
                  x-text="isAnnual ? '79' : '85'"
                >
                  250
                </span>
                <span class="text-xl font-medium text-slate-200">€</span>
              </div> */}
              <div class="text-sm text-slate-500">
                Persönliche Beratungsgespräche mit Experten, die über mehrere
                Stunden oder Tage gehen können. Diese kosten meist ein Vermögen
                und man muss persönlich zu einem festen Termin erscheinen.
              </div>
            </div>
          </div>
          {/* <!-- # Platform --> */}
          {/* <div class="bg-primary md:bg-inherit px-6 flex flex-col justify-end md:order-1">
            <div class="py-2 text-slate-900 font-medium mt-4 md:sr-only">
              Platform
            </div>
          </div> */}
          {/* <!-- Account Access --> */}
          <div class="bg-primary md:bg-inherit px-6 flex flex-col justify-end md:order-2">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span>
                <span class="md:sr-only">Umfangreich</span>
              </span>
            </div>
          </div>
          {/* <!-- Custom Domains --> */}
          <div class="bg-primary md:bg-inherit px-6 flex flex-col justify-end md:order-3">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span>
                <span class="md:sr-only">Aussagekräftig</span>
              </span>
            </div>
          </div>
          {/* <!-- Receipts Forward --> */}
          <div class="bg-primary md:bg-inherit px-6 flex flex-col justify-end md:order-4">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                class="shrink-0 fill-emerald-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="9"
              >
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
              </svg>
              <span>
                <span class="md:sr-only">Persönlichkeitsanalyse</span>
              </span>
            </div>
          </div>
          {/* <!-- Supplier Management --> */}
          <div class="bg-primary md:bg-inherit px-6 flex flex-col justify-end md:order-5">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="shrink-0 w-4 h-4 fill-red-500 mr-2"
              >
                <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
              </svg>
              <span>
                <span class="md:sr-only">Zeitlich flexibel</span>
              </span>
            </div>
          </div>

          {/* <!-- Custom Connection --> */}
          <div class="bg-primary md:bg-inherit px-6 flex flex-col justify-end md:order-[14]">
            <div class="flex items-center md:justify-center h-full border-b border-slate-700 py-2 text-slate-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="shrink-0 w-4 h-4 fill-red-500 mr-2"
              >
                <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
              </svg>
              <span>
                <span class="md:sr-only">Preiswert</span>
              </span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ComparisonTable;
