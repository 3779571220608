import { db, auth } from "../../firebase/firebaseConfig"; // Assume you've exported firebaseApp from your Firebase config file
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "firebase/firestore";

export const checkAndUseCoupon = async (couponID) => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log(couponID);

          // Reference to the coupons collection
          const couponsRef = collection(db, "coupons");

          // Query the collection for the specific couponID
          const q = query(couponsRef, where("couponId", "==", couponID));

          // Execute the query and get the snapshot
          const querySnapshot = await getDocs(q);

          // Check if any documents were returned
          if (!querySnapshot.empty) {
            // Get the document reference
            const couponDoc = querySnapshot.docs[0];
            const couponData = couponDoc.data();

            if (couponData.used !== true) {
              // Update the document by adding the field used=true
              await updateDoc(couponDoc.ref, { used: true });

              console.log("Coupon exists and has been marked as used.");

              // Reference to the user's payments subcollection
              const userPaymentsRef = collection(
                db,
                `users/${user.uid}/payments`
              );

              // Create a new payment entry
              await addDoc(userPaymentsRef, {
                couponID: couponID,
                status: "succeeded",
                priceId: "price_1PqW59LCsjAecyK8XoY8d4Qu",
              });

              console.log("Payment entry has been created.");
              resolve({
                status: true,
                message: "Gutscheincode erfolgreich angewendet.",
              });
            } else {
              console.log("Der Gutscheincode wurde bereits verwendet.");
              resolve({
                status: false,
                message: "Der Gutscheincode wurde bereits verwendet.",
              });
            }
          } else {
            resolve({
              status: false,
              message: "Der Gutscheincode existiert nicht.",
            });
            resolve(false);
          }
        } catch (error) {
          console.error("Error checking and using coupon: ", error);
          reject(error);
        }
      } else {
        console.log("User is not logged in.");
        resolve({ status: false, message: "User nicht eingeloggt." });
      }
    });
  });
};
