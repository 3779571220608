import React, { useState, useEffect } from "react";
import { Layout } from "./Layout";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Empathie.css";

const Button = ({ answers }) => {
  const navigate = useNavigate();

  let uid;
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      uid = user.uid;
    } else {
      //not signed in
      return navigate("/registrieren");
    }
  });

  const onClick = () => {
    console.log(answers);
    const sum = answers.reduce((acc, curr) => acc + curr, 0);
    console.log(uid);
    //to firebase
    setDoc(
      doc(db, "users", uid),
      {
        Empathie: sum / (40 * 2),
      },
      { merge: true }
    )
      .then(() => {
        console.log("User added to Firestore");
        return navigate("../Overview");
      })
      .catch((error) => {
        console.log("Error adding user to Firestore:", error);
      });
  };

  return (
    <div className="button-container">
      <button
        className="btn btn-accent rounded-3xl shadow-lg font-bold px-16 mx-auto"
        type="button"
        onClick={onClick}
      >
        Abschicken
      </button>
    </div>
  );
};

const Question = ({ text, index, setAnswers }) => {
  // State to hold the answers
  const [weights, setWeights] = useState([
    [2, 1, 0, 0],
    [0, 0, 1, 2],
    [2, 1, 0, 0],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [2, 1, 0, 0],
    [0, 0, 1, 2],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [0, 0, 1, 2],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [0, 0, 1, 2],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
    [2, 1, 0, 0],
  ]);

  // Function to update answer at a specific index
  const handleRadioChange = (index, value, setAnswers) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = value;
      console.log(newAnswers);
      return newAnswers;
    });
  };

  return (
    <div className="px-2">
      <div className="reas-card">
        <label className="">{text}</label> <br />
        <form>
          <div className="">
            <br />
            <input
              id={`radio1-${index}`}
              name={`option-${index}`}
              type="radio"
              value="sehr"
              onChange={() =>
                handleRadioChange(index, weights[index][0], setAnswers)
              }
            />
            <label htmlFor={`radio1-${index}`}>Stimme sehr zu</label>
            <br />
            <br />
            <input
              id={`radio2-${index}`}
              name={`option-${index}`}
              type="radio"
              value="sehr"
              onChange={() =>
                handleRadioChange(index, weights[index][1], setAnswers)
              }
            />
            <label htmlFor={`radio2-${index}`}>Stimme zu</label>
            <br />
            <br />
            <input
              id={`radio3-${index}`}
              name={`option-${index}`}
              type="radio"
              value="sehr"
              onChange={() =>
                handleRadioChange(index, weights[index][2], setAnswers)
              }
            />
            <label htmlFor={`radio3-${index}`}>Stimme nicht zu</label>
            <br />
            <br />
            <input
              id={`radio4-${index}`}
              name={`option-${index}`}
              type="radio"
              value="sehr"
              onChange={() =>
                handleRadioChange(index, weights[index][3], setAnswers)
              }
            />
            <label htmlFor={`radio4-${index}`}>Stimme gar nicht zu</label>
            <br />
          </div>
        </form>
      </div>
    </div>
  );
};

export function Empathie({}) {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Empathie Test - Futedu";
    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  const [answers, setAnswers] = useState(new Array(40).fill(0));
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt

  return (
    <>
      <Layout pageTitle="Empathie">
        <div className="py-10">
          <Question
            text="Ich merke es, wenn jemand in die Unterhaltung einsteigen möchte."
            index={0}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich finde es schwierig, anderen Dinge zu erklären, die ich einfach finde, wenn sie diese nicht gleich verstehen."
            index={1}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich mag es mich um andere zu kümmern."
            index={2}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich weiß oft nicht, wie ich mich bei sozialen Interaktionen verhalten soll."
            index={3}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich höre oft, dass ich es beim Verdeutlichen meines Standpunkts in einer Diskussion übertreibe."
            index={4}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn ich zu einem Treffen mit einem Freund zuspät komme, stört es mich nicht besonders."
            index={5}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Freundschaften und Beziehungen sind zu kompliziert, darum beschäftige ich mich nicht mit ihnen."
            index={6}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich habe Schwierigkeiten einzuschätzen, ob etwas beleidigend oder unhöflich ist."
            index={7}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="In einer Konversation konzentriere ich mich eher auf meine eigenen Gedanken, als auf die meiner Zuhörer."
            index={8}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Als Kind fand ich es unterhaltsam Würmer zu zerschneiden, um zu sehen was passiert."
            index={9}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn jemand etwas anderes sagt als er meint verstehe ich es sehr schnell."
            index={10}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Mir fällt es schwer nachzuvollziehen warum manche Sachen andere Leute so sehr stören."
            index={11}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Mir fällt es leicht mich in Andere hineinzuversetzen."
            index={12}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich bin gut darin vorherzusehen, wie sich jemand fühlen wird."
            index={13}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn sich in der Gruppe jemand unbehaglich fühlt merke ich es sehr schnell."
            index={14}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn sich jemand von dem was ich sage beleidigt fühlt ist das sein Problem, nicht meins."
            index={15}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn mich jemand fragt wie ich ihre Frisur finde, würde ich ehrlich antworten auch wenn sie mir nicht gefällt."
            index={16}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich kann nicht immer verstehen wieso sich jemand von einer Bemerkung beleidigt fühlen sollte."
            index={17}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn ich Menschen weinen sehe, stört es mich wenig."
            index={18}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich bin sehr direkt. Einige interpretieren das als Unhöflich, auch wenn es gar nicht meine Absicht ist."
            index={19}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich finde Situationen im sozialen Umfeld nicht verwirrend."
            index={20}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Andere Leute sagen mir, dass ich gut darin bin ihre Gedanken und Gefühle zu verstehen."
            index={21}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn ich mit Leuten rede, spreche ich eher über ihre Erfahrungen als meine."
            index={22}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Es stört mich Tiere mit Schmerzen zu sehen."
            index={23}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich kann Entscheidungen treffen, ohne mich von den Gefühlen anderer beeinflussen zu lassen."
            index={24}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich merke sehr schnell, ob jemand von dem, was ich sage, gelangweilt oder unterhalten ist."
            index={25}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich werde wütend, wenn ich Leute in den Nachrichten leiden sehe."
            index={26}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Viele sagen ich bin sehr verständnisvoll. Deshalb sprechen viele meiner Freunde mit mir über ihre Probleme."
            index={27}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich merke, wenn ich störe, auch wenn niemand mich darauf hinweist."
            index={28}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Leute sagen mir manchmal, dass ich es mit dem Necken übertrieben habe."
            index={29}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Leute sagen mir häufig, dass ich unsensibel bin. Ich verstehe nicht immer wieso."
            index={30}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Wenn ich einen Fremden in der Gruppe treffe, ist es seine Aufgabe sich zu integrieren."
            index={31}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Filme berühren mich selten emotional."
            index={32}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich kann mich schnell und intuitiv in Andere hineinversetzen."
            index={33}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich merke sehr schnell, worüber eine andere Person eventuell reden will."
            index={34}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich merke, wenn jemand seine wahren Emotionen verbirgt."
            index={35}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich denke nicht bewusst über die Regeln sozialer Interaktionen nach."
            index={36}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich bin gut darin vorherzusehen, was jemand anderes tun wird."
            index={37}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich neige dazu, mich emotional in die Probleme eines Freundes zu involvieren."
            index={38}
            setAnswers={setAnswers}
          ></Question>
          <Question
            text="Ich respektiere andere Sichtweisen, auch wenn ich nicht mit ihnen übereinstimme."
            index={39}
            setAnswers={setAnswers}
          ></Question>
          <Button answers={answers}></Button>
        </div>
      </Layout>
    </>
  );
}
