// LoginError.js
import React, { useState, useEffect } from "react";

// Übersetzungs-Hilfsfunktion für Firebase-Fehlermeldungen
const translateFirebaseErrorMessage = (errorMessage) => {
  switch (errorMessage) {
    case "auth/admin-restricted-operation":
      return "Die durchgeführte Operation ist auf Administratoren beschränkt.";
    case "auth/argument-error":
      return "Ein ungültiges Argument wurde übergeben.";
    case "auth/app-not-authorized":
      return "Die Anwendung ist nicht autorisiert, auf Firebase zuzugreifen.";
    case "auth/app-not-installed":
      return "Die Firebase-App ist nicht installiert.";
    case "auth/captcha-check-failed":
      return "Die Captcha-Überprüfung ist fehlgeschlagen.";
    case "auth/code-expired":
      return "Der bereitgestellte Code ist abgelaufen.";
    case "auth/cordova-not-ready":
      return "Cordova ist nicht bereit.";
    case "auth/cors-unsupported":
      return "CORS wird in diesem Browser nicht unterstützt.";
    case "auth/credential-already-in-use":
      return "Die Anmeldeinformationen werden bereits von einem anderen Konto verwendet.";
    case "auth/email-already-in-use":
      return "Die Email Adresse existiert bereits.";
    case "auth/custom-token-mismatch":
      return "Das benutzerdefinierte Token passt nicht zu der ID des Diensts.";
    case "auth/requires-recent-login":
      return "Für diese Aktion ist eine erneute Anmeldung erforderlich.";
    case "auth/dependent-sdk-initialized-before-auth":
      return "Der abhängige SDK wurde vor der Authentifizierung initialisiert.";
    case "auth/dynamic-link-not-activated":
      return "Der dynamische Link wurde nicht aktiviert.";
    case "auth/email-change-needs-verification":
      return "Die E-Mail-Änderung erfordert eine Überprüfung.";
    case "auth/emulator-config-failed":
      return "Die Konfiguration des Emulators ist fehlgeschlagen.";
    case "auth/expired-action-code":
      return "Der bereitgestellte Aktion-Code ist abgelaufen.";
    case "auth/cancelled-popup-request":
      return "Die Popup-Anforderung wurde abgebrochen.";
    case "auth/invalid-api-key":
      return "Der API-Schlüssel ist ungültig.";
    case "auth/invalid-app-credential":
      return "Die App-Anmeldeinformationen sind ungültig.";
    case "auth/invalid-app-id":
      return "Die App-ID ist ungültig.";
    case "auth/invalid-user-token":
      return "Das Benutzertoken ist ungültig.";
    case "auth/invalid-auth-event":
      return "Das Authentifizierungsereignis ist ungültig.";
    case "auth/invalid-cert-hash":
      return "Der Zertifikats-Hash ist ungültig.";
    case "auth/invalid-verification-code":
      return "Der Überprüfungscode ist ungültig.";
    case "auth/invalid-continue-uri":
      return "Die Fortsetzungs-URL ist ungültig.";
    case "auth/invalid-cordova-configuration":
      return "Die Cordova-Konfiguration ist ungültig.";
    case "auth/invalid-custom-token":
      return "Das benutzerdefinierte Token ist ungültig.";
    case "auth/invalid-dynamic-link-domain":
      return "Die dynamische Link-Domäne ist ungültig.";
    case "auth/invalid-email":
      return "Die E-Mail-Adresse ist ungültig.";
    case "auth/invalid-emulator-scheme":
      return "Das Emulator-Schema ist ungültig.";
    case "auth/invalid-credential":
      return "Die Anmeldeinformationen sind ungültig.";
    case "auth/invalid-message-payload":
      return "Die Nachrichten-Payload ist ungültig.";
    case "auth/invalid-multi-factor-session":
      return "Die Multi-Faktor-Sitzung ist ungültig.";
    case "auth/invalid-oauth-client-id":
      return "Die OAuth-Client-ID ist ungültig.";
    case "auth/invalid-oauth-provider":
      return "Der OAuth-Anbieter ist ungültig.";
    case "auth/invalid-action-code":
      return "Der Aktion-Code ist ungültig.";
    case "auth/unauthorized-domain":
      return "Die Domain ist nicht autorisiert.";
    case "auth/wrong-password":
      return "Das Passwort ist falsch.";
    case "auth/invalid-persistence-type":
      return "Der Persistenztyp ist ungültig.";
    case "auth/invalid-phone-number":
      return "Die Telefonnummer ist ungültig.";
    case "auth/invalid-provider-id":
      return "Die Provider-ID ist ungültig.";
    case "auth/invalid-recipient-email":
      return "Die Empfänger-E-Mail ist ungültig.";
    case "auth/invalid-sender":
      return "Der Sender ist ungültig.";
    case "auth/invalid-verification-id":
      return "Die Überprüfungs-ID ist ungültig.";
    case "auth/invalid-tenant-id":
      return "Die Tenant-ID ist ungültig.";
    case "auth/multi-factor-info-not-found":
      return "Die Multi-Faktor-Informationen wurden nicht gefunden.";
    case "auth/multi-factor-auth-required":
      return "Die Multi-Faktor-Authentifizierung ist erforderlich.";
    case "auth/missing-android-pkg-name":
      return "Der Android-Paketname fehlt.";
    case "auth/missing-app-credential":
      return "Die App-Anmeldeinformationen fehlen.";
    case "auth/auth-domain-config-required":
      return "Die Authentifizierungsdomänenkonfiguration ist erforderlich.";
    case "auth/missing-verification-code":
      return "Der Überprüfungscode fehlt.";
    case "auth/missing-continue-uri":
      return "Die Fortsetzungs-URL fehlt.";
    case "auth/missing-iframe-start":
      return "Der Start des IFrames fehlt.";
    case "auth/missing-ios-bundle-id":
      return "Die iOS-Bundle-ID fehlt.";
    case "auth/missing-or-invalid-nonce":
      return "Der Nonce fehlt oder ist ungültig.";
    case "auth/missing-multi-factor-info":
      return "Die Multi-Faktor-Informationen fehlen.";
    case "auth/missing-multi-factor-session":
      return "Die Multi-Faktor-Sitzung fehlt.";
    case "auth/missing-phone-number":
      return "Die Telefonnummer fehlt.";
    case "auth/missing-verification-id":
      return "Die Überprüfungs-ID fehlt.";
    case "auth/app-deleted":
      return "Die App wurde gelöscht.";
    case "auth/account-exists-with-different-credential":
      return "Ein Konto mit unterschiedlichen Anmeldeinformationen existiert bereits.";
    case "auth/network-request-failed":
      return "Das Netzwerkanforderung ist fehlgeschlagen.";
    case "auth/null-user":
      return "Der Benutzer ist null.";
    case "auth/no-auth-event":
      return "Es liegt kein Authentifizierungsereignis vor.";
    case "auth/no-such-provider":
      return "Der Anbieter existiert nicht.";
    case "auth/operation-not-allowed":
      return "Die Operation ist nicht erlaubt.";
    case "auth/operation-not-supported-in-this-environment":
      return "Die Operation wird in dieser Umgebung nicht unterstützt.";
    case "auth/popup-blocked":
      return "Das Popup wurde blockiert.";
    case "auth/popup-closed-by-user":
      return "Das Popup wurde vom Benutzer geschlossen.";
    case "auth/provider-already-linked":
      return "Der Anbieter ist bereits verknüpft.";
    case "auth/quota-exceeded":
      return "Das Quota wurde überschritten.";
    case "auth/redirect-cancelled-by-user":
      return "Die Weiterleitung wurde vom Benutzer abgebrochen.";
    case "auth/redirect-operation-pending":
      return "Die Weiterleitungsoperation ist ausstehend.";
    case "auth/rejected-credential":
      return "Die Anmeldeinformationen wurden abgelehnt.";
    case "auth/second-factor-already-in-use":
      return "Die zweite Authentifizierungsfaktor wird bereits verwendet.";
    case "auth/maximum-second-factor-count-exceeded":
      return "Die maximale Anzahl von Authentifizierungsfaktoren wurde überschritten.";
    case "auth/tenant-id-mismatch":
      return "Der Tenant-ID stimmt nicht überein.";
    case "auth/timeout":
      return "Die Anfrage hat das Zeitlimit überschritten.";
    case "auth/user-token-expired":
      return "Das Benutzertoken ist abgelaufen.";
    case "auth/too-many-requests":
      return "Zu viele Anfragen. Bitte versuchen Sie es später erneut.";
    case "auth/unauthorized-continue-uri":
      return "Die Fortsetzungs-URL ist nicht autorisiert.";
    case "auth/unsupported-first-factor":
      return "Der nicht unterstützte erste Faktor.";
    case "auth/unsupported-persistence-type":
      return "Der Persistenztyp wird nicht unterstützt.";
    case "auth/unsupported-tenant-operation":
      return "Die Tenant-Operation wird nicht unterstützt.";
    case "auth/unverified-email":
      return "Die E-Mail-Adresse wurde nicht verifiziert.";
    case "auth/user-cancelled":
      return "Der Benutzer hat die Aktion abgebrochen.";
    case "auth/user-not-found":
      return "Der Benutzer wurde nicht gefunden.";
    case "auth/user-disabled":
      return "Das Benutzerkonto ist deaktiviert.";
    case "auth/user-mismatch":
      return "Die Benutzerinformationen passen nicht überein.";
    case "auth/user-signed-out":
      return "Der Benutzer wurde abgemeldet.";
    case "auth/weak-password":
      return "Das Passwort ist zu schwach.";
    case "auth/web-storage-unsupported":
      return "Die Web-Speicherung wird nicht unterstützt.";
    case "auth/already-initialized":
      return "Firebase wurde bereits initialisiert.";
    case "auth/recaptcha-not-enabled":
      return "reCAPTCHA ist nicht aktiviert.";
    case "auth/missing-recaptcha-token":
      return "Das reCAPTCHA-Token fehlt.";
    case "auth/invalid-recaptcha-token":
      return "Das reCAPTCHA-Token ist ungültig.";
    case "auth/invalid-recaptcha-action":
      return "Die reCAPTCHA-Aktion ist ungültig.";
    case "auth/missing-client-type":
      return "Der Client-Typ fehlt.";
    case "auth/missing-recaptcha-version":
      return "Die reCAPTCHA-Version fehlt.";
    case "auth/invalid-recaptcha-version":
      return "Die reCAPTCHA-Version ist ungültig.";
    case "auth/invalid-req-type":
      return "Der Anforderungstyp ist ungültig.";
    case "Die Passwörter stimmen nicht überein.":
      return "Die Passwörter stimmen nicht überein.";
    default:
      return "Ein Authentifizierungsfehler ist aufgetreten.";
  }
};

const LoginError = ({ errorMessage }) => {
  const [isVisible, setIsVisible] = useState(false);
  const translatedErrorMessage = translateFirebaseErrorMessage(errorMessage); // Fehlermeldung übersetzen

  useEffect(() => {
    if (translateFirebaseErrorMessage) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 7000);
    }
  }, [translatedErrorMessage]);

  return (
    isVisible && (
      <div
        className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-red-200 mt-5 border border-red-400 text-red-700 px-4 py-3 rounded-md shadow-lg font-semibold z-50"
        role="alert"
      >
        <div className="grid grid-flow-col space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>

          <span>{translatedErrorMessage}</span>
        </div>
      </div>
    )
  );
};

export default LoginError;
